<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" v-if="is_loaded" class="relative z-10" @close="close()">

      <MyTeamsInvitePlayerModal v-if="invitation"
                                :invitation="invitation"
                                :onInvite="onSendInvitationClicked"
                                :image_key="'image_url'"
                                :placeholder="'Search for a Player to Invite to team'" />

      <ConfirmActionModal v-if="confirm_invitation"
                          :text="confirm_text"
                          :title="'Please confirm invitation'"
                          confirmText="Invite To Team"
                          declineText="Cancel Invitation"
                          :onConfirm="onInvitationConfirmed" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md popover-glass-white-form">
                <div class="flex h-full flex-col overflow-y-scroll bg-glass-white-500 backdrop-blur-md shadow-xl">
                  <div class="px-4 py-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <h2 id="slide-over-heading" class="section-header-1 mb-0">{{record.is_new ? 'New Team' : 'Manage Team'}}</h2>
                      <div class="items-center flex">
                        <button type="button" class="relative rounded-md bg-white/2 text-gray-500 hover:text-gray-700 focus:ring-2 focus:ring-glass-white-500" @click="close()">
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="main-panel">
                    <div class="max-w-md mx-auto mt-4 p-6">
                      <Spinner v-if="is_saving" />

                      <form class="" @submit.prevent="submitForm" v-if="!is_saving">
                        <div class="mb-4" v-if="record.is_new">
                          <Listbox as="div" v-model="selected">
                            <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">League</ListboxLabel>
                            <div class="relative mt-2">
                              <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                <span class="flex items-center">
                                  <img :src="$root.logoFor(league.subdomain)" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                                  <span class="ml-3 block truncate">{{ league.name }}</span>
                                </span>
                                <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </ListboxButton>

                              <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  <ListboxOption as="template" v-for="league in this.leagues" :key="league.id" :value="league.id" v-slot="{ active, selected }">
                                    <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                      <div class="flex items-center">
                                        <img :src="$root.logoFor(league.subdomain)" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ league.name }}</span>
                                      </div>

                                      <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    </li>
                                  </ListboxOption>
                                </ListboxOptions>
                              </transition>
                            </div>
                          </Listbox>
                        </div>

                        <div class="col-span-full mb-4">
                          <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Team logo</label>
                          <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div class="text-center">
                              <div v-if="image_url" class="mx-auto h-12 w-12">
                                <img :src="image_url" alt="Current logo" class="rounded-full h-12 w-12 object-cover">
                              </div>

                              <div class="mx-auto h-12 w-12" v-else>
                                <img :src="record.image_url" alt="Current cover" class="rounded-full h-12 w-12 object-cover">
                              </div>
                              <!-- <PhotoIcon v-else class="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" /> -->

                              <div class="mt-4 flex text-sm leading-6 text-gray-600 justify-center mb-2">
                                <label for="file-upload" class="relative cursor-pointer rounded-md menu-button-glass-black-1 font-semibold text-gray-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-secondary hover:bg-glass-white-500">
                                  <span>Upload a file</span>
                                  <input id="file-upload" name="file-upload" type="file" @change="handleFileUpload" class="sr-only" />
                                </label>
                                <p v-if="false" class="pl-1">or drag and drop</p>
                              </div>
                              <p class="text-xs leading-5 text-gray-600">Square image, ideally 512x512 - PNG, JPG, GIF</p>
                            </div>
                          </div>
                        </div>

                        <div class="mb-4">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
                            Name
                          </label>
                          <input v-model="record.name" type="text" id="name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Team Name">
                        </div>

                        <div class="mb-4" v-if="false">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="bio">
                            Bio
                          </label>
                          <textarea v-model="record.bio" id="bio" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Team Bio (not currently working, may or may not implement in the future)"></textarea>
                        </div>

                        <div class="mb-4" v-if="false">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="discord_invite_url">
                            Discord Invite URL
                          </label>
                          <input v-model="record.discord_invite_url" type="url" id="discord_invite_url" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="https://discord.gg/your-invite">
                        </div>

                        <div class="mb-4" v-if="false">
                          <label class="flex items-center">
                            <input v-model="record.is_recruiting" type="checkbox" class="form-checkbox h-5 w-5 text-blue-600"><span class="ml-2 text-gray-700">Is Recruiting?</span>
                          </label>
                        </div>

                        <div class="mb-4" v-if="!record.is_new">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="status">
                            Status
                          </label>
                          <select v-model="record.status" :disabled="record.is_new" id="status" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>

                        <div class="mb-4" v-if="league.regions.length > 1">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="status">
                            Region
                          </label>
                          <select v-model="record.region" id="region" class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option :value="region" v-for="region in league.regions" :key="region">
                              {{ uppercase(region) }}
                            </option>
                          </select>
                        </div>

                        <div class="mb-4 players" v-if="!league.is_singles && !record.is_new">
                          <div class="flex justify-between items-center mb-4 section-header-1 pl-0">
                            <h3 class="">Players</h3>
                            <button @click="addPlayer()" class="p-2 rounded-md bg-glass-black-500 text-white hover:bg-glass-primary-700 hover:ring-2 hover:ring-glass-primary-900">
                              <Tooltip text="Invite user to join team" position="left" class=""><UserPlusIcon class="h-6 w-6"/></Tooltip>
                            </button>
                          </div>

                          <div class="players mt-2">
                            <div class="grid grid-cols-1 gap-4 sm:grid-cols-1">
                              <MyTeamsPlayer v-for="player in team_players" :key="player.id" :player="player" :team="record" :user-is-captain="isCaptain()" />
                            </div>
                          </div>
                        </div>

                        <div class="flex items-center justify-between">
                          <button @click="save()" class="button-glass-black-1 hover-transition-1" type="submit">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import Mixins from '@/components/mixins';
import ImageUploadMixin from '@/components/mixins/image_upload_mixin';
import MyTeamsPlayer from './_player';
import MyTeamsInvitePlayerModal from './_invite_player_modal';
import Tooltip from '@/components/ui/tooltip';

import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { XMarkIcon, EllipsisVerticalIcon, UserPlusIcon } from '@heroicons/vue/24/outline';
import DiscordIcon from '@/components/icons/discord_icon';
import ConfirmActionModal from '@/components/ui/confirm_action_modal';
import UIComponents from '@/components/ui';

export default {
  name: 'MyTeamsFormPopout',
  mixins: _.values(Mixins),
  components: {
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
    CheckIcon, ChevronUpDownIcon, Dialog, DialogPanel,
    Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot,
    XMarkIcon, EllipsisVerticalIcon, DiscordIcon, UserPlusIcon,
    MyTeamsPlayer, MyTeamsInvitePlayerModal, ConfirmActionModal, ...UIComponents
  },
  props: ['parent', 'players', 'teams', 'close'],
  methods: {
    addPlayer() {
      this.invitation = { league_id: this.record.league_id };
    },
    async handleFileUpload(event) {
      let formData = new FormData();
      this.image = event.target.files[0];
      let extension = this.image.type.split('/')[1];
      this.image_url = URL.createObjectURL(this.image);
      this.formData = await this.resizeImage(this.image, 512, `team-logo-${this.record.id}-512.${extension}`);
      this.avatarData = await this.resizeImage(this.image, 128, `team-logo-${this.record.id}-128.${extension}`);
    },
    async onInvitationConfirmed() {
      console.log("CONFIRMED INVITATION");
      this.confirm_invitation;
      let notification = new Models.Notification({
        sender_id: this.$root.current_user.id,
        user_id: this.confirm_invitation.user_id,
        record_id: this.record.id,
        record_type: 'Team',
        kind: 'join_team_invitation'
      });

      try {
        await notification.save();
      } catch(e) {
        console.log(e);
      }

      console.log(notification);
    },
    onCancelInvitation() {
      this.confirm_invitation = false;
    },
    onSendInvitationClicked(invitation) {
      this.confirm_text = `Are you sure you want to invite ${invitation.player.name} to join ${this.record.name} ?`;
      this.confirm_invitation = invitation;
    },
    playerForLeague() {
      return _.find(this.players, {league_id: this.league.id})
    },
    currentPlayer() {
      return _.find(this.players, {league_id: this.league.id, user_id: this.$root.current_user.id})
    },
    isCaptain() {
      return this.record.captain_id === this.currentPlayer().id;
    },
    async save() {
      this.is_saving = true;

      if(this.record.is_new) {
        this.record.league_id = this.league.id;
        let player = this.playerForLeague();
        this.record.creator_id = player.id;
        this.record.captain_id = player.id;
        this.record.co_captain_id = player.id;
      }

      try {
        await this.record.save();
        this.showSuccessMessage("Team Saved");
      } catch(e) {
        console.log(e);
      }

      if(this.image) { await this.postImage(); }

      await this.$parent.$$initializeComponent();
      this.close();
    },
    async postImage() {
      await this.record.attachAsset('logo', this.formData);
      await this.record.attachAsset('avatar', this.avatarData);
    }
  },
  async created() {
    window.team_edit_popout = this;
    this.record = this.$parent.record;
    let team_league_ids = _.map(this.teams, 'league_id');
    let player_league_ids = _.map(this.players, 'league_id');

    if(this.record.is_new) {
      this.leagues = _.filter(this.$root.leagues, (l) => {
        return _.includes(player_league_ids, l.id) && !_.includes(team_league_ids, l.id) && !l.is_singles && l.is_active;
      })
      this.league = this.leagues[0];
    } else {
      this.league = this.$parent.leagueFor(this.record);
      this.team_players = await Models.Player.search({
        by_team_id: this.record.id
      })
    }

    this.is_loaded = true;
    this.open = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false),
      is_saving: ref(false),
      open: ref(false),
      confirm_invitation: ref(false),
      player: ref({}),
      record: ref({}),
      selected: ref({}),
      team_players: ref([]),
      league: ref({}),
      invitation: ref(false),
      confirm_text: ref(""),
      image: ref(null),
      image_url: ref(null)
    }
  }
}
</script>

<style>
</style>
