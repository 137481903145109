<template>
  <Spinner v-if="!is_loaded"></Spinner>

  <div v-if="is_loaded" class="h-max-content flow-root backdrop-blur-md px-4 -mt-8 text-shadow-lg flex-grow team-show">
    <UserShowHeading :user="user" />

    <div class="tab-navigation-wrapper mb-4 font-agency text-lg">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <nav class="border-b border-gray-500 pb-2 -mb-px flex space-x-12" aria-label="Tabs">
          <router-link v-for="tab in tabs" :key="tab.name" :to="tab.to" class=" hover-transition-3 px-4" :class="[isCurrentTab(tab) ? 'border-secondary text-glass-black-800 rounded-md bg-glass-white-400' : 'border-transparent text-glass-white-600 rounded-md item-hover-2', 'group inline-flex items-center border-b-1 py-2 text-xl uppercase font-medium']">
            <component :is="tab.icon" :class="[isCurrentTab(tab) ? 'text-gray-800' : 'text-gray-200 text-shadow-sm-black-inset group-hover:text-gray-100', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
            <span>{{ tab.name }}</span>
          </router-link>
        </nav>
      </div>
    </div>

    <div class="tab-wrapper transition-page-wrapper pb-4">
      <!-- <TeamPlayerPopout /> -->

      <Transition name="page-transition-2" mode="in-out">
        <router-view :parent="this" />
      </Transition>
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import UserShowHeading from '@/components/users/show/_heading';
import UIComponents from '@/components/ui';
import Mixins from '@/components/mixins';
import { InformationCircleIcon, UserGroupIcon, ArrowTrendingUpIcon, GlobeAltIcon, ClipboardIcon, TrophyIcon, FilmIcon } from '@heroicons/vue/24/solid'
import { sortAndPrepareMatchesBySeasons, sortAndPrepareRatingsBySeasons } from '@/lib/helpers';

export default {
  name: 'PlayerShow',
  mixins: _.values(Mixins),
  components: {
    UserShowHeading,
    InformationCircleIcon, UserGroupIcon, ArrowTrendingUpIcon,
    TrophyIcon, GlobeAltIcon, ClipboardIcon, FilmIcon,
    ...UIComponents
  },
  props: ['league'],
  methods: {
    showTeamPlayerPopout(player) {
      Subscriptions.$$broadcast('team.show.player.show', player);
    },
    isCurrentTab(tab) {
      return tab.to.path === this.$route.path.split("/").pop();
    },
    async initializeComponent() {
      this.is_loaded = false;

      this.tabs.push(...[
        { name: `Stats / ${this.$root.league.name}`, icon: InformationCircleIcon, to: { name: 'PlayerShowDetails', path: 'details'} },
        { name: 'Stats / All Leagues', icon: GlobeAltIcon, to: { name: 'PlayerShowLeagues', path: 'leagues'} },
        { name: 'Clips', icon: FilmIcon, to: { name: 'PlayerShowClips', path: 'clips'} }
      ]);

      window.player_show = this;
      this.player = await Models.Player.find(this.$route.params.id);
      this.user = await Models.User.find(this.player.user_id);

      this.global_player_ratings = await Models.PlayerRating.search({
        by_user_id: this.player.user_id,
        by_league_id: this.$root.league.id
      })

      this.rating = this.global_player_ratings[0];

      if(this.player.team_id) {
        let team_ratings = await Models.Rating.search({
          by_team_id: this.player.team_id
        })

        this.team_rating = _.reverse(_.sortBy(team_ratings, 'created_at'))[0];
      } else {
        this.team_rating = false;
      }

      if(this.player.team_ids.length > 0) {
        this.team_ratings = await Models.Rating.search({
          by_team_ids: this.player.team_ids
        })

        this.team_ratings = sortAndPrepareRatingsBySeasons(this.team_ratings, this.$root.league.ordered_seasons);
      }

      this.is_loaded = true;
    },
    async copyDiscord() {
      try {
        await navigator.clipboard.writeText(this.player.discord_name || 'discord_name');
        this.$$broadcast('notification.show', {
          heading: 'Copy Success',
          body: `Discord ID Copied to clipboard`
        })
      } catch(err) {
        console.log(err);
      }
    }
  },
  async created() {
    window.player_show = this;
    await this.initializeComponent();
  },
  mounted() {
    console.log(this.$route.params.slug);
  },
  watch: {
    async '$route.params.id'(to, from) {
      if(to) {
        await this.initializeComponent();
      }
    }
  },
  setup() {
    return {
      player: ref({}),
      user: ref({}),
      player_rating: ref({}),
      team_ratings: ref([]),
      team_rating: ref({}),
      team: ref([]),
      is_loaded: ref(false),
      tabs: ref([])
    }
  }
}
</script>

<style>
.transition-page-wrapper {
  transform-style: preserve-3d;
}
</style>
