import {BaseModel, Model} from './base_model';

@Model({
  type: 'season',
  url: 'api/front/seasons',
  attributes: {
    number: { default_value: 0 },
    started_at: { read_only: true },
    status: { read_only: true }
  }
})

export class Season extends BaseModel {

}
