<template>
  <div class="transition-page-inner-wrapper w-full h-full font-agency" v-if="is_loaded">
    <div class="my-teams min-h-full flex flow-root px-4 flex-grow w-full">
      <div class="section-header-1 border-b border-glass-white-200 mt-6 min-w-0 flex-1 text-shadow-white-inset font-aldrich flex justify-between items-center">
        <h1 class="truncate text-2xl">My Matches</h1>
        <div class="actions"></div>
      </div>

      <MyMatchesFormPopout v-if="record" :record="record" :match="match" :league="league" :close="closeResults" :confirm="confirmMatchResult"  />

      <div v-if="is_loaded" class="space-y-6 backdrop-blur-md pb-6">
        <section v-for="team in teams" :key="team.id" class="bg-glass-white-700 shadow rounded-lg">
          <header class="flex items-center justify-between p-6 glass-white-shadow-divider">
            <div class="flex items-center">
              <img :src="$root.logoFor(leagueFor(team).slug)" alt="" class="h-10 w-10 flex-shrink-0 mr-4">
              <div>
                <h2 class="text-lg font-semibold text-gray-900"><span class="uppercase text-lg">{{ leagueFor(team).name }}</span> / <span class="text-xl">{{ team.name }}</span></h2>
              </div>
            </div>
          </header>
          <div class="pending-matches p-2">
            <MyMatchesMatch v-for="match in matches_by_league[team.league_id]" :key="match.id" :match="match" :team="team" class="border-b border-glass-black-200 last:border-b-0 font-aldrich">
            </MyMatchesMatch>
          </div>
        </section>
      </div>

      <div v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot  } from '@headlessui/vue';
import { XMarkIcon, UserGroupIcon } from '@heroicons/vue/24/outline';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/vue/20/solid';
import TemplateHelpers from '@/components/mixins/template_helpers';
import MyMatchesFormPopout from './matches/_form_popout';
import MyMatchesMatch from './matches/_match';
import Mixins from '@/components/mixins';

export default {
  name: 'MyMatches',
  mixins: _.values(Mixins),
  components: {
     Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
     XMarkIcon, EllipsisVerticalIcon, PlusIcon, UserGroupIcon, MyMatchesFormPopout, MyMatchesMatch
  },
  props: ['parent'],
  data() {
    return {
      teams: [],
      is_loaded: false
    }
  },
  methods: {
    addNew(league, match) {
      this.closeResults();

      if(match.relationPresent('result')) {
        this.record = match.result;
      } else {
        this.record = new Models.MatchResult({league_id: league.id, match_id: match.id});
      }

      this.match = match;
      this.league = league;
      console.log(this.record);
    },
    closeResults() {
      this.record = false;
      this.match = false;
      this.league = false;
    },
    leagueFor(team) {
      return this.$root.findLeagueById(team.league_id);
    },
    playerForLeague(league) {
      return _.find(this.players, {league_id: league.id});
    },
    formVisible() {
      return !!this.record;
    },
    // async saveMatch(match, attributes) {
    //   _.assign(match, attributes);
    //   await match.save();
    // },
    async confirmMatchResult(result, league) {
      let player = this.playerForLeague(league);

      await result.memberAction('confirm', {
        confirmer_id: player.id,
        params: {
          confirmer_id: player.id
        }
      });

      this.is_loaded = false;
      this.$router.go();
      await this.initializeComponent();
    },
    async save() {
      try {
        await this.record.save();
        this.teams.push(this.record);
        this.record = false;
      } catch(e) {

      }
    },
    async initializeComponent() {
      this.is_loaded = false;
      this.teams = await Models.Team.search({
        by_user_id: this.$root.current_user.id
      });

      let team_ids = _.map(this.teams, 'id');

      this.matches = await Models.Match.$search({
        by_team_ids: team_ids,
        by_statuses: ['created', 'scheduled']
      });

      this.players = await Models.Player.search({
        by_user_id: this.$root.current_user.id
      });

      this.matches_by_league = _.groupBy(this.matches, 'league_id');
      window.my_matches = this;

      this.is_loaded = true;
    },
    async $$reload() {
      await this.initializeComponent();
    }
  },
  async created() {
    this.ensureCurrentUserLoggedIn();
    await this.initializeComponent();
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      form_visible: ref(false),
      record: ref(false),
      is_loaded: ref(false),
      match: ref(false),
      league: ref(false)
    }
  }
}
</script>

<style>

</style>
