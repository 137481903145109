/* eslint-disable */
import _ from 'lodash';
import { compactObject } from '@/lib/helpers';

class ModelCollection extends Array {
  constructor(...args) {
    super(...args);
  }

  static $$initialize(items=[], meta={}) {
    let a = new this(...items);
    a.meta = meta;
    return a;
  }

  build(attributes) {
    this.push(new this.constructor.$$model(attributes));
    return this;
  }

  get attributes_for_save() {
    return _.map(this, 'attributes_for_save');
  }
}

const ModelRegistry = {

}

export function Model(options) {
  return function(target) {
    let {url, type, member_key=type, collection_key = `${member_key}s`, route_name=collection_key, has_many, has_one, belongs_to, attributes} = options;

    target._url = url;
    target._type = type;
    target._collection_key = collection_key;
    target._member_key = member_key;
    target._route_name = route_name;
    target._relations = {
    }
    target._attributes = {}
    target._attributes_to_serialize = []
    target._attributes_to_hide_from_collection = []

    _.each(has_many, (v,k) => { target.hasMany(k, v) })
    _.each(has_one, (v,k) => { target.hasOne(k, v) })
    _.each(belongs_to, (v,k) => { target.belongsTo(k, v) })

    _.each(attributes, (v,k) => {
      target.defineAttribute(k, v);
    })

    target._is_configured = true;
    window.ModelRegistry = ModelRegistry;
    ModelRegistry[target.$type] = target;
    target.$$collection = class extends ModelCollection {

    }

    target.$$collection.$$model = target;
    InstanceRegistry[target.$type] = {}
  }
}

class InstanceRegistry {
  static fetch({id, type}={}) {
    return _.get(this, `${type}.${id}`);
  }
}

export class BaseModel {
  static get $url() { return this._url }
  static get $axios() { return this._axios }
  static get $member_key(){ return this._member_key }
  static get $collection_key(){ return this._collection_key }
  static get $type() { return this._type }
  static get $route_name() { return this._route_name }
  static get $relations() { return this._relations }
  static get $attributes() { return this._attributes }
  static get $attributes_to_serialize() { return this._attributes_to_serialize }
  static get $attributes_to_hide_from_collection() { return this._attributes_to_hide_from_collection }
  static hasMany(key, options={}) {
    options.key = key;
    options.relation_type = 'has_many';
    this._relations[key] = options;

    Object.defineProperty(this.prototype, key, {
      get: function() {
        if(!this.relations[key]) {
          console.log('defining has many');
          this.relations[key] = ModelRegistry[options.type].$$collection.$$initialize()
          let models = _.filter(_.values(InstanceRegistry[options.type]), {[`${this.constructor.$type}_id`]: this.id})
          // I hope commenting out below doesn't break anything, was causing data duplication
          // _.each(models, (model) => { this.relations[key].push(model) });
        }

        return this.relations[key]
      },
      set: function(v) {
        if(!this.relations[key]) {
          this.relations[key] = ModelRegistry[options.type].$$collection.$$initialize()
        } else {
          this.relations[key].length = 0;
          this.relations[key].push(...v);

        }

        console.log('after setting has many');
      }
    })
  }

  static belongsTo(key, options={}) {
    options.key = key;
    options.relation_type = 'belongs_to';
    this._relations[key] = options;

    Object.defineProperty(this.prototype, key, {
      get: function() {
        if(!this.relations[key]) {
          if(!this.$$relations[key] || !this.$$relations[key]["data"]) { return }
          let instance = InstanceRegistry.fetch(this.$$relations[key].data)
          console.log(options.type);
          this.relations[key] = instance || new ModelRegistry[options.type](this.$$relations[key].data)
        }

        return this.relations[key]
      },
      set: function(v) {
        //todo: later if necessary
        // debugger;
        // if(!this.relations[key]) {
        //   this.relations[key] = ModelRegistry[options.type].$$collection.$$initialize()
        // } else {
        //   this.relations[key].length = 0;
        //   this.relations[key].push(...v);
        // }
      }
    })
  }

  static hasOne(key, options={}) {
    options.key = key;
    options.relation_type = 'has_one';
    this._relations[key] = options;

    Object.defineProperty(this.prototype, key, {
      get: function() {
        if(!this.relations[key]) {
          if(!this.$$relations[key] || !this.$$relations[key]["data"]) { return }
          let instance = InstanceRegistry.fetch(this.$$relations[key]["data"]);
          this.relations[key] = instance || new ModelRegistry[options.type](this.$$relations[key].data)
        }

        return this.relations[key]
      },
      set: function(v) {
        //todo: later if necessary
        // debugger;
        // if(!this.relations[key]) {
        //   this.relations[key] = ModelRegistry[options.type].$$collection.$$initialize()
        // } else {
        //   this.relations[key].length = 0;
        //   this.relations[key].push(...v);
        // }
      }
    })
  }

  static $relation(key) {
    return this._relations['has_many'][key] || this._relations['belongs_to'][key] || this._relations['has_one'][key]
  }

  static defineAttribute(name, config) {
    // this.prototype[name] = ref(config.default_value);
    Object.defineProperty(this.prototype, name, {
      get: function() { return this[`_${name}`] },
      set: function(v) {
        this[`_${name}`] = v;
        if(this._is_initialized) { this.$dirty = true }
      }
    })

    this.$attributes[name] = {...config, name: name};
    if(!(config.serialize === false)) {  this.$attributes_to_serialize.push(name) }
    if(config.hide_from_collection === true) {  this.$attributes_to_hide_from_collection.push(name) }
  }

  static defineHasManyRelation(key, config) {
    Object.defineProperty(this.prototype, key, {
      get: function() {
        if(!this.relations[key]) { this.relations[key] = ModelRegistry[config.type].$$collection.$$initialize() }
        return this.relations[key]
        // return this[`_${key}`]
      },
      set: function(v) {
        if(!this.relations[key]) {
          this.relations[key] = ModelRegistry[config.type].$$collection.$$initialize()
        } else {
          this.relations[key].length = 0;
          this.relations[key].push(...v);
        }
      }
    })
  }

  static set config({
    url,
    axios,
    name,
    member_key=name,
    collection_key=`${member_key}s`
  }={}) {
    // debugger;
    this._url = url;
    this._axios = axios;
    this._name = name;
    this._member_key = member_key;
    this._collection_key = collection_key;
  }

  static configure({
    url,
    // axios=this.axios,
    name=this.name,
    member_key=name,
    collection_key=`${member_key}s`,
    route_name=collection_key
  }={}) {
    // debugger;
    this._url = url;
    // this._axios = axios;
    this._name = name;
    this._member_key = member_key;
    this._collection_key = collection_key;
    this._route_name = route_name;
  }

  static onErrors(errors, resource, response) {
    resource, response;
    return errors;
  }

  static async fetch() {
    let result = await this.connection(this.$QueryFetch())();

    return new this({
      attributes: result[this.$modelNameCamelCase]
    })
  }

  $$relations = {}
  relations = {}

  // static async create(attributes={}) {
  //   let record = new this({attributes: attributes});
  //   await record.save();
  //   return record;
  // }

  constructor(attributes={}, relations={}) {
    // this.$attributes = attributes;
    _.each(attributes, (v, k) => {
      this[k] = v;
    })

    _.each(this.constructor.$attributes, (v, k) => {
      if(!this[k]) {
        if(v.default_value === false) {
          this[k] = false
        } else {
          if(_.isNumber(v.default_value)) {
            this[k] = v.default_value;
          } else {
            this[k] = v.default_value || ""
          }
        }
      }
    })

    //not sure if this could cause any further problems so commenting out for now
    this.$$relations = compactObject(relations);
    // this.$$relations = relations;

    _.each(relations, (v, k) => {
      if(_.isArray(v["data"])) {
        //TODO NOTE: THIS WILL BLOW UP IF A HAS MANY HAS BEEN RETURNED BY SERIALIZER AND DOES NOT EXIST HERE
        //ALSO I DON'T THINK ITS WORKING CORRECTLY? NOTE THIS REQUIRES INCLUDED TO HAVE BEEN DEFINED AS THE DATA IS COMING FROM THE TRANSFORM INCLUDED
        this[k].push(..._.map(v.data, (d) => { return InstanceRegistry[d.type][d.id] }));
        // this.relations[k] = ModelCollection.$$initialize(..._.map(v.data, (d) => { return InstanceRegistry[d.type][d.id] }));
      } else {
        // this.relations[k] = InstanceRegistry[v.data.type][v.data.id] = InstanceRegistry[v.data.type][v.data.key];
      }
      // let i = InstanceRegistry[v.type][v.id];
      // this.relations[k] = v[v.data.]
      // this.$$relationships[k] = v.data
    })

    InstanceRegistry[this.constructor.$type][this.id] = this;
    this._is_initialized = true;
  }

  get attributes() {
    let attributes = {}
    if(this.id) { attributes.id = this.id }

    _.each(this.constructor.$attributes_to_serialize, (k) => {
      attributes[k] = this[k];
    })

    return attributes
  }

  get attributes_for_save() {
    let attributes = {...this.attributes}
    _.each(this.constructor.$relations, (relation, k) => {
      if(relation.nested) {
        attributes[`${k}_attributes`] = this[k].attributes_for_save;
      }
    })

    return attributes;
  }

  get serialized_attributes() {
    return {
      [this.constructor.$member_key]: this.attributes_for_save
    }
  }

  get $url() {
    if(this.is_new) {
      return `${this.constructor.$url}`
    } else {
      return `${this.constructor.$url}/${this.attributes.id}`;
    }
  }

  get $route() { return `/${this.constructor.$route_name}/${this.id}`}
  get $edit_route() { return `/${this.constructor.$route_name}/${this.id}/edit` }

  static urlFor(_url) {
    return `${_url}`
  }

  urlFor(...args) {
    return this.constructor.urlFor(...args)
  }

  get is_new() { return !this.id }

  static async first_or_create(attributes) {
    let response = await this.$axios.post(`${this.$url}/first_or_create`, {
      [this.$member_key]: attributes
    });

    return this.initializeOrHandleErrors(response);
  }

  static async create(attributes) {
    let response = await this.$axios.post(this.urlFor(this.$url), {
      [this.$member_key]: attributes
    });

    console.log(response, 'response is');

    return this.initializeOrHandleErrors(response);
  }

  async update(attributes={}) {
    _.each(attributes, (v,k) => {
      this[k] = v;
    })
    let response = await this.constructor.$axios.put(this.$url, this.serialized_attributes);

    return this.constructor.initializeOrHandleErrors(response);
  }

  async attachAsset(path, data) {
    console.log('should be posting to', `${this.$url}/${path}`);
    let response = await this.constructor.$axios.post(`${this.$url}/${path}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response;
  }

  async reload() {
    let response = await this.$axios.get(`${this.$url}`);
    this.attributes = this.constructor.transformResourceResponse(response);
    return this;
  }

  static initializeOrHandleErrors(response) {
    if(_.get(response, 'meta.errors')) {
      this.onErrors(_.get(response, 'meta.errors'), this, response);
      return false
    } else {
      return new this(this.transformResourceResponse(response));
    }
  }

  async save() {
    let response;
    if(this.is_new) {
      let response = await this.constructor.$axios.post(this.$url, this.serialized_attributes);
      this.id = response.data.data.id;
      return response;
    } else {
      response = await this.constructor.$axios.put(this.$url, this.serialized_attributes);
      if(response) {
        return this;
      } else {
        return response;
      }
    }
  }

  async destroy() {
    let response = await this.constructor.$axios.delete(`${this.$url}`);
    return response;
  }

  static async fetchSingleton(params={}) {
    let response = await this.$axios.get(`${this.$url}.json`, {params: params});
    return this.transformResourceResponse(response);
  }

  static transformResourceResponse(response) {
    this.transformIncluded(response.data.included);
    return this.transformItem(_.get(response, `data.data`));
  }

  static registerInstance(type, instance) {
    InstanceRegistry[type][instance.attributes.id] = instance;
  }

  static transformItem(item) {
    let instance = new ModelRegistry[item.type](item.attributes, item.relationships);
    this.registerInstance(item.type, instance);
    return instance;
  }

  async memberAction(member, options={}, request_method='get') {
    let response = await this.constructor.$axios[request_method](`${this.$url}/${member}`, options);
    return new this.constructor(this.constructor.transformResourceResponse(response));
  }

  relationPresent(key) {
    return !_.isUndefined(_.get(this, `$$relations.${key}.data.id`));
  }

  static async find(id, options={}) {
    let response = await this.$axios.get(`${this.$url}/${id}`, options);
    return new this(this.transformResourceResponse(response));
  }

  static transformIncluded(included) {
    _.each(included, (v, k) => {
      this.transformItem(v);
    })
  }

  static transformCollectionResponse(response) {
    this.transformIncluded(response.data.included);

    return ModelCollection.$$initialize(
     _.map(_.get(response, `data.data`), (r) => { return new this(r.attributes, r.relationships) }),
     response.data.meta
    );
  }

  static async fetchAll() {
    let graphs = await this.$axios.get(this.urlFor(`${this.$url}`));
    return this.transformCollectionResponse(graphs);
  }

  static get collection_fields() {
    if(this._collection_fields) { return this._collection_fields }
    let attributes = _.filter(this.$attributes, (attr) => {
      return attr.hide_from_collection !== true
    })

    _.each(this.$relations, (relation, k) => {
      attributes.push({name: relation.key});
    })

    this._collection_fields = `${this._type}:id,${_.map(attributes, 'name').join(',')}`;

    return this._collection_fields;
  }

  static async search(params={}) {
    let request_data = {
      params: params
    }

    if(_.some(this.$attributes_to_hide_from_collection)) {
      request_data['params']['fields'] = this.collection_fields
    }

    let collection = await this.$axios.get(this.urlFor(`${this.$url}`), {params: params});
    return this.transformCollectionResponse(collection);
  }

  static async $search(params={}) {
    let request_data = {
      params: params
    }

    if(_.some(this.$attributes_to_hide_from_collection)) {
      request_data['params']['fields'] = this.collection_fields
    }

    let collection = await this.$axios.post(this.urlFor(`${this.$url}/search`), {...params});
    return this.transformCollectionResponse(collection);
  }

  static async $findBy(params={}) {
    let result = await this.$search({...params, skip_pagination: true});
    if (!result || result.length === 0 || result.length > 1) {
        throw new Error("No results found");
    }
    return result[0];
  }

  static fetchInstanceBy(params={}) {
    return _.filter(InstanceRegistry[this.$type], params);
  }
}
