<template>
  <svg :class="svgClass" fill="currentColor" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <title>YouTube</title>
    <path d="M23.4993,6.1968a3.0084,3.0084,0,0,0-2.1167-2.1328C19.7455,3.7506,12.0045,3.75,12.0045,3.75s-7.7406,0-9.3776.3141A3.0084,3.0084,0,0,0,.5105,6.1968a32.3358,32.3358,0,0,0-.51,6.2808,32.3358,32.3358,0,0,0,.51,6.2808,3.0084,3.0084,0,0,0,2.1167,2.1328c1.637,0.3141,9.3776.3141,9.3776.3141s7.7409,0,9.378-.3141a3.0084,3.0084,0,0,0,2.1167-2.1328,32.3358,32.3358,0,0,0,.51-6.2808A32.3358,32.3358,0,0,0,23.4993,6.1968ZM9.5454,15.4521V8.3021l6.583,3.575Z" />
  </svg>
</template>

<script>
export default {
  name: 'YoutubeIcon',
  props: {
    svgClass: {
      type: String,
      default: '',
    },
  },
};
</script>
