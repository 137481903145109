<template>
  <li class="col-span-1 flex flex-col divide-y divide-glass-white-150 rounded-lg bg-glass-white-500 shadow backdrop-blur-lg font-aldrich">
    <!-- Header with league icon and league name -->
    <div class="p-4 flex items-center justify-between">
      <div class="flex items-center">
        <img class="h-8 w-8 flex-shrink-0 rounded-full ring-2 ring-glass-white-200" :src="$root.logoFor(leagueFor(team).slug)" alt="" />
        <span class="ml-2 text-md font-agency">{{ leagueFor(team).name }}</span>
      </div>
    </div>

    <router-link as="div" :to="teamPathFor(team)" class="flex flex-1 flex-col items-center p-4 hover:bg-glass-white-300 hover-transition-2">
      <div class="team-logo mb-2"><img class="mx-auto h-16 w-16 flex-shrink-0 ring-2 ring-glass-white-200 rounded-full" :src="team.image_url" alt="" /></div>
      <h3 class="mt-2 text-md font-medium text-gray-900">{{ team.name }}</h3>
    </router-link>
  </li>
</template>

<script>
/* eslint-disable */
import TemplateHelpers from '@/components/mixins/template_helpers';
import { PencilIcon, ArrowRightEndOnRectangleIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'UserShowTeamCard',
  props: ['team', 'league'],
  mixins: [ TemplateHelpers ],
  components: {
    PencilIcon, ArrowRightEndOnRectangleIcon
  },
  methods: {
    teamPathFor(team) {
      let league = this.leagueFor(team);
      return `/leagues/${league.subdomain}/team/${team.id}/details`;
    },
    leagueFor(team) {
      return this.$root.findLeagueById(team.league_id);
    }
  },
  async created() {
  }
}
</script>

<style>
</style>
