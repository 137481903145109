import {BaseModel, Model} from './base_model';

@Model({
  type: 'map',
  url: 'api/front/maps',
  attributes: {
    name: {}
  }
})

export class Map extends BaseModel {

}
