<template>
  <LogoSpinner v-if="!is_loaded"></LogoSpinner>

  <div v-if="is_loaded" class="h-max-content main-wrapper px-2 flow-root px-4 mt-2 text-shadow-lg flex-grow team-show backdrop-blur-md backdrop-saturate-100 bg-gradient-to-b from-glass-white-50 to-glass-white-100 rounded-md">
    <!-- <div class="image-heading-bg">
      <img class="h-32 w-full object-cover lg:h-48" :src="team.image_url" alt="" />
    </div> -->
    <div class="page-title mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 py-4 backdrop-saturate--50 rounded-md m-4">
      <div class="flex flex-col sm:flex-row sm:items-center justify-center sm:justify-start">
        <div class="flex justify-center sm:justify-start sm:mr-4">
          <img class="h-20 w-20 rounded-full ring-4 ring-white" :src="team.image_url" alt="" />
        </div>
        <div class="mt-3 sm:mt-0 flex flex-col sm:flex-row sm:min-w-0 sm:flex-1 sm:items-center sm:justify-start">
          <!-- Title for small screens -->
          <div class="min-w-0 flex-1 sm:hidden">
            <h1 class="truncate font-bold text-3xl font-aldrich text-shadow-sm-white">
              <span class="page-title-text font-agency uppercase highlight-first-letter-secondary">{{ team.name }}</span>
            </h1>
          </div>
          <!-- Title for larger screens -->
          <div class="flex min-w-0 flex-1 items-center justify-between sm:block">
            <h1 class="flex-grow block truncate font-bold text-3xl font-aldrich text-shadow-white-inset-sm highlight-first-letter-secondary text-gray-100">
              <span class="page-title-text font-agency uppercase">{{ team.name }}</span>
            </h1>

            <!-- <div class="flex items-center justify-end">
              <Award v-for="award in team.awards" :record="award" :key="award.id"></Award>
            </div> -->
          </div>
          <div class="mt-0 flex flex-col sm:flex-row justify-center sm:justify-start sm:space-x-4">
            <button v-if="$root.league.is_singles" @click="copyDiscord()" type="button" class="inline-flex justify-center rounded-md icon-button-glass-black-1">
              <DiscordIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-navigation-wrapper mb-4 font-agency text-lg">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <nav class="border-b border-glass-white-200 pb-2 -mb-px flex space-x-4" aria-label="Tabs">
          <router-link v-for="tab in tabs" :key="tab.name" :to="tab.to" class="hover-transition-3 px-4" :class="[isCurrentTab(tab) ? 'border-secondary text-glass-black-800 rounded-md bg-glass-white-400' : 'border-transparent text-gray-800 rounded-md hover:border-white hover:bg-glass-black-100 hover:text-gray-100', 'group inline-flex items-center border-b-1 py-2 text-xl uppercase font-medium']">
            <component :is="tab.icon" :class="[isCurrentTab(tab) ? 'text-gray-800' : 'text-gray-800 text-shadow-sm-black-inset group-hover:text-gray-100', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
            <span>{{ tab.name }}</span>
          </router-link>
        </nav>
      </div>
    </div>

    <div class="tab-wrapper transition-page-wrapper pb-4">
      <TeamPlayerPopout />

      <Transition name="page-transition-2" mode="in-out">
        <router-view :parent="this" />
      </Transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import TeamShowDetails from './show/details';
import TeamShowPlayers from './show/players';
import TeamShowMatches from './show/matches';
import TeamPlayerPopout from './show/_team_player_popout';
import UIComponents from '@/components/ui';
import DiscordIcon from '@/components/icons/discord_icon';
import { InformationCircleIcon, UserGroupIcon, ArrowTrendingUpIcon, ClipboardIcon, TrophyIcon, FilmIcon } from '@heroicons/vue/24/solid'
import { sortAndPrepareMatchesBySeasons, sortAndPrepareRatingsBySeasons } from '@/lib/helpers';
import { BaseMixin } from '@/components/mixins/base_mixin';
import Mixins from '@/components/mixins';
import Award from '@/components/standings/index/item/award';

export default {
  name: 'TeamShow',
  components: {
    TeamShowDetails,
    TeamShowPlayers,
    TeamShowMatches,
    TeamPlayerPopout,
    InformationCircleIcon, UserGroupIcon, ArrowTrendingUpIcon, DiscordIcon, ClipboardIcon,
    TrophyIcon, FilmIcon, Award,
    ...UIComponents
  },
  props: ['league'],
  mixins: _.values(Mixins),
  methods: {
    playerRole(player) {
      if(this.team.captain_id === player.id) {
        return "Captain"
      } else if(this.team.co_captain_id === player.id) {
        return "Co-Captain"
      } else if(player.team_id !== this.team.id) {
        return "Former Player"
      } else {
        return "Player"
      }
    },
    showTeamPlayerPopout(player) {
      Subscriptions.$$broadcast('team.show.player.show', player);
    },
    isCurrentTab(tab) {
      return tab.to.path === this.$route.path.split("/").pop();
    },
    async copyDiscord() {
      try {
        await navigator.clipboard.writeText(this.players[0].discord_name || 'discord_name');
        this.$$broadcast('notification.show', {
          heading: 'Copy Success',
          body: `Discord ID Copied to clipboard`
        })
      } catch(err) {
        console.log(err);
      }
    },
    async initializeComponent() {
      this.is_loaded = false;

      this.team = await Models.Team.find(this.$route.params.team_id);
      this.ratings = await Models.Rating.search({
        by_team_id: this.$route.params.team_id
      });

      let matches = await Models.Match.search({
        by_team_id: this.$route.params.team_id,
        per_page: 1000
      });

      this.matches = _.filter(matches, (m) => { return m.relationPresent('result') });
      this.upcoming_matches = _.filter(matches, (m) => { return !m.relationPresent('result') });
      this.all_players = await Models.Player.search({ by_played_on_team_id: this.$route.params.team_id });
      this.current_season = this.$root.league.current_season;
      this.season_ratings = sortAndPrepareRatingsBySeasons(this.ratings, this.$root.league.seasons);
      this.season_matches = sortAndPrepareMatchesBySeasons(this.matches, this.$root.league.seasons);
      this.players = _.filter(this.all_players, {team_id: this.$route.params.team_id});
      this.former_players = _.filter(this.all_players, (p) => { return p.team_id !== this.$route.params.team_id})
      this.is_loaded = true;
    }
  },
  async created() {
    window.team_show = this;
    await this.initializeComponent();
  },

  mounted() {
    console.log(this.$route.params.slug);
  },
  watch: {
    async '$route.params.team_id'(to, from) {
      if(to) {
        await this.initializeComponent();
      }
    }
  },
  setup() {
    return {
      team: ref([]),
      players: ref([]),
      all_players: ref([]),
      matches: ref([]),
      upcoming_matches: ref([]),
      current_season: ref({}),
      season_matches: ref({}),
      season_ratings: ref({}),
      is_loaded: ref(false),
      tabs: [
        { name: 'Details / Current Season', icon: InformationCircleIcon, to: { name: 'TeamShowDetails', path: 'details'} },
        { name: 'Seasonal History', icon: ArrowTrendingUpIcon, to: { name: 'TeamShowMatches', path: 'matches'} },
        { name: 'Player History', icon: UserGroupIcon, to: { name: 'TeamShowPlayers', path: 'players'} },
        { name: 'Clips', icon: FilmIcon, to: { name: 'TeamShowClips', path: 'clips'} }
      ]
    }
  }
}
</script>

<!-- .page-title {
  position: relative;
  z-index: 1;
  &::before {
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.0)), url('/public/images/textures/hextile_frosted_glass_20a_512.png');
    background-blend-mode: color-burn;
    background-size: 10%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2; /* Adjust for desired transparency */
    z-index: -1; /* Place it behind the text content */
  }
} -->

<!-- .page-title-text {
  position: relative;
  z-index: 1;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  background: linear-gradient(to bottom, rgba(253,38,24,1.0) 0%, rgba(255, 255, 255, 0.1) 100%), url('/public/images/textures/gummi_bear_glass_256.png');
  mix-blend-mode: multiply; /* You can experiment with different blend modes */
  color: transparent !important;
  font-size: 2.5rem;
  background-size: 15%;
  text-transform: uppercase;
} -->

<style>
.team-show {

}

.page-title-text {
  /* color: red; */
  /* mix-blend-mode: color-burn;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  background-blend-mode: color-dodge;
  color: transparent;
  font-size: 3rem;


  background: linear-gradient(to bottom, rgba(253, 115, 52, 0.5) 0%, rgba(253, 115, 52, 1.0) 100%), url('/public/images/textures/gummi_bear_glass_256.png'); */
}

/* .main-wrapper {
  position: relative;
  z-index: 1;
  &::before {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.0), rgba(255, 255, 255, 0.5)), url('/public/images/textures/gummi_bear_glass_256.png');
    background-blend-mode: color-burn;
    background-size: 10%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: -1;
  }
} */

.transition-page-wrapper {
  transform-style: preserve-3d;
}
</style>
