<template>
  <div :class="'tooltip' + name" class="relative" @mouseenter="show = true" @mouseleave="show = false">
    <slot></slot>
    <div
      class="absolute z-10 px-2 py-1 bg-glass-black-800 text-white text-sm rounded-md shadow-lg whitespace-nowrap uppercase hover-transition-1"
      v-if="show"
      :class="positionClass">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    text: String,
    position: {
      type: String,
      default: 'right'
    }
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    positionClass() {
      switch (this.position) {
        case 'top':
          return 'bottom-full mb-2 left-1/2 transform -translate-x-1/2';
        case 'bottom':
          return 'top-full mt-2 left-1/2 transform -translate-x-1/2';
        case 'left':
          return 'right-full mr-2 top-1/2 transform -translate-y-1/2';
        case 'right':
          return 'left-full ml-2 top-1/2 transform -translate-y-1/2';
        default:
          return 'top-full mt-2 left-1/2 transform -translate-x-1/2';
      }
    }
  }
};
</script>
