<template>
  <div class="transition-page-inner-wrapper">
    <PlayersList :players="parent.players" :team="parent.team" :title="parent.team.name + ' / Current Roster'" />
    <PlayersList :players="parent.former_players" :team="parent.team" :title="parent.team.name + ' / Past Members'" />
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import PlayersList from './_players_list';

export default {
  name: 'TeamShowPlayers',
  components: {
    PlayersList
  },
  props: ['parent'],
  methods: {
  },
  async created() {
    window.team_show_players = this;
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      team: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
.team-show {

}
</style>
