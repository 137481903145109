<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" v-if="is_loaded" class="relative z-10" @close="close()">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-18 sm:pl-18">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-full sm:max-w-md popover-glass-white-form">
                <div class="flex h-full flex-col overflow-y-scroll bg-glass-white-500 backdrop-blur-md shadow-xl">
                  <div class="px-4 py-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <div class="items-center"><h2 id="slide-over-heading" class="slide-over-header-1 mb-0">Edit Profile</h2></div>
                      <div class="items-center flex">
                        <button type="button" class="relative rounded-md bg-white/2 text-gray-500 hover:text-gray-700" @click="close()">
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="main-panel">
                    <div class="max-w-md mx-auto mt-4 p-6">
                      <Spinner v-if="is_saving" />

                      <form class="" @submit.prevent="submitForm" v-if="!is_saving">
                        <div class="col-span-full mb-4">
                          <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Avatar</label>
                          <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div class="text-center">
                              <div v-if="image_url" class="mx-auto h-12 w-12">
                                <img :src="image_url" alt="Current logo" class="rounded-full h-12 w-12 object-cover">
                              </div>

                              <div class="mx-auto h-12 w-12" v-else>
                                <img :src="record.image_url" alt="Current Avatar" class="rounded-full h-12 w-12 object-cover">
                              </div>

                              <div class="mt-4 flex text-sm leading-6 text-gray-600 justify-center mb-2">
                                <label for="file-upload" class="relative cursor-pointer rounded-md menu-button-glass-black-1 font-semibold text-gray-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-secondary hover:bg-glass-white-500">
                                  <span>Upload a file</span>
                                  <input id="file-upload" name="file-upload" type="file" @change="handleAvatarUpload" class="sr-only" />
                                </label>
                                <p v-if="false" class="pl-1">or drag and drop</p>
                              </div>
                              <p class="text-xs leading-5 text-gray-600">Square image, ideally 128x128 - PNG, JPG, GIF</p>
                            </div>
                          </div>
                        </div>

                        <div class="col-span-full mb-4" v-if="record.show_hero_banner">
                          <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">Banner</label>
                          <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div class="text-center">
                              <div v-if="hero_image_url" class="mx-auto h-12 w-12">
                                <img :src="hero_image_url" alt="Current logo" class="rounded-full h-12 w-12 object-cover">
                              </div>

                              <div class="mx-auto h-12 w-12" v-else>
                                <img :src="record.hero_image_url" alt="Current Banner" class="rounded-full h-12 w-12 object-cover">
                              </div>

                              <div class="mt-4 flex text-sm leading-6 text-gray-600 justify-center mb-2">
                                <label for="hero-image-upload" class="relative cursor-pointer rounded-md menu-button-glass-black-1 font-semibold text-gray-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-secondary hover:bg-glass-white-500">
                                  <span>Upload a file</span>
                                  <input id="hero-image-upload" name="hero-image-upload" type="file" @change="handleHeroImageUpload" class="sr-only" />
                                </label>
                                <p v-if="false" class="pl-1">or drag and drop</p>
                              </div>
                              <p class="text-xs leading-5 text-gray-600">Rectangular banner image, ideally 128px tall, up to 1280px wide - PNG, JPG, GIF</p>
                            </div>
                          </div>
                        </div>

                        <div class="mb-4">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
                            Name
                          </label>
                          <input v-model="record.username" type="text" id="name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="User Name">
                        </div>

                        <div class="mb-4">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="country">
                            Country
                          </label>
                          <CountrySelect :record="record" />
                        </div>

                        <div class="mb-4" v-if="false">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="bio">
                            Bio
                          </label>
                          <textarea v-model="record.bio" id="bio" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Team Bio (not currently working, may or may not implement in the future)"></textarea>
                        </div>

                        <div class="mb-4">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="twitch_url">
                            Twitch URL
                          </label>
                          <input v-model="record.twitch_url" type="url" id="twitch_url" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Twitch URL">
                        </div>

                        <div class="mb-4">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="twitch_url">
                            YouTube URL
                          </label>
                          <input v-model="record.youtube_url" type="url" id="youtube_url" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Twitch URL">
                        </div>

                        <div class="mb-4">
                          <label class="block text-gray-700 text-sm font-bold mb-2" for="tiktok_url">
                            TikTok URL
                          </label>
                          <input v-model="record.tiktok_url" type="url" id="tiktok_url" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="TikTok URL">
                        </div>

                        <div class="mb-4">
                          <label class="flex items-center">
                            <input v-model="record.show_hero_banner" type="checkbox" class="form-checkbox h-5 w-5 text-blue-600"><span class="ml-2 text-gray-700">Show Hero Banner?</span>
                          </label>
                        </div>

                        <div class="flex items-center justify-between">
                          <button @click="save()" class="button-glass-black-1 hover-transition-1" type="submit">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import Mixins from '@/components/mixins';
import ImageUploadMixin from '@/components/mixins/image_upload_mixin';
import Tooltip from '@/components/ui/tooltip';

import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { XMarkIcon, EllipsisVerticalIcon, UserPlusIcon } from '@heroicons/vue/24/outline';
import DiscordIcon from '@/components/icons/discord_icon';
import ConfirmActionModal from '@/components/ui/confirm_action_modal';
import UIComponents from '@/components/ui';

export default {
  name: 'UsersShowFormPopout',
  mixins: _.values(Mixins),
  components: {
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
    CheckIcon, ChevronUpDownIcon, Dialog, DialogPanel,
    Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot,
    XMarkIcon, EllipsisVerticalIcon, DiscordIcon,
    ConfirmActionModal, ...UIComponents
  },
  props: ['parent', 'close', 'toggle'],
  methods: {
    async handleAvatarUpload(event) {
      this.image = event.target.files[0];
      let extension = this.image.type.split('/')[1];
      this.image_url = URL.createObjectURL(this.image);
      this.avatarData = await this.resizeImage(this.image, 256, `user-logo-${this.record.id}-256.${extension}`);
    },
    async handleHeroImageUpload(event) {
      this.hero_image = event.target.files[0];
      let extension = this.hero_image.type.split('/')[1];
      this.hero_image_url = URL.createObjectURL(this.hero_image);
      this.heroImageData = await this.resizeAndCropImage(this.hero_image, `user-hero-image-${this.record.id}-256.${extension}`, 256);
    },
    async save() {
      this.is_saving = true;
      let result;

      try {
        result = await this.record.save();
        this.showSuccessMessage("Profile Updated");
      } catch(e) {
        console.log("CAUGHT ERROR HERE");
      }
      if(this.image) { await this.postImage(); }
      if(this.hero_image) { await this.postHeroImage(); }

      //wonky but works for now
      if(this.current_username !== this.record.username) {
        let user = await Models.User.find(this.record.id)

        this.$router.push({
          name: 'UserShowDetails',
          params: {
            handle: user.handle
          }
        })
      } else {
        this.$root.$$reload();
      }

      this.close();
    },
    async postImage() {
      // await this.record.attachAsset('logo', this.formData);
      await this.record.attachAsset('avatar', this.avatarData);
    },
    async postHeroImage() {
      await this.record.attachAsset('hero_image', this.heroImageData);
    }
  },
  async created() {
    window.users_form = this;

    this.record = this.$parent.record;
    this.current_username = this.record.username;
    this.is_loaded = true;
    this.open = true;
  },
  mounted() {
  },
  setup() {
    return {
      is_loaded: ref(false),
      is_saving: ref(false),
      open: ref(false),
      record: ref({}),
      selected: ref({}),
      image: ref(null),
      image_url: ref(null),
      hero_image: ref(null),
      hero_image_url: ref(null)
    }
  }
}
</script>

<style>
</style>
