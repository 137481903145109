<template>
  <TransitionRoot as="template" :show="open">
     <Dialog as="div" class="relative z-10" @close="open = false">
       <div class="fixed inset-0" />

       <div class="fixed inset-0 overflow-hidden">
         <div class="absolute inset-0 overflow-hidden">
           <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
             <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
               <DialogPanel class="pointer-events-auto w-screen max-w-md">
                 <div class="flex h-full flex-col overflow-y-scroll bg-glass-white-500 backdrop-blur-md shadow-xl">
                   <div class="px-4 py-6 sm:px-6">
                     <div class="flex items-start justify-between">
                       <h2 id="slide-over-heading" class="text-base font-semibold leading-6 text-gray-900">Team Player Profile</h2>
                       <div class="ml-3 flex h-7 items-center">
                         <button type="button" class="relative rounded-md bg-white/2 text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-glass-white-500" @click="open = false">
                           <span class="absolute -inset-2.5" />
                           <span class="sr-only">Close panel</span>
                           <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                         </button>
                       </div>
                     </div>
                   </div>
                   <!-- Main -->
                   <div>
                     <div class="pb-1 sm:pb-6">
                       <div>
                         <div class="relative h-40 sm:h-56">
                           <img class="absolute h-full w-full object-cover" :src="player.image_url" alt="" />
                         </div>
                         <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                           <div class="sm:flex-1">
                             <div>
                               <div class="flex items-center">
                                 <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">{{player.name}}</h3>
                                 <span class="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400">
                                   <span class="sr-only">{{player.status}}</span>
                                 </span>
                               </div>
                               <p class="text-sm text-gray-500">{{player.name}}</p>
                             </div>
                             <div class="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                               <button @click="copyDiscord()" type="button" class="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1">
                                 <ClipboardIcon class="h-5 w-5" /> <span class="pl-2 pr-2">Copy ID</span> <DiscordIcon class="h-5 w-5" />
                               </button>
                               <router-link :to="playerPathFor(player.id)" class="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">View</router-link>
                               <div class="ml-3 inline-flex sm:ml-0">
                                 <Menu as="div" class="relative inline-block text-left">
                                   <MenuButton class="relative inline-flex items-center rounded-md bg-white p-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                     <span class="absolute -inset-1" />
                                     <span class="sr-only">Open options menu</span>
                                     <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                                   </MenuButton>
                                   <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                     <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                       <div class="py-1">
                                         <MenuItem v-slot="{ active }">
                                           <router-link :to="playerPathFor(player.id)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">View {{$root.league.name}} profile</router-link>
                                         </MenuItem>
                                         <MenuItem v-slot="{ active }">
                                           <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">View Global Profile</a>
                                         </MenuItem>
                                       </div>
                                     </MenuItems>
                                   </transition>
                                 </Menu>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                     <div class="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                       <dl class="space-y-8 px-4 sm:space-y-6 sm:px-6">
                         <div v-if="false">
                           <dt class="text-sm font-medium text-gray-600 sm:w-40 sm:flex-shrink-0">Bio</dt>
                           <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                             <p>Enim feugiat ut ipsum, neque ut. Tristique mi id elementum praesent. Gravida in tempus feugiat netus enim aliquet a, quam scelerisque. Dictumst in convallis nec in bibendum aenean arcu.</p>
                           </dd>
                         </div>
                         <div>
                           <dt class="text-sm font-medium text-gray-600 sm:w-40 sm:flex-shrink-0">Nationality</dt>
                           <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">{{player.nationality}}</dd>
                         </div>
                         <div>
                           <dt class="text-sm font-medium text-gray-600 sm:w-40 sm:flex-shrink-0">Discord Name</dt>
                           <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">{{player.name}}</dd>
                         </div>
                         <div>
                           <dt class="text-sm font-medium text-gray-600 sm:w-40 sm:flex-shrink-0">Joined Team</dt>
                           <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                             <time datetime="1988-06-23">{{ displayDate(player.created_at) }}</time>
                           </dd>
                         </div>
                       </dl>
                     </div>
                   </div>
                 </div>
               </DialogPanel>
             </TransitionChild>
           </div>
         </div>
       </div>
     </Dialog>
   </TransitionRoot>

</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';
import BaseMixin from '@/components/mixins/base_mixin';
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { XMarkIcon, EllipsisVerticalIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
import DiscordIcon from '@/components/icons/discord_icon';

export default {
  name: 'TeamPlayerPopout',
  mixins: [ BaseMixin, TemplateHelpersMixin ],
  components: {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    EllipsisVerticalIcon,
    ClipboardIcon,
    DiscordIcon
  },
  props: ['parent', 'title', 'player', 'team'],
  methods: {
    playerRole(player) {
      if(this.team.captain_id === player.id) {
        return "Captain"
      } else if(this.team.co_captain_id === player.id) {
        return "Co-Captain"
      } else if(player.team_id !== this.team.id) {
        return "Former Player"
      } else {
        return "Player"
      }
    },
    async copyDiscord() {
      try {
        await navigator.clipboard.writeText(this.player.discord_name || 'discord_name');
        this.$$broadcast('notification.show', {
          heading: 'Copy Success',
          body: `Discord ID Copied to clipboard`
        })
      } catch(err) {
        console.log(err);
      }
    }
  },
  async created() {
    window.team_player_popout = this;
    Subscriptions.$$on("team.show.player.show", (player) => {
      this.player = player;
      this.open = true;
    })
    this.is_loaded = true;
  },
  mounted() {

  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false),
      stats: ref([]),
      open: ref(false),
      player: ref({})
    }
  }
}
</script>

<style>
</style>
