import {BaseModel, Model} from './base_model';

@Model({
  type: 'cast',
  url: 'api/front/casts',
  attributes: {
    url: {},
    cast_source: { read_only: true },
    recent_cast_title: { read_only: true },
    starts_at: { read_only: true }
  }
})

export class Cast extends BaseModel {

}
