<template>
  <div><h1 class="home-page-title p-3 text-shadow-md-black">XRL Sponsors</h1></div>
  <div class="sponsors home-page-widget-4 mb-0">
    <div class="block w-full rounded-md border border-transparent px-6 py-6 text-sm font-medium text-gray-900 sm:w-auto flex justify-center">
      <div class="mt-0 mx-4 text-base text-white font-aldrich font-small leading-2 text-center">
        XRL is looking for sponsors and partners to join us on our journey.<br>
        If you have interest in being part of this exciting opportunity,<br> please reach out to us for more information.
      </div>
    </div>
    <div class="flex justify-center m-2 mb-4">
      <a href="mailto:league@xrl.pro" class="block glowing-orange-500-to-sky-500 home-page-glass-button font-semibold p-3 px-12 font-agency uppercase  mb-6 hover-transition-3">
        <EnvelopeIcon class="inline h-6 w-6 -mt-1 mr-2" />

        <span>EMail Us</span>
      </a>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { EnvelopeIcon } from '@heroicons/vue/24/outline';
const SPONSORS = [
  { name: "Transistor", src: "https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg" },
  { name: "Reform", src: "https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg" }
];

export default {
  name: 'HomePageSponsors',
  components: { EnvelopeIcon },
  async created() {
  },
  setup() {
    return {
      sponsors: ref(SPONSORS)
    }
  }
}
</script>

<style>
.home .sponsors {
}
</style>
