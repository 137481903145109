import { createRouter, createWebHistory } from 'vue-router'

// Pages
import Home from '@/components/pages/home.vue';
import About from '@/components/pages/about.vue';

import TournamentsIndex from '@/components/tournaments/index.vue';
import StandingsIndex from '@/components/standings/index.vue';
import UserShow from '@/components/users/show.vue';
import UserShowDetails from '@/components/users/show/details.vue';
import UserShowLeagues from '@/components/users/show/leagues.vue';
import UserShowClips from '@/components/users/show/clips.vue';
import PlayersIndex from '@/components/players/index.vue';
import PlayerShow from '@/components/players/show.vue';
import PlayerShowDetails from '@/components/players/show/details.vue';
import PlayerShowLeagues from '@/components/players/show/leagues.vue';
import PlayerShowClips from '@/components/players/show/clips.vue';
import TeamShow from '@/components/team/show.vue';
import TeamShowDetails from '@/components/team/show/details';
import TeamShowPlayers from '@/components/team/show/players';
import TeamShowMatches from '@/components/team/show/matches';
import TeamShowClips from '@/components/team/show/clips';
import UserStandingsIndex from '@/components/user_standings/index';
import AdminIndex from '@/components/admin/index';
import TradeshowsIndex from '@/components/tradeshows/index';
import MyClips from '@/components/my/clips';
import MyLeagues from '@/components/my/leagues';
import MyTeams from '@/components/my/teams';
import MyMatches from '@/components/my/matches';
import ToolsCastOverlay from '@/components/tools/cast/overlay';
// Define your routes
const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: About },
  { path: '/tools/cast/overlay', name: 'ToolsCastOverlay', component: ToolsCastOverlay},
  { path: '/admin/index', name: 'AdminIndex', component: AdminIndex },
  { path: '/user/:handle',
    name: 'UserShow',
    component: UserShow,
    children: [
      {
        path: 'details',
        name: 'UserShowDetails',
        component: UserShowDetails
      },
      {
        path: 'leagues',
        name: 'UserShowLeagues',
        component: UserShowLeagues
      },
      {
        path: 'clips',
        name: 'UserShowClips',
        component: UserShowClips
      }
    ]
  },
  { path: '/my/clips', name: 'MyClips', component: MyClips },
  { path: '/my/leagues', name: 'MyLeagues', component: MyLeagues },
  { path: '/my/teams', name: 'MyTeams', component: MyTeams },
  { path: '/my/matches', name: 'MyMatches', component: MyMatches },
  { path: '/players', name: 'UserStandingsIndex', component: UserStandingsIndex },
  { path: '/players/region/:region', name: 'UserStandingsIndexRegion', component: UserStandingsIndex },
  { path: '/tradeshows/:name', name: 'TradeshowsIndex', component: TradeshowsIndex },
  { path: '/leagues/:slug/tournaments', name: 'TournamentsIndex', component: TournamentsIndex },
  { path: '/leagues/:slug/players', name: 'PlayersIndex', component: PlayersIndex },
  { path: '/leagues/:slug/players/region/:region', name: 'PlayersIndexRegion', component: PlayersIndex },
  {
    path: '/leagues/:slug/player/:id',
    name: 'PlayerShow',
    component: PlayerShow,
    children: [
      {
        path: 'details',
        name: 'PlayerShowDetails',
        component: PlayerShowDetails
      },
      {
        path: 'leagues',
        name: 'PlayerShowLeagues',
        component: PlayerShowLeagues
      },
      {
        path: 'clips',
        name: 'PlayerShowClips',
        component: PlayerShowClips
      },
      // {
      //   path: 'players',
      //   name: 'TeamShowPlayers',
      //   component: TeamShowPlayers
      // }
    ]
  },
  { path: '/leagues/:slug', name: 'LeagueShow', component: StandingsIndex },
  { path: '/leagues/:slug', name: 'Standings', component: StandingsIndex },
  { path: '/leagues/:slug/region/:region', name: 'StandingsIndexRegion', component: StandingsIndex },
  { path: '/leagues/:slug/region/:region?/season/:season?', name: 'StandingsIndexRegionSeason', component: StandingsIndex },
  {
    path: '/leagues/:slug/team/:team_id',
    name: 'TeamShow',
    component: TeamShow,
    children: [
      {
        path: 'details',
        name: 'TeamShowDetails',
        component: TeamShowDetails
      },
      {
        path: 'matches',
        name: 'TeamShowMatches',
        component: TeamShowMatches
      },
      {
        path: 'players',
        name: 'TeamShowPlayers',
        component: TeamShowPlayers
      },
      {
        path: 'clips',
        name: 'TeamShowClips',
        component: TeamShowClips
      }
    ]
  }
]

// Create the router instance and pass the `routes` option
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Use browser history
  routes // short for `routes: routes`
})

export default router
