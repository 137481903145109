/* eslint-disable */
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';

function setLeague() {
  // let league = _.find(this.$root.leagues, (l) => { return this.$route.params.slug === l.subdomain });
  // this.league = league;
  // if(this.league) {
  //   this.$$broadcast("league.loaded", this.league);
  // }
  this.league = this.$root.league;
}

function $$on(...args) {
  let sub = Subscriptions.$$on(...args);
  this._subscriptions.push(sub);
}

function $$broadcast(...args) {
  Subscriptions.$$broadcast(...args);
}

function $$initialize() {
  if(!this._subscriptions) { this._subscriptions = [] }
}

function $$deinitialize() {
  _.invokeMap(this._subscriptions, 'dispose');
  this._subscriptions = null;
}

function ensureCurrentUserLoggedIn() {
  if(!this.$root.current_user.signed_in) {
    this.$router.push('/');
  }
}

function showSuccessMessage(message, title='Success') {
  this.$$broadcast('notification.success', {
    heading: title,
    body: message
  });
}

function $$updateQuery(query={}) {
  this.$router.push({
    name: this.$route.name,
    params: this.$route.params,
    query: { ...this.$route.query, ...query }
  });
}

function $$updateParams(params={}) {
  this.$router.push({
    name: this.$route.name,
    params: { ...this.$route.params, ...params },
    query: this.$route.query
  });
}

function $$updateCurrentRoute(params={}, query={}) {
  this.$router.push({
    name: this.$route.name,
    params: { ...this.$route.params, ...params },
    query: { ...this.$route.query, ...query }
  });
}

function $$goTo(name, params={}, query={}) {
  this.$router.push({
    name: name,
    params: { ...this.$route.params, ...params },
    query: { ...this.$route.query, ...query }
  });
}

export default {
  methods: {
    $$initialize,
    $$deinitialize,
    setLeague,
    $$on,
    $$broadcast,
    ensureCurrentUserLoggedIn,
    showSuccessMessage,
    $$updateQuery,
    $$updateParams,
    $$updateCurrentRoute,
    $$goTo
  }
}
