<template>
  <div class="flex flex-col overlay-page">
    <div class="overlay-embed flex justify-around items-start mb-4 bg-transparent p-2" style="height: 300px;" v-if="embedReady()">
      <Team :team="data.teams[0]" />

      <div style="width:106px;" class="round-scores flex flex-col justify-center mx-3 " :class="'rounds-'+ data.teams[0].rounds.length.toString()">
        <div v-for="(score, index) in data.rounds[0]" :key="'round-' + index" class="flex items-center mb-1 text-white font-agency">
          <div style="min-width:20px;" class="score-wrapper py-1 home-team-bg-color text-md rounded text-center">{{ score }}</div>
          <div style="min-width:50px;" class="score-wrapper mx-2 py-1 rounded bg-glass-black-500 text-center">Round {{ index + 1 }}</div>
          <div style="min-width:20px;" class="score-wrapper py-1 away-team-bg-color rounded text-center">{{ data.rounds[1][index] }}</div>
        </div>
      </div>

      <Team :team="data.teams[1]" />
    </div>

    <div class="flex flex-col overlay-form p-12 bg-gray-100" v-if="true">
      <div class="flex select-league mb-4 items-center space-x-2">
        <label for="selected-league" class="w-32">League</label>
        <select v-model="selectedLeague" @change="leagueSelected" class="p-2 border border-gray-300 rounded w-full">
          <option disabled value="">Select a League</option>
          <option v-for="league in leagues" :key="league.id" :value="league">{{ league.name }}</option>
        </select>
      </div>

      <div class="add-round my-4">
        <button @click="addRound" class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 mr-2" :disabled="!teamsSelected()">Add Round</button>
        <button @click="clearSession" class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 mr-2">Clear Session</button>
        <button @click="copyUrl" class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700">Copy URL</button>
      </div>

      <div class="teams flex justify-between space-x-4">
        <div class="flex flex-col home-team items-center space-x-2">
          <div class="flex team-select items-center">
            <label for="home-team" class="w-36 mr-2">Home Team: </label>
            <select v-model="home_team" @change="teamSelected('home', $event)" class="p-2 border border-gray-300 rounded w-full">
              <option disabled value="">Select Home Team</option>
              <option v-for="team in teams" :key="team.id" :value="team">{{ team.name }}</option>
            </select>
          </div>

          <div class="flex rounds">
            <div>
              <input v-for="(score, index) in home_team.rounds" :key="'home-' + index" type="number" v-model="home_team.rounds[index]" @input="onInputChange" class="p-2 border border-gray-300 rounded w-full my-1">
            </div>
          </div>
        </div>
        <div class="flex flex-col away-team items-center space-x-2">
          <div class="flex team-select">
            <label for="away-team" class="w-36 mr-2">Away Team: </label>
            <select v-model="away_team" @change="teamSelected('away', $event)" class="p-2 border border-gray-300 rounded w-full">
              <option disabled value="">Select Away Team</option>
              <option v-for="team in teams" :key="team.id" :value="team">{{ team.name }}</option>
            </select>
          </div>

          <div class="rounds">
            <div>
              <input v-for="(score, index) in away_team.rounds" :key="'away-' + index" type="number" v-model="away_team.rounds[index]" @input="onInputChange" class="p-2 border border-gray-300 rounded w-full my-1">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { XMarkIcon, EllipsisVerticalIcon, ClipboardIcon } from '@heroicons/vue/24/outline';
import { Helpers } from 'traxjs';
import Mixins from '@/components/mixins';
import {BaseModel} from '@/models/base_model';
import Team from './_team';

export default {
  name: 'ToolsCastOverlay',
  mixins: _.values(Mixins),
  components: {
    Team
  },
  data() {
    return {
      selectedLeague: null,
      leagues: ref([]),
      teams: ref([]),
      home_team: ref({}),
      home_team_players: ref([]),
      home_team_rating: ref({}),
      away_team: ref({}),
      away_team_players: ref([]),
      away_team_rating: ref({}),
      rounds: ref([]),
      data: ref({}),
      is_form: ref(false)
    };
  },
  computed: {
    homeTeamStyle() {
      const hex = '#HomeTeamHexColor';
      const rgba = this.hexToRgba(hex, 0.8);
      return { backgroundColor: rgba };
    },
    awayTeamStyle() {
      const hex = '#AwayTeamHexColor';
      const rgba = this.hexToRgba(hex, 0.8);
      return { backgroundColor: rgba };
    }
  },
  methods: {
    copyUrl() {
      this.copyToClipboard(`${window.location.host}${this.$route.fullPath}`);
    },
    onInputChange() {
      this.sync();
    },
    embedReady() {
      return _.keys(this.data).length > 1;
    },
    async teamSelected(property) {
      let team = this[`${property}_team`];
      team.rounds = [];
      console.log(team, 'team is');
      console.log(property, 'team_selected');

      this[`${property}_team_players`] = await Models.Player.search({ by_current_team_id: team.id });

      let ratings = await Models.Rating.search({
        by_team_id: team.id,
        by_season_id: this.selectedLeague.current_season.id
      });

      if(ratings.length > 0) {
        this[`${property}_team_rating`] = ratings[0];
      }
    },
    teamsSelected() { return this.home_team.name && this.away_team.name },
    async leagueSelected() {
      console.log("Selected league is:", this.selectedLeague);
      // Assuming Models.Team.$search returns an array directly
      this.teams = await Models.Team.$search({
        by_league_id: this.selectedLeague.id
      });
      // Reset home and away teams when league changes
      this.home_team = {};
      this.away_team = {};
    },
    addRound() {
      this.home_team.rounds.push(0); // Initialize new round with a score of 0
      this.away_team.rounds.push(0); // Initialize new round with a score of 0

      if(this.home_team.rounds.length === 1 && this.away_team.rounds.length === 1) {
        console.log(this.home_team.rounds, this.away_team.rounds);

        this.sync();
      }
    },
    clearSession() {
      clearInterval(this.pollingInterval);
      this.$router.push({
        name: this.$route.name,
        query: {}
      });
    },
    serializeTeam(property) {
      let team = this[`${property}_team`];
      let players = this[`${property}_team_players`];
      let rating = this[`${property}_team_rating`];
      return {
        ...team.attributes,
        players: _.map(players, (player) => { return {name: player.name, image_url: player.image_url}}),
        rounds: team.rounds,
        rating: {...rating.attributes}
      }
    },
    serialize() {
      let home_team = this.serializeTeam('home');
      let away_team = this.serializeTeam('away');

      return {
        league_name: this.selectedLeague.display_name,
        teams: [ home_team, away_team],
        rounds: [ home_team.rounds, away_team.rounds ]
      }
    },
    async fetchData() {
      try {
        let data = await BaseModel.$axios.get(`api/front/json_data/${this.$route.query.uuid}`);
        this.data = data.data;
      } catch(e) {
        console.log(e);
      }
    },
    startPolling() {
      this.fetchData();
      this.pollingInterval = setInterval(this.fetchData, 1000 * 2);
    },
    async sync() {
      console.log('do sync now');
      BaseModel;

      this.data = this.serialize();

      await BaseModel.$axios.put(
        `api/front/json_data/${this.$route.query.uuid}`,
        {
          data: {...this.serialize()}
        }
      );
    }
  },
  async created() {
    if(!this.$route.query.uuid) {
      this.ensureUserIsCaster();
      this.is_form = true;

      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          uuid: Helpers.uuid()
        }
      });
    } else {


      try {
        await this.fetchData();
        this.startPolling();
      } catch(e) {
        console.log(e);
      }
    }
    window.overlay_form = this;
    this.leagues = await Models.League.fetchAll();

//     this.data = {
//     "league_name": "Racket Club",
//     "rounds": [[0,1,3], [1,2,4]],
//     "teams": [
//         {
//             "id": "1e7da7bb-a061-4d9b-a1a9-cdd0faba9f5c",
//             "name": "Descenders",
//             "placeholder_logo_url": "https://vrmasterleague.com//images/logos/teams/3f2dd56e-dc93-4551-bb18-48b9d1563792.png",
//             "creator_id": "",
//             "captain_id": "1d381463-d730-48a3-aef3-880760f3e4aa",
//             "co_captain_id": "1d381463-d730-48a3-aef3-880760f3e4aa",
//             "image_url": "https://vrmasterleague.com//images/logos/teams/3f2dd56e-dc93-4551-bb18-48b9d1563792.png",
//             "league_id": "1b5db2f9-589b-489e-a399-2251d4391cfc",
//             "status": "active",
//             "players": [
//                 {
//                     "name": "GengarLucas",
//                     "image_url": "https://xrlpro.s3.amazonaws.com/frontend/public/xrl_icon_logo_bg.gif"
//                 },
//                 {
//                     "name": "SillyArab",
//                     "image_url": "https://xrlpro.s3.amazonaws.com/frontend/public/xrl_icon_logo_bg.gif"
//                 },
//                 {
//                     "name": "Dr0xu5",
//                     "image_url": "https://xrlpro.s3.amazonaws.com/frontend/public/xrl_icon_logo_bg.gif"
//                 },
//                 {
//                     "name": "GaraNexis2",
//                     "image_url": "https://xrlpro.s3.amazonaws.com/frontend/public/xrl_icon_logo_bg.gif"
//                 },
//                 {
//                     "name": "Jinx_Xaut0X",
//                     "image_url": "https://xrlpro.s3.amazonaws.com/frontend/public/xrl_icon_logo_bg.gif"
//                 }
//             ],
//             "rounds": [
//                 0
//             ],
//             "rating": {
//                 "id": "1fba2017-34dd-4fd9-9be9-4e2099459792",
//                 "rating": 1552,
//                 "rank": 56,
//                 "win_count": 10,
//                 "loss_count": 16,
//                 "draw_count": 0,
//                 "season_id": "2a27abb4-1bd9-45e7-b916-50ee6b55a6ba",
//                 "division": "platinum_2"
//             }
//         },
//         {
//             "id": "1ef5a09f-aaeb-46ec-b43e-f1ac22bac997",
//             "name": "The Instrumentalists",
//             "placeholder_logo_url": "https://vrmasterleague.com//images/logos/teams/ed5fb7df-ef4f-4940-8786-1b507cab7917.png",
//             "creator_id": "",
//             "captain_id": "1d173d80-36d4-4265-92fc-3c6b01ea3bc4",
//             "co_captain_id": "1d173d80-36d4-4265-92fc-3c6b01ea3bc4",
//             "image_url": "https://vrmasterleague.com//images/logos/teams/ed5fb7df-ef4f-4940-8786-1b507cab7917.png",
//             "league_id": "1b5db2f9-589b-489e-a399-2251d4391cfc",
//             "status": "active",
//             "players": [
//                 {
//                     "name": "Aperson2u",
//                     "image_url": "https://xrlpro.s3.amazonaws.com/frontend/public/xrl_icon_logo_bg.gif"
//                 },
//                 {
//                     "name": "Life-is-karma",
//                     "image_url": "https://xrlpro.s3.amazonaws.com/frontend/public/xrl_icon_logo_bg.gif"
//                 },
//                 {
//                     "name": "DBH-",
//                     "image_url": "https://xrlpro.s3.amazonaws.com/frontend/public/xrl_icon_logo_bg.gif"
//                 },
//                 {
//                     "name": "Z00MBIE",
//                     "image_url": "https://xrlpro.s3.amazonaws.com/frontend/public/xrl_icon_logo_bg.gif"
//                 }
//             ],
//             "rounds": [
//                 0
//             ],
//             "rating": {
//                 "id": "1faecf23-915f-41f9-931f-7a93eae1aeb9",
//                 "rating": 1533,
//                 "rank": 252,
//                 "win_count": 2,
//                 "loss_count": 0,
//                 "draw_count": 0,
//                 "season_id": "2a27abb4-1bd9-45e7-b916-50ee6b55a6ba",
//                 "division": "platinum_1"
//             }
//         }
//     ]
// }

  },
  mounted() {
    document.body.style.backgroundColor = 'transparent';
  }
}
</script>

<style scoped>
.rounds-7 .score-wrapper, .rounds-8 .score-wrapper, .rounds-9 .score-wrapper {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 0.8em;
}

.home-team-bg-color {
  background: rgba(65, 207, 253, 0.8);
  /* background: rgba(59, 76, 117, 0.8); */
}

.away-team-bg-color {
  background: rgba(248, 149, 55, 0.8);
  /* background: rgba(140, 69, 63, 0.8); */
}
.team-info:nth-child(1) {
  background: rgba(65, 207, 253, 0.8);
  text-shadow: 0 -1px 0 rgba(65, 207, 253, 0.5), 0 1px 0 rgba(65, 207, 253, 0.5);

  /* background: rgba(59, 76, 117, 0.8);
  text-shadow: 0 -1px 0 rgba(59, 76, 117, 0.5), 0 1px 0 rgba(59, 76, 117, 0.5); */
}

.team-info:nth-child(3) {
  background: rgba(248, 149, 55, 0.8);
  text-shadow: 0 -1px 0 rgba(248, 149, 55, 0.5), 0 1px 0 rgba(248, 149, 55, 0.5);

  /* background: rgba(140, 69, 63, 0.8);
  text-shadow: 0 -1px 0 rgba(140, 69, 63, 0.5), 0 1px 0 rgba(140, 69, 63, 0.8); */
}
</style>
