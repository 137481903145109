<template>
  <li class="col-span-1 flex flex-col divide-y divide-glass-white-150 rounded-lg bg-glass-white-500 shadow backdrop-blur-lg font-aldrich">
    <ConfirmActionModal v-if="show_confirm_leave_team"
                        :text="`You are about to leave ${team.name}, please confirm. Note: if your team falls below the minimum number of players to play a match, your team will not have matches generated next week. Please make sure to notify the rest of your team`"
                        :title="'Are you sure?'"
                        confirmText="Leave Team"
                        declineText="Cancel"
                        :onConfirm="leave" />

    <div class="p-4 flex items-center justify-between">
      <div class="flex items-center">
        <img class="h-8 w-8 flex-shrink-0 rounded-full" loading="lazy" :src="$root.logoFor(leagueFor(team).slug)" alt="" />
        <span class="ml-2 text-md font-medium text-gray-900">{{ leagueFor(team).name }}</span>
      </div>
    </div>

    <router-link as="div" :to="teamPathFor(team)" class="flex flex-1 flex-col items-center p-4 hover:bg-glass-white-300 hover-transition-2">
      <div class="team-logo"><img loading="lazy" class="mx-auto h-16 w-16 flex-shrink-0 rounded-full" :src="team.image_url" alt="" /></div>
      <h3 class="mt-2 text-md font-medium text-gray-900">{{ team.name }}</h3>
    </router-link>

    <div class="flex justify-center -mt-px divide-x divide-glass-white-100">
      <a @click="edit()" class="flex w-1/2 items-center justify-center gap-x-3 rounded-bl-lg py-4 text-md text-gray-900 cursor-pointer hover:bg-glass-white-400 hover-transition-1 font-agency uppercase">
        <WrenchScrewdriverIcon name="pencil" class="h-5 w-5 text-gray-800" />
        Manage Team
      </a>
      <a v-if="!leagueFor(team).is_singles" @click="onLeaveTeamClick()" class="flex w-1/2 items-center justify-center gap-x-3 rounded-br-lg py-4 text-md  text-gray-900 cursor-pointer hover:bg-glass-white-400 hover-transition-1 font-agency uppercase">
        <UserMinusIcon name="logout" class="h-5 w-5 text-gray-800" />
        Leave Team
      </a>
    </div>
  </li>
</template>

<script>
import { ref } from 'vue';
import { WrenchScrewdriverIcon, UserMinusIcon } from '@heroicons/vue/24/outline';
import ConfirmActionModal from '@/components/ui/confirm_action_modal';
import _ from 'lodash';
import Mixins from '@/components/mixins';

export default {
  name: 'MyTeamsTeam',
  props: ['team', 'league'],
  mixins: _.values(Mixins),
  components: {
    WrenchScrewdriverIcon, UserMinusIcon, ConfirmActionModal
  },
  methods: {
    teamPathFor(team) {
      let league = this.leagueFor(team);
      return `/leagues/${league.subdomain}/team/${team.id}/details`;
    },
    leagueFor(team) {
      return this.$root.findLeagueById(team.league_id);
    },
    player() {
      return _.find(this.$parent.players, {team_id: this.team.id});
    },
    edit() {
      console.log('edit');
      this.$parent.record = this.team;
      this.$parent.form_visible = true;
    },
    onLeaveTeamClick() {
      this.show_confirm_leave_team = true;
    },
    async leave() {
      try {
        await this.player().memberAction('leave_team');
        this.showSuccessMessage(`You have left ${this.team.name}`);
        this.$root.$$reload();
      } catch(e) {
        console.log(e);
      }
    }
  },
  async created() {
  },
  data() {
    return {
      show_confirm_leave_team: ref(false)
    }
  }
}
</script>

<style>
</style>
