<template>
  <div class="home-page-widget mr-2">
    <h1 class="title font-agency text-2xl text-orange-100 uppercase">XRL / All Leagues</h1>

    <div class="grid grid-cols-1 gap-3 sm:grid-cols-3 p-2">
      <router-link :to="rootLeaguePathFor(league.slug)" v-for="league in leagues" :key="league.id" class="relative flex items-center space-x-3 rounded-lg border border-glass-white-100 bg-glass-white-100/20 px-4 py-4 shadow-md shadow-inner hover:border-glass-white-200 hover-transition-2 hover:ring-2 ring-secondary/50 ring-offset-1 ring-offset-orange-200/50 opacity-80 hover:opacity-100">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" :src="$root.logoFor(league.slug)" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <a href="#" class="focus:outline-none">
            <p class="text-lg font-medium text-gray-100 font-agency leading-5 text-center">{{ league.name }}</p>
          </a>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import * as Models from '@/models';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';

export default {
  name: 'HomePageLeagues',
  components: {
  },
  mixins: [ TemplateHelpersMixin ],
  async created() {
    window.home_leagues = this;
    this.leagues = await Models.League.fetchAll();
  },
  setup() {
    return {
      is_loaded: ref(true),
      leagues: ref([])
    }
  }
}
</script>

<style>
.home .leagues {
}
</style>
