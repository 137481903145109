/* eslint-disable */
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const month = months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return {month, day, year};
  // return `${month} ${day}, ${year}`;
}
