 /* eslint-disable */
import axios from 'axios'
import _ from 'lodash';
import {BaseModel} from '@/models/base_model';
// const BASE_URL = 'http://localhost:3010/';
import {Subscriptions} from '@/lib/subscriptions';
const BASE_URL = window.location.origin;

function configureAxios(base_url) {
  const api = axios.create({
    baseURL: base_url,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    data: {}
    // headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  })


  const PendingRequests = []

  // function showLoading() {
  //   Loading.show();
  // }

  const showLoading = _.debounce(() => {
    // Loading.show();
  }, 100, {leading: true, trailing: false})

  const hideLoading = _.debounce(() => {
    // Loading.hide();
  }, 200, {trailing: false})



  api.interceptors.request.use(function (config) {
      if(PendingRequests.length === 0) {
        // Loading.show();
        showLoading();
      }

      config.$$id = _.random(0, 9999999999, false);
      PendingRequests.push(config);
      return config;
      // return response;
    }, function (error) {
      console.log('request error', config);

      return error;
  });

  api.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      _.remove(PendingRequests, response.config);

      // if(response.config.method === "put" || response.config.method === "post") {
      //   if(_.get(response, 'data.data.type')) {
      //     Subscriptions.$$broadcast('notification.success', {
      //       heading: `Success`,
      //       body: `${response.data.data.type} was saved`
      //     });
      //   }
      // }

      if(PendingRequests.length === 0) {
        // Loading.hide();
        hideLoading();
      }
      return response;
    }, function (error) {
      console.log("GOT ERROR");
      let errors = _.get(error.response.data, 'meta.errors', _.get(error.response.data, 'errors', {}));
      if(error.response.data.error) { errors['Error'] = error.response.data.error }
      _.each(errors, (v,k) => {
        console.log(v, k);

        Subscriptions.$$broadcast('notification.error', {
          heading: `Could not save record`,
          body: `${k == 'base' ? '' : k} ${v}`
        });
      })

      _.remove(PendingRequests, error.response.config);
      if(PendingRequests.length === 0) {
        hideLoading();
        // Loading.hide();
      }

      return error;
  });

  return api;
}

let api = configureAxios(BASE_URL);
BaseModel._axios = api
