<template>
  <div class="section-header-1">
    {{title}}
  </div>
  <div class="players mt-2">
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
      <div @click="showTeamPlayerPopout(player)" v-for="player in players" :key="player.id" class="relative flex items-center space-x-3 rounded-lg item-hover-1 hover-transition-1 hover:text-white border border-gray-300 bg-glass-white-500 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
        <div class="flex-shrink-0">
          <img class="h-10 w-10 rounded-full" :src="player.image_url" alt="" />
        </div>
        <div class="min-w-0 flex-1 text-shadow-white-inset">
          <span class="absolute inset-0" aria-hidden="true" />
          <p class="text-sm font-medium text-gray-900 hover-text-secondary">{{ player.name }}</p>
          <p class="truncate text-sm text-gray-500 hover-text-white">{{ playerRole(player) }} / Joined {{ displayDate(player.created_at) }}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';

export default {
  name: 'PlayersList',
  mixins: [TemplateHelpersMixin],
  components: {
  },
  props: ['parent', 'title', 'players', 'team'],
  methods: {
    showTeamPlayerPopout(player) {
      Subscriptions.$$broadcast('team.show.player.show', player);
    },
    playerRole(player) {
      if(this.team.captain_id === player.id) {
        return "Captain"
      } else if(this.team.co_captain_id === player.id) {
        return "Co-Captain"
      } else if(player.team_id !== this.team.id) {
        return "Former Player"
      } else {
        return "Player"
      }
    },
  },
  async created() {
    window.team_show_players = this;
    this.is_loaded = true;
  },
  mounted() {

  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
</style>
