<template>
  <div style="height: 220px;" class="team-info text-white flex flex-row flex-grow p-3 rounded-md justify-between font-agency">
    <div class="image-name p-1">
      <div class="mr-4 mt-12 team-image rounded-full bg-glass-black-100"><img :src="team.image_url" alt="Team 1 Image" class="rounded-full block w-28 h-28 object-cover ring ring-glass-black-100 ring-2 shadow-lg"></div>
      <div class="xrl-text text-center mt-2 -ml-2">XRL.pro</div>
    </div>
    <div class="flex flex-grow flex-col">
      <div class="font-aldrich text-3xl mb-4 uppercase mt-0 -ml-28" style="font-size: 1.7rem">
        <img class="xrl-logo h-6 -ml-2 mr-10 mt-1 w-auto" src="/xrl_text_logo_small.png" alt="XRL Logo" style="float:left;" />
        {{ team.name }}
      </div>
      <div class="player-names grid grid-cols-2 gap-3 text-sm -mt-1">
        <div class="flex col-span-1 bg-glass-white-100 p-1 rounded-lg" v-for="player in team.players" :key="'team1-player-' + player">
          <img :src="player.image_url" alt="Team 1 Image" class="player-image rounded-full block w-8 h-8 object-cover mr-2 mt-0 bg-white">
          <span class="player-name text-lg font-agency">{{ player.name }}</span>
        </div>
      </div>
    </div>

    <div class="min-w-[140px] season-stats -mt-2">
      <div>
        <dl class="mt-0 grid grid-cols-1 gap-1">
          <dt class="truncate text-md font-medium text-gray-700 font-agency uppercase text-right">Season Record</dt>
          <dd class="mt-0 text-3xl font-semibold tracking-tight text-gray-900 text-right -mt-2 mb-1">{{ team.rating.win_count }} - {{ team.rating.loss_count }}</dd>
          <dt class="truncate text-md font-medium text-gray-700 font-agency uppercase text-right -mt-2">Current Rank</dt>
          <dd class="mt-0 text-3xl font-semibold tracking-tight text-gray-900 text-right -mt-2 mb-1">{{ team.rating.rank }}</dd>

          <dt class="truncate text-md font-medium text-gray-700 font-agency uppercase text-right -mt-2">Division: {{humanize(team.rating.division)}}</dt>
          <dd class="mt-0 text-3xl font-semibold tracking-medium text-gray-900 text-right items-right flex flex-row" :class="team.rating.division">
            <div class="flex-grow truncate text-sm tracking-tight font-medium text-gray-700 font-agency uppercase" :class="team.rating.division">&nbsp;</div>
            <div class="division-bg min-w-64 min-h-64 -mt-2" style="height: 64px; width: 64px; background-size: 64px 64px !important;" :class="team.rating.division">&nbsp;</div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';

export default {
  name: 'ToolsCastOverlayTeam',
  props: ['team'],
  mixins: [ TemplateHelpersMixin ],
  async created() {
  },
}
</script>

<style scoped>
.division-bg {
  background-size: 64px 64px !important;
  background-origin: center;
  background-position: left 0px bottom 0px !important;
}

.division-bg {
  background-image: url('/public/images/icons/masters_1.png'); /* Path to your image */
  background-size: 64px 64px;
  background-position: center; /* This centers the image in the cell */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
</style>
