<template>
<a :href="cast.url" target="_blank" class="button-hover-ring-rose rose-hover-1 relative block border border-glass-white-100 bg-rose-200/10 hover:bg-rose-100/40 rounded-md shadow-inner shadow-lg p-2 flex-col hover-transition-3">
  <!-- <div class="glowing-orange-500-to-sky-500 absolute top-0 right-0 bottom-0 left-0 rounded-md opacity-0 group-hover:opacity-100 hover-transition-1 backdrop-saturate-200 bg-opacity-20" style="position:absolute; z-index: -1; "></div> -->
  <div :class="cast.status" class="cast-title flex items-center">
    <div class="game-logo-wrapper mr-2 h-10 w-10 flex-shrink-0">
      <img class="h-full w-full object-cover rounded-full" :src="$root.logoFor(cast.league_slugs[0])" alt="" />
    </div>
    <div class="flex flex-col items-start flex-grow">
      <a :href="cast.url" target="_blank" class="row text-lg font-agency text-zinc-200 flex-grow-0">{{ cast.title }}</a>
      <!-- <div class="flex flex-shrink overflow-hidden"> -->
      <div class="font-aldrich cast-teams text-xs -mt-0.5 text-zinc-300 flex-grow-0">
        {{ cast.details || castTeams() }}
      </div>
    </div>
    <div class="day-month ml-1 flex flex-col items-center mr-0 bg-gradient-to-b from-orange-950/20 to-orange-900/20 rounded-md shadow-sm shadow-inset">
      <div class="w-full month bg-orange-900/20 rounded-md rounded-b-none tracking-wider px-2 pb-0 text-xs text-orange-100 uppercase text-center">{{ formatDate(cast.starts_at)['month'] }}</div>
      <div class="day px-2 text-3xl text-orange-50 uppercase text-center">{{ formatDate(cast.starts_at)['day'] }}</div>

      <div class="tracking-tight text-orange-50 p-1 pt-1 px-2 -mt-1 inline-block rounded-sm text-xs font-agency flex-none" v-if="cast.status =='scheduled' || cast.status == 'live'">
        <div>{{ formatLocalTime(cast.starts_at) }}</div>
      </div>
    </div>
  </div>

  <div :id="cast.id" v-if="cast.status !== 'scheduled'" class="twitch-embed mt-1 h-min-[180px] items-center flex">
    <iframe v-if="cast.youtube_url" :src="cast.youtube_url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="w-full h-full"></iframe>

    <transition name="fadeslow" mode="out">
      <LogoSpinner v-if="!is_loaded && !cast.youtube_url" />
    </transition>
  </div>
</a>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import 'twitch-embed';
import Mixins from '@/components/mixins';
import Spinner from '@/components/ui/spinner';
import LogoSpinner from '@/components/ui/logo_spinner';

export default {
  name: 'Cast',
  props: ['cast'],
  mixins: _.values(Mixins),
  components: {
    Spinner, LogoSpinner
  },
  methods: {
    castTeams() {
      return this.cast.team_names;
    },
    twitchId() {
      return this.cast.url.split("/").pop();
    },
    twitchUrl() {
      let parents = window.location.hostname;
      return `https://player.twitch.tv/?video=${this.twitchId()}&parent=${parents}&autoplay=false`
    },
    initializeEmbed() {
      if(this.cast.youtube_url) { return }

      this.embed = new Twitch.Embed(this.cast.id, {
        height: 180,
        width: "100%",
        parent: window.location.hostname,
        autoplay: false,
        video: this.twitchId()
      })

      this.embed.addEventListener(Twitch.Embed.VIDEO_READY, () => {
        this.is_loaded = true;
      });
    }
  },
  async created() {
    this.$$initialize();
  },
  mounted() {
    if(this.cast.status !== 'scheduled') {
      if(this.$root.twitch_loaded) {
        this.initializeEmbed();
      } else {
        this.$$on("twitch_loaded", this.initializeEmbed);
      }
    }
  },
  setup() {
    return {
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
.scheduled .day-month, .live .day-month {
  @apply min-w-[65px];
}
</style>
