<template>
  <div class="font-aldrich" v-if="is_loaded && match">
    <div class="flex justify-between items-center p-4">
      <router-link :to="teamPathFor(match.home_team)" class="flex w-64 p-4 items-center text-left truncate hover:bg-glass-black-400 hover:text-white rounded-md hover-transition-1">
        <img class="h-10 w-10 rounded-full mr-3" :src="match.home_team.image_url" />
        <span class="font-agency text-lg">{{ match.home_team.name }}</span>
      </router-link>
      <div class="mx-2">
        <span>VS</span>
      </div>
      <router-link :to="teamPathFor(match.away_team)" class="flex items-center p-4 justify-end w-64  truncate hover:bg-glass-black-400 hover:text-white rounded-md hover-transition-1">
        <span class="font-agency text-lg">{{ match.away_team.name }}</span>
        <img class="h-10 w-10 rounded-full ml-3" :src="match.away_team.image_url" />
      </router-link>
    </div>

    <div class="flex p-4">
      <div class="flex justify-start px-3 py-2 font-semibold text-glass-black-800 font-agency text-base" v-if="match.scheduled_at">
        <CalendarDaysIcon class="h-5 w-5" />
        <span class="uppercase mr-1"></span> <span>{{formatLocalDateTime(match.scheduled_at)}}</span>
      </div>

      <div class="flex-grow"></div>

      <div class="flex justify-end">
        <DateTimePicker :buttonClass="'inline-flex items-center menu-button-glass-black-4 text-md uppercase font-semibold rounded-md px-4 py-2 font-agency ml-4'"
                        v-if="!match.relationPresent('result')"
                        :mode="'current_week'"
                        :onConfirm="scheduleMatch" />
        <button v-if="showReportResults()" @click="$parent.addNew(leagueFor(team), match)" class="inline-flex items-center menu-button-glass-black-4 text-md uppercase font-semibold rounded-md px-4 py-2 font-agency ml-4">
          <CalculatorIcon class="h-5 w-5 mr-2" />
          <span v-if="match.relationPresent('result')">
            View / Confirm Results
          </span>

          <span v-else>
            Report Match Results
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import BaseMixin from '@/components/mixins/base_mixin';
import TemplateHelpers from '@/components/mixins/template_helpers';
import { CalculatorIcon, CalendarDaysIcon } from '@heroicons/vue/20/solid';
import DateTimePicker from '@/components/ui/date_time_picker';

export default {
  name: 'MyMatchesMatch',
  props: ['match', 'team', 'save'],
  mixins: [ BaseMixin, TemplateHelpers ],
  components: {
    CalculatorIcon, CalendarDaysIcon, DateTimePicker
  },
  methods: {
    showScheduleMatch() {
      return !this.match.relationPresent('result');
    },
    async scheduleMatch(selectedTime) {
      this.match.scheduled_at = selectedTime;
      this.match.status = 'scheduled';
      await this.match.save();
      this.showSuccessMessage(`Match has been scheduled for ${this.formatLocalDateTime(selectedTime)}`);
      this.$$reload();
    },
    showReportResults() {
      return `${this.match.scheduled_at}`.length > 0 || this.match.relationPresent('result');
    },
    teamPathFor(team) {
      let league = this.leagueFor(team);
      return `/leagues/${league.subdomain}/team/${team.id}/details`;
    },
    leagueFor(team) {
      return this.$root.findLeagueById(team.league_id);
    }
  },
  async created() {
    this.is_loaded = true;
  },
  setup() {
    return {
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
</style>
