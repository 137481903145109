export default {
  "Types": {
    "source": "Kore::Types",
    "name": "types",
    "type": "struct",
    "fields": {
    },
    "Player": {
      "source": "Kore::Types::Player",
      "name": "player",
      "type": "struct",
      "fields": {
        "status": {
          "name": "status",
          "source": "Kore::Types::Player::Fields::Status",
          "type": "enum",
          "choices": [
            {
              "source": "Kore::Types::Player::Fields::Status::Inactive",
              "name": "inactive",
              "type": "enum_value",
              "integer_value": 1,
              "attributes": {
              }
            },
            {
              "source": "Kore::Types::Player::Fields::Status::LookingForTeam",
              "name": "looking_for_team",
              "type": "enum_value",
              "integer_value": 2,
              "attributes": {
              }
            }
          ],
          "values": [
            "inactive",
            "looking_for_team"
          ],
          "default": "inactive"
        }
      }
    },
    "User": {
      "source": "Kore::Types::User",
      "name": "user",
      "type": "struct",
      "fields": {
        "country": {
          "name": "country",
          "source": "Kore::Types::User::Fields::Country",
          "type": "enum",
          "choices": [
            {
              "source": "Kore::Types::User::Fields::Country::Aw",
              "name": "aw",
              "type": "enum_value",
              "integer_value": 0,
              "attributes": {
                "flag": "🇦🇼",
                "display_name": "Aruba"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Af",
              "name": "af",
              "type": "enum_value",
              "integer_value": 1,
              "attributes": {
                "flag": "🇦🇫",
                "display_name": "Afghanistan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ao",
              "name": "ao",
              "type": "enum_value",
              "integer_value": 2,
              "attributes": {
                "flag": "🇦🇴",
                "display_name": "Angola"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ai",
              "name": "ai",
              "type": "enum_value",
              "integer_value": 3,
              "attributes": {
                "flag": "🇦🇮",
                "display_name": "Anguilla"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ax",
              "name": "ax",
              "type": "enum_value",
              "integer_value": 4,
              "attributes": {
                "flag": "🇦🇽",
                "display_name": "Åland Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Al",
              "name": "al",
              "type": "enum_value",
              "integer_value": 5,
              "attributes": {
                "flag": "🇦🇱",
                "display_name": "Albania"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ad",
              "name": "ad",
              "type": "enum_value",
              "integer_value": 6,
              "attributes": {
                "flag": "🇦🇩",
                "display_name": "Andorra"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ae",
              "name": "ae",
              "type": "enum_value",
              "integer_value": 7,
              "attributes": {
                "flag": "🇦🇪",
                "display_name": "United Arab Emirates"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ar",
              "name": "ar",
              "type": "enum_value",
              "integer_value": 8,
              "attributes": {
                "flag": "🇦🇷",
                "display_name": "Argentina"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Am",
              "name": "am",
              "type": "enum_value",
              "integer_value": 9,
              "attributes": {
                "flag": "🇦🇲",
                "display_name": "Armenia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::As",
              "name": "as",
              "type": "enum_value",
              "integer_value": 10,
              "attributes": {
                "flag": "🇦🇸",
                "display_name": "American Samoa"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Aq",
              "name": "aq",
              "type": "enum_value",
              "integer_value": 11,
              "attributes": {
                "flag": "🇦🇶",
                "display_name": "Antarctica"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tf",
              "name": "tf",
              "type": "enum_value",
              "integer_value": 12,
              "attributes": {
                "flag": "🇹🇫",
                "display_name": "French Southern Territories"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ag",
              "name": "ag",
              "type": "enum_value",
              "integer_value": 13,
              "attributes": {
                "flag": "🇦🇬",
                "display_name": "Antigua and Barbuda"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Au",
              "name": "au",
              "type": "enum_value",
              "integer_value": 14,
              "attributes": {
                "flag": "🇦🇺",
                "display_name": "Australia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::At",
              "name": "at",
              "type": "enum_value",
              "integer_value": 15,
              "attributes": {
                "flag": "🇦🇹",
                "display_name": "Austria"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Az",
              "name": "az",
              "type": "enum_value",
              "integer_value": 16,
              "attributes": {
                "flag": "🇦🇿",
                "display_name": "Azerbaijan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bi",
              "name": "bi",
              "type": "enum_value",
              "integer_value": 17,
              "attributes": {
                "flag": "🇧🇮",
                "display_name": "Burundi"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Be",
              "name": "be",
              "type": "enum_value",
              "integer_value": 18,
              "attributes": {
                "flag": "🇧🇪",
                "display_name": "Belgium"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bj",
              "name": "bj",
              "type": "enum_value",
              "integer_value": 19,
              "attributes": {
                "flag": "🇧🇯",
                "display_name": "Benin"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bq",
              "name": "bq",
              "type": "enum_value",
              "integer_value": 20,
              "attributes": {
                "flag": "🇧🇶",
                "display_name": "Bonaire, Sint Eustatius and Saba"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bf",
              "name": "bf",
              "type": "enum_value",
              "integer_value": 21,
              "attributes": {
                "flag": "🇧🇫",
                "display_name": "Burkina Faso"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bd",
              "name": "bd",
              "type": "enum_value",
              "integer_value": 22,
              "attributes": {
                "flag": "🇧🇩",
                "display_name": "Bangladesh"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bg",
              "name": "bg",
              "type": "enum_value",
              "integer_value": 23,
              "attributes": {
                "flag": "🇧🇬",
                "display_name": "Bulgaria"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bh",
              "name": "bh",
              "type": "enum_value",
              "integer_value": 24,
              "attributes": {
                "flag": "🇧🇭",
                "display_name": "Bahrain"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bs",
              "name": "bs",
              "type": "enum_value",
              "integer_value": 25,
              "attributes": {
                "flag": "🇧🇸",
                "display_name": "Bahamas"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ba",
              "name": "ba",
              "type": "enum_value",
              "integer_value": 26,
              "attributes": {
                "flag": "🇧🇦",
                "display_name": "Bosnia and Herzegovina"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bl",
              "name": "bl",
              "type": "enum_value",
              "integer_value": 27,
              "attributes": {
                "flag": "🇧🇱",
                "display_name": "Saint Barthélemy"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::By",
              "name": "by",
              "type": "enum_value",
              "integer_value": 28,
              "attributes": {
                "flag": "🇧🇾",
                "display_name": "Belarus"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bz",
              "name": "bz",
              "type": "enum_value",
              "integer_value": 29,
              "attributes": {
                "flag": "🇧🇿",
                "display_name": "Belize"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bm",
              "name": "bm",
              "type": "enum_value",
              "integer_value": 30,
              "attributes": {
                "flag": "🇧🇲",
                "display_name": "Bermuda"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bo",
              "name": "bo",
              "type": "enum_value",
              "integer_value": 31,
              "attributes": {
                "flag": "🇧🇴",
                "display_name": "Bolivia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Br",
              "name": "br",
              "type": "enum_value",
              "integer_value": 32,
              "attributes": {
                "flag": "🇧🇷",
                "display_name": "Brazil"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bb",
              "name": "bb",
              "type": "enum_value",
              "integer_value": 33,
              "attributes": {
                "flag": "🇧🇧",
                "display_name": "Barbados"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bn",
              "name": "bn",
              "type": "enum_value",
              "integer_value": 34,
              "attributes": {
                "flag": "🇧🇳",
                "display_name": "Brunei Darussalam"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bt",
              "name": "bt",
              "type": "enum_value",
              "integer_value": 35,
              "attributes": {
                "flag": "🇧🇹",
                "display_name": "Bhutan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bv",
              "name": "bv",
              "type": "enum_value",
              "integer_value": 36,
              "attributes": {
                "flag": "🇧🇻",
                "display_name": "Bouvet Island"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Bw",
              "name": "bw",
              "type": "enum_value",
              "integer_value": 37,
              "attributes": {
                "flag": "🇧🇼",
                "display_name": "Botswana"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cf",
              "name": "cf",
              "type": "enum_value",
              "integer_value": 38,
              "attributes": {
                "flag": "🇨🇫",
                "display_name": "Central African Republic"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ca",
              "name": "ca",
              "type": "enum_value",
              "integer_value": 39,
              "attributes": {
                "flag": "🇨🇦",
                "display_name": "Canada"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cc",
              "name": "cc",
              "type": "enum_value",
              "integer_value": 40,
              "attributes": {
                "flag": "🇨🇨",
                "display_name": "Cocos (Keeling) Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ch",
              "name": "ch",
              "type": "enum_value",
              "integer_value": 41,
              "attributes": {
                "flag": "🇨🇭",
                "display_name": "Switzerland"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cl",
              "name": "cl",
              "type": "enum_value",
              "integer_value": 42,
              "attributes": {
                "flag": "🇨🇱",
                "display_name": "Chile"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cn",
              "name": "cn",
              "type": "enum_value",
              "integer_value": 43,
              "attributes": {
                "flag": "🇨🇳",
                "display_name": "China"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ci",
              "name": "ci",
              "type": "enum_value",
              "integer_value": 44,
              "attributes": {
                "flag": "🇨🇮",
                "display_name": "Côte d'Ivoire"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cm",
              "name": "cm",
              "type": "enum_value",
              "integer_value": 45,
              "attributes": {
                "flag": "🇨🇲",
                "display_name": "Cameroon"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cd",
              "name": "cd",
              "type": "enum_value",
              "integer_value": 46,
              "attributes": {
                "flag": "🇨🇩",
                "display_name": "Congo, The Democratic Republic of the"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cg",
              "name": "cg",
              "type": "enum_value",
              "integer_value": 47,
              "attributes": {
                "flag": "🇨🇬",
                "display_name": "Congo"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ck",
              "name": "ck",
              "type": "enum_value",
              "integer_value": 48,
              "attributes": {
                "flag": "🇨🇰",
                "display_name": "Cook Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Co",
              "name": "co",
              "type": "enum_value",
              "integer_value": 49,
              "attributes": {
                "flag": "🇨🇴",
                "display_name": "Colombia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Km",
              "name": "km",
              "type": "enum_value",
              "integer_value": 50,
              "attributes": {
                "flag": "🇰🇲",
                "display_name": "Comoros"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cv",
              "name": "cv",
              "type": "enum_value",
              "integer_value": 51,
              "attributes": {
                "flag": "🇨🇻",
                "display_name": "Cabo Verde"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cr",
              "name": "cr",
              "type": "enum_value",
              "integer_value": 52,
              "attributes": {
                "flag": "🇨🇷",
                "display_name": "Costa Rica"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cu",
              "name": "cu",
              "type": "enum_value",
              "integer_value": 53,
              "attributes": {
                "flag": "🇨🇺",
                "display_name": "Cuba"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cw",
              "name": "cw",
              "type": "enum_value",
              "integer_value": 54,
              "attributes": {
                "flag": "🇨🇼",
                "display_name": "Curaçao"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cx",
              "name": "cx",
              "type": "enum_value",
              "integer_value": 55,
              "attributes": {
                "flag": "🇨🇽",
                "display_name": "Christmas Island"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ky",
              "name": "ky",
              "type": "enum_value",
              "integer_value": 56,
              "attributes": {
                "flag": "🇰🇾",
                "display_name": "Cayman Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cy",
              "name": "cy",
              "type": "enum_value",
              "integer_value": 57,
              "attributes": {
                "flag": "🇨🇾",
                "display_name": "Cyprus"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Cz",
              "name": "cz",
              "type": "enum_value",
              "integer_value": 58,
              "attributes": {
                "flag": "🇨🇿",
                "display_name": "Czechia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::De",
              "name": "de",
              "type": "enum_value",
              "integer_value": 59,
              "attributes": {
                "flag": "🇩🇪",
                "display_name": "Germany"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Dj",
              "name": "dj",
              "type": "enum_value",
              "integer_value": 60,
              "attributes": {
                "flag": "🇩🇯",
                "display_name": "Djibouti"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Dm",
              "name": "dm",
              "type": "enum_value",
              "integer_value": 61,
              "attributes": {
                "flag": "🇩🇲",
                "display_name": "Dominica"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Dk",
              "name": "dk",
              "type": "enum_value",
              "integer_value": 62,
              "attributes": {
                "flag": "🇩🇰",
                "display_name": "Denmark"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Do",
              "name": "do",
              "type": "enum_value",
              "integer_value": 63,
              "attributes": {
                "flag": "🇩🇴",
                "display_name": "Dominican Republic"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Dz",
              "name": "dz",
              "type": "enum_value",
              "integer_value": 64,
              "attributes": {
                "flag": "🇩🇿",
                "display_name": "Algeria"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ec",
              "name": "ec",
              "type": "enum_value",
              "integer_value": 65,
              "attributes": {
                "flag": "🇪🇨",
                "display_name": "Ecuador"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Eg",
              "name": "eg",
              "type": "enum_value",
              "integer_value": 66,
              "attributes": {
                "flag": "🇪🇬",
                "display_name": "Egypt"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Er",
              "name": "er",
              "type": "enum_value",
              "integer_value": 67,
              "attributes": {
                "flag": "🇪🇷",
                "display_name": "Eritrea"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Eh",
              "name": "eh",
              "type": "enum_value",
              "integer_value": 68,
              "attributes": {
                "flag": "🇪🇭",
                "display_name": "Western Sahara"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Es",
              "name": "es",
              "type": "enum_value",
              "integer_value": 69,
              "attributes": {
                "flag": "🇪🇸",
                "display_name": "Spain"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ee",
              "name": "ee",
              "type": "enum_value",
              "integer_value": 70,
              "attributes": {
                "flag": "🇪🇪",
                "display_name": "Estonia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Et",
              "name": "et",
              "type": "enum_value",
              "integer_value": 71,
              "attributes": {
                "flag": "🇪🇹",
                "display_name": "Ethiopia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Fi",
              "name": "fi",
              "type": "enum_value",
              "integer_value": 72,
              "attributes": {
                "flag": "🇫🇮",
                "display_name": "Finland"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Fj",
              "name": "fj",
              "type": "enum_value",
              "integer_value": 73,
              "attributes": {
                "flag": "🇫🇯",
                "display_name": "Fiji"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Fk",
              "name": "fk",
              "type": "enum_value",
              "integer_value": 74,
              "attributes": {
                "flag": "🇫🇰",
                "display_name": "Falkland Islands (Malvinas)"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Fr",
              "name": "fr",
              "type": "enum_value",
              "integer_value": 75,
              "attributes": {
                "flag": "🇫🇷",
                "display_name": "France"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Fo",
              "name": "fo",
              "type": "enum_value",
              "integer_value": 76,
              "attributes": {
                "flag": "🇫🇴",
                "display_name": "Faroe Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Fm",
              "name": "fm",
              "type": "enum_value",
              "integer_value": 77,
              "attributes": {
                "flag": "🇫🇲",
                "display_name": "Micronesia, Federated States of"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ga",
              "name": "ga",
              "type": "enum_value",
              "integer_value": 78,
              "attributes": {
                "flag": "🇬🇦",
                "display_name": "Gabon"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gb",
              "name": "gb",
              "type": "enum_value",
              "integer_value": 79,
              "attributes": {
                "flag": "🇬🇧",
                "display_name": "United Kingdom"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ge",
              "name": "ge",
              "type": "enum_value",
              "integer_value": 80,
              "attributes": {
                "flag": "🇬🇪",
                "display_name": "Georgia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gg",
              "name": "gg",
              "type": "enum_value",
              "integer_value": 81,
              "attributes": {
                "flag": "🇬🇬",
                "display_name": "Guernsey"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gh",
              "name": "gh",
              "type": "enum_value",
              "integer_value": 82,
              "attributes": {
                "flag": "🇬🇭",
                "display_name": "Ghana"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gi",
              "name": "gi",
              "type": "enum_value",
              "integer_value": 83,
              "attributes": {
                "flag": "🇬🇮",
                "display_name": "Gibraltar"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gn",
              "name": "gn",
              "type": "enum_value",
              "integer_value": 84,
              "attributes": {
                "flag": "🇬🇳",
                "display_name": "Guinea"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gp",
              "name": "gp",
              "type": "enum_value",
              "integer_value": 85,
              "attributes": {
                "flag": "🇬🇵",
                "display_name": "Guadeloupe"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gm",
              "name": "gm",
              "type": "enum_value",
              "integer_value": 86,
              "attributes": {
                "flag": "🇬🇲",
                "display_name": "Gambia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gw",
              "name": "gw",
              "type": "enum_value",
              "integer_value": 87,
              "attributes": {
                "flag": "🇬🇼",
                "display_name": "Guinea-Bissau"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gq",
              "name": "gq",
              "type": "enum_value",
              "integer_value": 88,
              "attributes": {
                "flag": "🇬🇶",
                "display_name": "Equatorial Guinea"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gr",
              "name": "gr",
              "type": "enum_value",
              "integer_value": 89,
              "attributes": {
                "flag": "🇬🇷",
                "display_name": "Greece"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gd",
              "name": "gd",
              "type": "enum_value",
              "integer_value": 90,
              "attributes": {
                "flag": "🇬🇩",
                "display_name": "Grenada"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gl",
              "name": "gl",
              "type": "enum_value",
              "integer_value": 91,
              "attributes": {
                "flag": "🇬🇱",
                "display_name": "Greenland"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gt",
              "name": "gt",
              "type": "enum_value",
              "integer_value": 92,
              "attributes": {
                "flag": "🇬🇹",
                "display_name": "Guatemala"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gf",
              "name": "gf",
              "type": "enum_value",
              "integer_value": 93,
              "attributes": {
                "flag": "🇬🇫",
                "display_name": "French Guiana"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gu",
              "name": "gu",
              "type": "enum_value",
              "integer_value": 94,
              "attributes": {
                "flag": "🇬🇺",
                "display_name": "Guam"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gy",
              "name": "gy",
              "type": "enum_value",
              "integer_value": 95,
              "attributes": {
                "flag": "🇬🇾",
                "display_name": "Guyana"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Hk",
              "name": "hk",
              "type": "enum_value",
              "integer_value": 96,
              "attributes": {
                "flag": "🇭🇰",
                "display_name": "Hong Kong"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Hm",
              "name": "hm",
              "type": "enum_value",
              "integer_value": 97,
              "attributes": {
                "flag": "🇭🇲",
                "display_name": "Heard Island and McDonald Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Hn",
              "name": "hn",
              "type": "enum_value",
              "integer_value": 98,
              "attributes": {
                "flag": "🇭🇳",
                "display_name": "Honduras"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Hr",
              "name": "hr",
              "type": "enum_value",
              "integer_value": 99,
              "attributes": {
                "flag": "🇭🇷",
                "display_name": "Croatia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ht",
              "name": "ht",
              "type": "enum_value",
              "integer_value": 100,
              "attributes": {
                "flag": "🇭🇹",
                "display_name": "Haiti"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Hu",
              "name": "hu",
              "type": "enum_value",
              "integer_value": 101,
              "attributes": {
                "flag": "🇭🇺",
                "display_name": "Hungary"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Id",
              "name": "id",
              "type": "enum_value",
              "integer_value": 102,
              "attributes": {
                "flag": "🇮🇩",
                "display_name": "Indonesia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Im",
              "name": "im",
              "type": "enum_value",
              "integer_value": 103,
              "attributes": {
                "flag": "🇮🇲",
                "display_name": "Isle of Man"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::In",
              "name": "in",
              "type": "enum_value",
              "integer_value": 104,
              "attributes": {
                "flag": "🇮🇳",
                "display_name": "India"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Io",
              "name": "io",
              "type": "enum_value",
              "integer_value": 105,
              "attributes": {
                "flag": "🇮🇴",
                "display_name": "British Indian Ocean Territory"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ie",
              "name": "ie",
              "type": "enum_value",
              "integer_value": 106,
              "attributes": {
                "flag": "🇮🇪",
                "display_name": "Ireland"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ir",
              "name": "ir",
              "type": "enum_value",
              "integer_value": 107,
              "attributes": {
                "flag": "🇮🇷",
                "display_name": "Iran, Islamic Republic of"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Iq",
              "name": "iq",
              "type": "enum_value",
              "integer_value": 108,
              "attributes": {
                "flag": "🇮🇶",
                "display_name": "Iraq"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Is",
              "name": "is",
              "type": "enum_value",
              "integer_value": 109,
              "attributes": {
                "flag": "🇮🇸",
                "display_name": "Iceland"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Il",
              "name": "il",
              "type": "enum_value",
              "integer_value": 110,
              "attributes": {
                "flag": "🇮🇱",
                "display_name": "Israel"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::It",
              "name": "it",
              "type": "enum_value",
              "integer_value": 111,
              "attributes": {
                "flag": "🇮🇹",
                "display_name": "Italy"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Jm",
              "name": "jm",
              "type": "enum_value",
              "integer_value": 112,
              "attributes": {
                "flag": "🇯🇲",
                "display_name": "Jamaica"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Je",
              "name": "je",
              "type": "enum_value",
              "integer_value": 113,
              "attributes": {
                "flag": "🇯🇪",
                "display_name": "Jersey"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Jo",
              "name": "jo",
              "type": "enum_value",
              "integer_value": 114,
              "attributes": {
                "flag": "🇯🇴",
                "display_name": "Jordan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Jp",
              "name": "jp",
              "type": "enum_value",
              "integer_value": 115,
              "attributes": {
                "flag": "🇯🇵",
                "display_name": "Japan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Kz",
              "name": "kz",
              "type": "enum_value",
              "integer_value": 116,
              "attributes": {
                "flag": "🇰🇿",
                "display_name": "Kazakhstan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ke",
              "name": "ke",
              "type": "enum_value",
              "integer_value": 117,
              "attributes": {
                "flag": "🇰🇪",
                "display_name": "Kenya"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Kg",
              "name": "kg",
              "type": "enum_value",
              "integer_value": 118,
              "attributes": {
                "flag": "🇰🇬",
                "display_name": "Kyrgyzstan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Kh",
              "name": "kh",
              "type": "enum_value",
              "integer_value": 119,
              "attributes": {
                "flag": "🇰🇭",
                "display_name": "Cambodia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ki",
              "name": "ki",
              "type": "enum_value",
              "integer_value": 120,
              "attributes": {
                "flag": "🇰🇮",
                "display_name": "Kiribati"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Kn",
              "name": "kn",
              "type": "enum_value",
              "integer_value": 121,
              "attributes": {
                "flag": "🇰🇳",
                "display_name": "Saint Kitts and Nevis"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Kr",
              "name": "kr",
              "type": "enum_value",
              "integer_value": 122,
              "attributes": {
                "flag": "🇰🇷",
                "display_name": "South Korea"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Kw",
              "name": "kw",
              "type": "enum_value",
              "integer_value": 123,
              "attributes": {
                "flag": "🇰🇼",
                "display_name": "Kuwait"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::La",
              "name": "la",
              "type": "enum_value",
              "integer_value": 124,
              "attributes": {
                "flag": "🇱🇦",
                "display_name": "Lao People's Democratic Republic"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Lb",
              "name": "lb",
              "type": "enum_value",
              "integer_value": 125,
              "attributes": {
                "flag": "🇱🇧",
                "display_name": "Lebanon"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Lr",
              "name": "lr",
              "type": "enum_value",
              "integer_value": 126,
              "attributes": {
                "flag": "🇱🇷",
                "display_name": "Liberia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ly",
              "name": "ly",
              "type": "enum_value",
              "integer_value": 127,
              "attributes": {
                "flag": "🇱🇾",
                "display_name": "Libya"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Lc",
              "name": "lc",
              "type": "enum_value",
              "integer_value": 128,
              "attributes": {
                "flag": "🇱🇨",
                "display_name": "Saint Lucia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Li",
              "name": "li",
              "type": "enum_value",
              "integer_value": 129,
              "attributes": {
                "flag": "🇱🇮",
                "display_name": "Liechtenstein"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Lk",
              "name": "lk",
              "type": "enum_value",
              "integer_value": 130,
              "attributes": {
                "flag": "🇱🇰",
                "display_name": "Sri Lanka"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ls",
              "name": "ls",
              "type": "enum_value",
              "integer_value": 131,
              "attributes": {
                "flag": "🇱🇸",
                "display_name": "Lesotho"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Lt",
              "name": "lt",
              "type": "enum_value",
              "integer_value": 132,
              "attributes": {
                "flag": "🇱🇹",
                "display_name": "Lithuania"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Lu",
              "name": "lu",
              "type": "enum_value",
              "integer_value": 133,
              "attributes": {
                "flag": "🇱🇺",
                "display_name": "Luxembourg"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Lv",
              "name": "lv",
              "type": "enum_value",
              "integer_value": 134,
              "attributes": {
                "flag": "🇱🇻",
                "display_name": "Latvia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mo",
              "name": "mo",
              "type": "enum_value",
              "integer_value": 135,
              "attributes": {
                "flag": "🇲🇴",
                "display_name": "Macao"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mf",
              "name": "mf",
              "type": "enum_value",
              "integer_value": 136,
              "attributes": {
                "flag": "🇲🇫",
                "display_name": "Saint Martin (French part)"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ma",
              "name": "ma",
              "type": "enum_value",
              "integer_value": 137,
              "attributes": {
                "flag": "🇲🇦",
                "display_name": "Morocco"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mc",
              "name": "mc",
              "type": "enum_value",
              "integer_value": 138,
              "attributes": {
                "flag": "🇲🇨",
                "display_name": "Monaco"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Md",
              "name": "md",
              "type": "enum_value",
              "integer_value": 139,
              "attributes": {
                "flag": "🇲🇩",
                "display_name": "Moldova"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mg",
              "name": "mg",
              "type": "enum_value",
              "integer_value": 140,
              "attributes": {
                "flag": "🇲🇬",
                "display_name": "Madagascar"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mv",
              "name": "mv",
              "type": "enum_value",
              "integer_value": 141,
              "attributes": {
                "flag": "🇲🇻",
                "display_name": "Maldives"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mx",
              "name": "mx",
              "type": "enum_value",
              "integer_value": 142,
              "attributes": {
                "flag": "🇲🇽",
                "display_name": "Mexico"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mh",
              "name": "mh",
              "type": "enum_value",
              "integer_value": 143,
              "attributes": {
                "flag": "🇲🇭",
                "display_name": "Marshall Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mk",
              "name": "mk",
              "type": "enum_value",
              "integer_value": 144,
              "attributes": {
                "flag": "🇲🇰",
                "display_name": "North Macedonia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ml",
              "name": "ml",
              "type": "enum_value",
              "integer_value": 145,
              "attributes": {
                "flag": "🇲🇱",
                "display_name": "Mali"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mt",
              "name": "mt",
              "type": "enum_value",
              "integer_value": 146,
              "attributes": {
                "flag": "🇲🇹",
                "display_name": "Malta"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mm",
              "name": "mm",
              "type": "enum_value",
              "integer_value": 147,
              "attributes": {
                "flag": "🇲🇲",
                "display_name": "Myanmar"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Me",
              "name": "me",
              "type": "enum_value",
              "integer_value": 148,
              "attributes": {
                "flag": "🇲🇪",
                "display_name": "Montenegro"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mn",
              "name": "mn",
              "type": "enum_value",
              "integer_value": 149,
              "attributes": {
                "flag": "🇲🇳",
                "display_name": "Mongolia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mp",
              "name": "mp",
              "type": "enum_value",
              "integer_value": 150,
              "attributes": {
                "flag": "🇲🇵",
                "display_name": "Northern Mariana Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mz",
              "name": "mz",
              "type": "enum_value",
              "integer_value": 151,
              "attributes": {
                "flag": "🇲🇿",
                "display_name": "Mozambique"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mr",
              "name": "mr",
              "type": "enum_value",
              "integer_value": 152,
              "attributes": {
                "flag": "🇲🇷",
                "display_name": "Mauritania"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ms",
              "name": "ms",
              "type": "enum_value",
              "integer_value": 153,
              "attributes": {
                "flag": "🇲🇸",
                "display_name": "Montserrat"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mq",
              "name": "mq",
              "type": "enum_value",
              "integer_value": 154,
              "attributes": {
                "flag": "🇲🇶",
                "display_name": "Martinique"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mu",
              "name": "mu",
              "type": "enum_value",
              "integer_value": 155,
              "attributes": {
                "flag": "🇲🇺",
                "display_name": "Mauritius"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Mw",
              "name": "mw",
              "type": "enum_value",
              "integer_value": 156,
              "attributes": {
                "flag": "🇲🇼",
                "display_name": "Malawi"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::My",
              "name": "my",
              "type": "enum_value",
              "integer_value": 157,
              "attributes": {
                "flag": "🇲🇾",
                "display_name": "Malaysia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Yt",
              "name": "yt",
              "type": "enum_value",
              "integer_value": 158,
              "attributes": {
                "flag": "🇾🇹",
                "display_name": "Mayotte"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Na",
              "name": "na",
              "type": "enum_value",
              "integer_value": 159,
              "attributes": {
                "flag": "🇳🇦",
                "display_name": "Namibia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Nc",
              "name": "nc",
              "type": "enum_value",
              "integer_value": 160,
              "attributes": {
                "flag": "🇳🇨",
                "display_name": "New Caledonia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ne",
              "name": "ne",
              "type": "enum_value",
              "integer_value": 161,
              "attributes": {
                "flag": "🇳🇪",
                "display_name": "Niger"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Nf",
              "name": "nf",
              "type": "enum_value",
              "integer_value": 162,
              "attributes": {
                "flag": "🇳🇫",
                "display_name": "Norfolk Island"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ng",
              "name": "ng",
              "type": "enum_value",
              "integer_value": 163,
              "attributes": {
                "flag": "🇳🇬",
                "display_name": "Nigeria"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ni",
              "name": "ni",
              "type": "enum_value",
              "integer_value": 164,
              "attributes": {
                "flag": "🇳🇮",
                "display_name": "Nicaragua"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Nu",
              "name": "nu",
              "type": "enum_value",
              "integer_value": 165,
              "attributes": {
                "flag": "🇳🇺",
                "display_name": "Niue"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Nl",
              "name": "nl",
              "type": "enum_value",
              "integer_value": 166,
              "attributes": {
                "flag": "🇳🇱",
                "display_name": "Netherlands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::No",
              "name": "no",
              "type": "enum_value",
              "integer_value": 167,
              "attributes": {
                "flag": "🇳🇴",
                "display_name": "Norway"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Np",
              "name": "np",
              "type": "enum_value",
              "integer_value": 168,
              "attributes": {
                "flag": "🇳🇵",
                "display_name": "Nepal"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Nr",
              "name": "nr",
              "type": "enum_value",
              "integer_value": 169,
              "attributes": {
                "flag": "🇳🇷",
                "display_name": "Nauru"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Nz",
              "name": "nz",
              "type": "enum_value",
              "integer_value": 170,
              "attributes": {
                "flag": "🇳🇿",
                "display_name": "New Zealand"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Om",
              "name": "om",
              "type": "enum_value",
              "integer_value": 171,
              "attributes": {
                "flag": "🇴🇲",
                "display_name": "Oman"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pk",
              "name": "pk",
              "type": "enum_value",
              "integer_value": 172,
              "attributes": {
                "flag": "🇵🇰",
                "display_name": "Pakistan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pa",
              "name": "pa",
              "type": "enum_value",
              "integer_value": 173,
              "attributes": {
                "flag": "🇵🇦",
                "display_name": "Panama"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pn",
              "name": "pn",
              "type": "enum_value",
              "integer_value": 174,
              "attributes": {
                "flag": "🇵🇳",
                "display_name": "Pitcairn"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pe",
              "name": "pe",
              "type": "enum_value",
              "integer_value": 175,
              "attributes": {
                "flag": "🇵🇪",
                "display_name": "Peru"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ph",
              "name": "ph",
              "type": "enum_value",
              "integer_value": 176,
              "attributes": {
                "flag": "🇵🇭",
                "display_name": "Philippines"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pw",
              "name": "pw",
              "type": "enum_value",
              "integer_value": 177,
              "attributes": {
                "flag": "🇵🇼",
                "display_name": "Palau"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pg",
              "name": "pg",
              "type": "enum_value",
              "integer_value": 178,
              "attributes": {
                "flag": "🇵🇬",
                "display_name": "Papua New Guinea"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pl",
              "name": "pl",
              "type": "enum_value",
              "integer_value": 179,
              "attributes": {
                "flag": "🇵🇱",
                "display_name": "Poland"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pr",
              "name": "pr",
              "type": "enum_value",
              "integer_value": 180,
              "attributes": {
                "flag": "🇵🇷",
                "display_name": "Puerto Rico"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Kp",
              "name": "kp",
              "type": "enum_value",
              "integer_value": 181,
              "attributes": {
                "flag": "🇰🇵",
                "display_name": "North Korea"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pt",
              "name": "pt",
              "type": "enum_value",
              "integer_value": 182,
              "attributes": {
                "flag": "🇵🇹",
                "display_name": "Portugal"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Py",
              "name": "py",
              "type": "enum_value",
              "integer_value": 183,
              "attributes": {
                "flag": "🇵🇾",
                "display_name": "Paraguay"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ps",
              "name": "ps",
              "type": "enum_value",
              "integer_value": 184,
              "attributes": {
                "flag": "🇵🇸",
                "display_name": "Palestine, State of"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pf",
              "name": "pf",
              "type": "enum_value",
              "integer_value": 185,
              "attributes": {
                "flag": "🇵🇫",
                "display_name": "French Polynesia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Qa",
              "name": "qa",
              "type": "enum_value",
              "integer_value": 186,
              "attributes": {
                "flag": "🇶🇦",
                "display_name": "Qatar"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Re",
              "name": "re",
              "type": "enum_value",
              "integer_value": 187,
              "attributes": {
                "flag": "🇷🇪",
                "display_name": "Réunion"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ro",
              "name": "ro",
              "type": "enum_value",
              "integer_value": 188,
              "attributes": {
                "flag": "🇷🇴",
                "display_name": "Romania"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ru",
              "name": "ru",
              "type": "enum_value",
              "integer_value": 189,
              "attributes": {
                "flag": "🇷🇺",
                "display_name": "Russian Federation"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Rw",
              "name": "rw",
              "type": "enum_value",
              "integer_value": 190,
              "attributes": {
                "flag": "🇷🇼",
                "display_name": "Rwanda"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sa",
              "name": "sa",
              "type": "enum_value",
              "integer_value": 191,
              "attributes": {
                "flag": "🇸🇦",
                "display_name": "Saudi Arabia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sd",
              "name": "sd",
              "type": "enum_value",
              "integer_value": 192,
              "attributes": {
                "flag": "🇸🇩",
                "display_name": "Sudan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sn",
              "name": "sn",
              "type": "enum_value",
              "integer_value": 193,
              "attributes": {
                "flag": "🇸🇳",
                "display_name": "Senegal"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sg",
              "name": "sg",
              "type": "enum_value",
              "integer_value": 194,
              "attributes": {
                "flag": "🇸🇬",
                "display_name": "Singapore"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Gs",
              "name": "gs",
              "type": "enum_value",
              "integer_value": 195,
              "attributes": {
                "flag": "🇬🇸",
                "display_name": "South Georgia and the South Sandwich Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sh",
              "name": "sh",
              "type": "enum_value",
              "integer_value": 196,
              "attributes": {
                "flag": "🇸🇭",
                "display_name": "Saint Helena, Ascension and Tristan da Cunha"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sj",
              "name": "sj",
              "type": "enum_value",
              "integer_value": 197,
              "attributes": {
                "flag": "🇸🇯",
                "display_name": "Svalbard and Jan Mayen"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sb",
              "name": "sb",
              "type": "enum_value",
              "integer_value": 198,
              "attributes": {
                "flag": "🇸🇧",
                "display_name": "Solomon Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sl",
              "name": "sl",
              "type": "enum_value",
              "integer_value": 199,
              "attributes": {
                "flag": "🇸🇱",
                "display_name": "Sierra Leone"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sv",
              "name": "sv",
              "type": "enum_value",
              "integer_value": 200,
              "attributes": {
                "flag": "🇸🇻",
                "display_name": "El Salvador"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sm",
              "name": "sm",
              "type": "enum_value",
              "integer_value": 201,
              "attributes": {
                "flag": "🇸🇲",
                "display_name": "San Marino"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::So",
              "name": "so",
              "type": "enum_value",
              "integer_value": 202,
              "attributes": {
                "flag": "🇸🇴",
                "display_name": "Somalia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Pm",
              "name": "pm",
              "type": "enum_value",
              "integer_value": 203,
              "attributes": {
                "flag": "🇵🇲",
                "display_name": "Saint Pierre and Miquelon"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Rs",
              "name": "rs",
              "type": "enum_value",
              "integer_value": 204,
              "attributes": {
                "flag": "🇷🇸",
                "display_name": "Serbia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ss",
              "name": "ss",
              "type": "enum_value",
              "integer_value": 205,
              "attributes": {
                "flag": "🇸🇸",
                "display_name": "South Sudan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::St",
              "name": "st",
              "type": "enum_value",
              "integer_value": 206,
              "attributes": {
                "flag": "🇸🇹",
                "display_name": "Sao Tome and Principe"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sr",
              "name": "sr",
              "type": "enum_value",
              "integer_value": 207,
              "attributes": {
                "flag": "🇸🇷",
                "display_name": "Suriname"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sk",
              "name": "sk",
              "type": "enum_value",
              "integer_value": 208,
              "attributes": {
                "flag": "🇸🇰",
                "display_name": "Slovakia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Si",
              "name": "si",
              "type": "enum_value",
              "integer_value": 209,
              "attributes": {
                "flag": "🇸🇮",
                "display_name": "Slovenia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Se",
              "name": "se",
              "type": "enum_value",
              "integer_value": 210,
              "attributes": {
                "flag": "🇸🇪",
                "display_name": "Sweden"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sz",
              "name": "sz",
              "type": "enum_value",
              "integer_value": 211,
              "attributes": {
                "flag": "🇸🇿",
                "display_name": "Eswatini"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sx",
              "name": "sx",
              "type": "enum_value",
              "integer_value": 212,
              "attributes": {
                "flag": "🇸🇽",
                "display_name": "Sint Maarten (Dutch part)"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sc",
              "name": "sc",
              "type": "enum_value",
              "integer_value": 213,
              "attributes": {
                "flag": "🇸🇨",
                "display_name": "Seychelles"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Sy",
              "name": "sy",
              "type": "enum_value",
              "integer_value": 214,
              "attributes": {
                "flag": "🇸🇾",
                "display_name": "Syrian Arab Republic"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tc",
              "name": "tc",
              "type": "enum_value",
              "integer_value": 215,
              "attributes": {
                "flag": "🇹🇨",
                "display_name": "Turks and Caicos Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Td",
              "name": "td",
              "type": "enum_value",
              "integer_value": 216,
              "attributes": {
                "flag": "🇹🇩",
                "display_name": "Chad"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tg",
              "name": "tg",
              "type": "enum_value",
              "integer_value": 217,
              "attributes": {
                "flag": "🇹🇬",
                "display_name": "Togo"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Th",
              "name": "th",
              "type": "enum_value",
              "integer_value": 218,
              "attributes": {
                "flag": "🇹🇭",
                "display_name": "Thailand"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tj",
              "name": "tj",
              "type": "enum_value",
              "integer_value": 219,
              "attributes": {
                "flag": "🇹🇯",
                "display_name": "Tajikistan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tk",
              "name": "tk",
              "type": "enum_value",
              "integer_value": 220,
              "attributes": {
                "flag": "🇹🇰",
                "display_name": "Tokelau"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tm",
              "name": "tm",
              "type": "enum_value",
              "integer_value": 221,
              "attributes": {
                "flag": "🇹🇲",
                "display_name": "Turkmenistan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tl",
              "name": "tl",
              "type": "enum_value",
              "integer_value": 222,
              "attributes": {
                "flag": "🇹🇱",
                "display_name": "Timor-Leste"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::To",
              "name": "to",
              "type": "enum_value",
              "integer_value": 223,
              "attributes": {
                "flag": "🇹🇴",
                "display_name": "Tonga"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tt",
              "name": "tt",
              "type": "enum_value",
              "integer_value": 224,
              "attributes": {
                "flag": "🇹🇹",
                "display_name": "Trinidad and Tobago"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tn",
              "name": "tn",
              "type": "enum_value",
              "integer_value": 225,
              "attributes": {
                "flag": "🇹🇳",
                "display_name": "Tunisia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tr",
              "name": "tr",
              "type": "enum_value",
              "integer_value": 226,
              "attributes": {
                "flag": "🇹🇷",
                "display_name": "Turkey"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tv",
              "name": "tv",
              "type": "enum_value",
              "integer_value": 227,
              "attributes": {
                "flag": "🇹🇻",
                "display_name": "Tuvalu"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tw",
              "name": "tw",
              "type": "enum_value",
              "integer_value": 228,
              "attributes": {
                "flag": "🇹🇼",
                "display_name": "Taiwan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Tz",
              "name": "tz",
              "type": "enum_value",
              "integer_value": 229,
              "attributes": {
                "flag": "🇹🇿",
                "display_name": "Tanzania"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ug",
              "name": "ug",
              "type": "enum_value",
              "integer_value": 230,
              "attributes": {
                "flag": "🇺🇬",
                "display_name": "Uganda"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ua",
              "name": "ua",
              "type": "enum_value",
              "integer_value": 231,
              "attributes": {
                "flag": "🇺🇦",
                "display_name": "Ukraine"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Um",
              "name": "um",
              "type": "enum_value",
              "integer_value": 232,
              "attributes": {
                "flag": "🇺🇲",
                "display_name": "United States Minor Outlying Islands"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Uy",
              "name": "uy",
              "type": "enum_value",
              "integer_value": 233,
              "attributes": {
                "flag": "🇺🇾",
                "display_name": "Uruguay"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Us",
              "name": "us",
              "type": "enum_value",
              "integer_value": 234,
              "attributes": {
                "flag": "🇺🇸",
                "display_name": "United States"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Uz",
              "name": "uz",
              "type": "enum_value",
              "integer_value": 235,
              "attributes": {
                "flag": "🇺🇿",
                "display_name": "Uzbekistan"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Va",
              "name": "va",
              "type": "enum_value",
              "integer_value": 236,
              "attributes": {
                "flag": "🇻🇦",
                "display_name": "Holy See (Vatican City State)"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Vc",
              "name": "vc",
              "type": "enum_value",
              "integer_value": 237,
              "attributes": {
                "flag": "🇻🇨",
                "display_name": "Saint Vincent and the Grenadines"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ve",
              "name": "ve",
              "type": "enum_value",
              "integer_value": 238,
              "attributes": {
                "flag": "🇻🇪",
                "display_name": "Venezuela"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Vg",
              "name": "vg",
              "type": "enum_value",
              "integer_value": 239,
              "attributes": {
                "flag": "🇻🇬",
                "display_name": "Virgin Islands, British"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Vi",
              "name": "vi",
              "type": "enum_value",
              "integer_value": 240,
              "attributes": {
                "flag": "🇻🇮",
                "display_name": "Virgin Islands, U.S."
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Vn",
              "name": "vn",
              "type": "enum_value",
              "integer_value": 241,
              "attributes": {
                "flag": "🇻🇳",
                "display_name": "Vietnam"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Vu",
              "name": "vu",
              "type": "enum_value",
              "integer_value": 242,
              "attributes": {
                "flag": "🇻🇺",
                "display_name": "Vanuatu"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Wf",
              "name": "wf",
              "type": "enum_value",
              "integer_value": 243,
              "attributes": {
                "flag": "🇼🇫",
                "display_name": "Wallis and Futuna"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ws",
              "name": "ws",
              "type": "enum_value",
              "integer_value": 244,
              "attributes": {
                "flag": "🇼🇸",
                "display_name": "Samoa"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Ye",
              "name": "ye",
              "type": "enum_value",
              "integer_value": 245,
              "attributes": {
                "flag": "🇾🇪",
                "display_name": "Yemen"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Za",
              "name": "za",
              "type": "enum_value",
              "integer_value": 246,
              "attributes": {
                "flag": "🇿🇦",
                "display_name": "South Africa"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Zm",
              "name": "zm",
              "type": "enum_value",
              "integer_value": 247,
              "attributes": {
                "flag": "🇿🇲",
                "display_name": "Zambia"
              }
            },
            {
              "source": "Kore::Types::User::Fields::Country::Zw",
              "name": "zw",
              "type": "enum_value",
              "integer_value": 248,
              "attributes": {
                "flag": "🇿🇼",
                "display_name": "Zimbabwe"
              }
            }
          ],
          "values": [
            "aw",
            "af",
            "ao",
            "ai",
            "ax",
            "al",
            "ad",
            "ae",
            "ar",
            "am",
            "as",
            "aq",
            "tf",
            "ag",
            "au",
            "at",
            "az",
            "bi",
            "be",
            "bj",
            "bq",
            "bf",
            "bd",
            "bg",
            "bh",
            "bs",
            "ba",
            "bl",
            "by",
            "bz",
            "bm",
            "bo",
            "br",
            "bb",
            "bn",
            "bt",
            "bv",
            "bw",
            "cf",
            "ca",
            "cc",
            "ch",
            "cl",
            "cn",
            "ci",
            "cm",
            "cd",
            "cg",
            "ck",
            "co",
            "km",
            "cv",
            "cr",
            "cu",
            "cw",
            "cx",
            "ky",
            "cy",
            "cz",
            "de",
            "dj",
            "dm",
            "dk",
            "do",
            "dz",
            "ec",
            "eg",
            "er",
            "eh",
            "es",
            "ee",
            "et",
            "fi",
            "fj",
            "fk",
            "fr",
            "fo",
            "fm",
            "ga",
            "gb",
            "ge",
            "gg",
            "gh",
            "gi",
            "gn",
            "gp",
            "gm",
            "gw",
            "gq",
            "gr",
            "gd",
            "gl",
            "gt",
            "gf",
            "gu",
            "gy",
            "hk",
            "hm",
            "hn",
            "hr",
            "ht",
            "hu",
            "id",
            "im",
            "in",
            "io",
            "ie",
            "ir",
            "iq",
            "is",
            "il",
            "it",
            "jm",
            "je",
            "jo",
            "jp",
            "kz",
            "ke",
            "kg",
            "kh",
            "ki",
            "kn",
            "kr",
            "kw",
            "la",
            "lb",
            "lr",
            "ly",
            "lc",
            "li",
            "lk",
            "ls",
            "lt",
            "lu",
            "lv",
            "mo",
            "mf",
            "ma",
            "mc",
            "md",
            "mg",
            "mv",
            "mx",
            "mh",
            "mk",
            "ml",
            "mt",
            "mm",
            "me",
            "mn",
            "mp",
            "mz",
            "mr",
            "ms",
            "mq",
            "mu",
            "mw",
            "my",
            "yt",
            "na",
            "nc",
            "ne",
            "nf",
            "ng",
            "ni",
            "nu",
            "nl",
            "no",
            "np",
            "nr",
            "nz",
            "om",
            "pk",
            "pa",
            "pn",
            "pe",
            "ph",
            "pw",
            "pg",
            "pl",
            "pr",
            "kp",
            "pt",
            "py",
            "ps",
            "pf",
            "qa",
            "re",
            "ro",
            "ru",
            "rw",
            "sa",
            "sd",
            "sn",
            "sg",
            "gs",
            "sh",
            "sj",
            "sb",
            "sl",
            "sv",
            "sm",
            "so",
            "pm",
            "rs",
            "ss",
            "st",
            "sr",
            "sk",
            "si",
            "se",
            "sz",
            "sx",
            "sc",
            "sy",
            "tc",
            "td",
            "tg",
            "th",
            "tj",
            "tk",
            "tm",
            "tl",
            "to",
            "tt",
            "tn",
            "tr",
            "tv",
            "tw",
            "tz",
            "ug",
            "ua",
            "um",
            "uy",
            "us",
            "uz",
            "va",
            "vc",
            "ve",
            "vg",
            "vi",
            "vn",
            "vu",
            "wf",
            "ws",
            "ye",
            "za",
            "zm",
            "zw"
          ],
          "default": "us"
        }
      }
    }
  },
  "User": {
    "country": {
      "name": "country",
      "source": "User::Fields::Country",
      "type": "enum",
      "choices": [
        {
          "source": "Kore::Types::User::Fields::Country::Aw",
          "name": "aw",
          "type": "enum_value",
          "integer_value": 0,
          "attributes": {
            "flag": "🇦🇼",
            "display_name": "Aruba"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Af",
          "name": "af",
          "type": "enum_value",
          "integer_value": 1,
          "attributes": {
            "flag": "🇦🇫",
            "display_name": "Afghanistan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ao",
          "name": "ao",
          "type": "enum_value",
          "integer_value": 2,
          "attributes": {
            "flag": "🇦🇴",
            "display_name": "Angola"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ai",
          "name": "ai",
          "type": "enum_value",
          "integer_value": 3,
          "attributes": {
            "flag": "🇦🇮",
            "display_name": "Anguilla"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ax",
          "name": "ax",
          "type": "enum_value",
          "integer_value": 4,
          "attributes": {
            "flag": "🇦🇽",
            "display_name": "Åland Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Al",
          "name": "al",
          "type": "enum_value",
          "integer_value": 5,
          "attributes": {
            "flag": "🇦🇱",
            "display_name": "Albania"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ad",
          "name": "ad",
          "type": "enum_value",
          "integer_value": 6,
          "attributes": {
            "flag": "🇦🇩",
            "display_name": "Andorra"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ae",
          "name": "ae",
          "type": "enum_value",
          "integer_value": 7,
          "attributes": {
            "flag": "🇦🇪",
            "display_name": "United Arab Emirates"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ar",
          "name": "ar",
          "type": "enum_value",
          "integer_value": 8,
          "attributes": {
            "flag": "🇦🇷",
            "display_name": "Argentina"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Am",
          "name": "am",
          "type": "enum_value",
          "integer_value": 9,
          "attributes": {
            "flag": "🇦🇲",
            "display_name": "Armenia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::As",
          "name": "as",
          "type": "enum_value",
          "integer_value": 10,
          "attributes": {
            "flag": "🇦🇸",
            "display_name": "American Samoa"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Aq",
          "name": "aq",
          "type": "enum_value",
          "integer_value": 11,
          "attributes": {
            "flag": "🇦🇶",
            "display_name": "Antarctica"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tf",
          "name": "tf",
          "type": "enum_value",
          "integer_value": 12,
          "attributes": {
            "flag": "🇹🇫",
            "display_name": "French Southern Territories"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ag",
          "name": "ag",
          "type": "enum_value",
          "integer_value": 13,
          "attributes": {
            "flag": "🇦🇬",
            "display_name": "Antigua and Barbuda"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Au",
          "name": "au",
          "type": "enum_value",
          "integer_value": 14,
          "attributes": {
            "flag": "🇦🇺",
            "display_name": "Australia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::At",
          "name": "at",
          "type": "enum_value",
          "integer_value": 15,
          "attributes": {
            "flag": "🇦🇹",
            "display_name": "Austria"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Az",
          "name": "az",
          "type": "enum_value",
          "integer_value": 16,
          "attributes": {
            "flag": "🇦🇿",
            "display_name": "Azerbaijan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bi",
          "name": "bi",
          "type": "enum_value",
          "integer_value": 17,
          "attributes": {
            "flag": "🇧🇮",
            "display_name": "Burundi"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Be",
          "name": "be",
          "type": "enum_value",
          "integer_value": 18,
          "attributes": {
            "flag": "🇧🇪",
            "display_name": "Belgium"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bj",
          "name": "bj",
          "type": "enum_value",
          "integer_value": 19,
          "attributes": {
            "flag": "🇧🇯",
            "display_name": "Benin"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bq",
          "name": "bq",
          "type": "enum_value",
          "integer_value": 20,
          "attributes": {
            "flag": "🇧🇶",
            "display_name": "Bonaire, Sint Eustatius and Saba"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bf",
          "name": "bf",
          "type": "enum_value",
          "integer_value": 21,
          "attributes": {
            "flag": "🇧🇫",
            "display_name": "Burkina Faso"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bd",
          "name": "bd",
          "type": "enum_value",
          "integer_value": 22,
          "attributes": {
            "flag": "🇧🇩",
            "display_name": "Bangladesh"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bg",
          "name": "bg",
          "type": "enum_value",
          "integer_value": 23,
          "attributes": {
            "flag": "🇧🇬",
            "display_name": "Bulgaria"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bh",
          "name": "bh",
          "type": "enum_value",
          "integer_value": 24,
          "attributes": {
            "flag": "🇧🇭",
            "display_name": "Bahrain"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bs",
          "name": "bs",
          "type": "enum_value",
          "integer_value": 25,
          "attributes": {
            "flag": "🇧🇸",
            "display_name": "Bahamas"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ba",
          "name": "ba",
          "type": "enum_value",
          "integer_value": 26,
          "attributes": {
            "flag": "🇧🇦",
            "display_name": "Bosnia and Herzegovina"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bl",
          "name": "bl",
          "type": "enum_value",
          "integer_value": 27,
          "attributes": {
            "flag": "🇧🇱",
            "display_name": "Saint Barthélemy"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::By",
          "name": "by",
          "type": "enum_value",
          "integer_value": 28,
          "attributes": {
            "flag": "🇧🇾",
            "display_name": "Belarus"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bz",
          "name": "bz",
          "type": "enum_value",
          "integer_value": 29,
          "attributes": {
            "flag": "🇧🇿",
            "display_name": "Belize"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bm",
          "name": "bm",
          "type": "enum_value",
          "integer_value": 30,
          "attributes": {
            "flag": "🇧🇲",
            "display_name": "Bermuda"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bo",
          "name": "bo",
          "type": "enum_value",
          "integer_value": 31,
          "attributes": {
            "flag": "🇧🇴",
            "display_name": "Bolivia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Br",
          "name": "br",
          "type": "enum_value",
          "integer_value": 32,
          "attributes": {
            "flag": "🇧🇷",
            "display_name": "Brazil"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bb",
          "name": "bb",
          "type": "enum_value",
          "integer_value": 33,
          "attributes": {
            "flag": "🇧🇧",
            "display_name": "Barbados"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bn",
          "name": "bn",
          "type": "enum_value",
          "integer_value": 34,
          "attributes": {
            "flag": "🇧🇳",
            "display_name": "Brunei Darussalam"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bt",
          "name": "bt",
          "type": "enum_value",
          "integer_value": 35,
          "attributes": {
            "flag": "🇧🇹",
            "display_name": "Bhutan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bv",
          "name": "bv",
          "type": "enum_value",
          "integer_value": 36,
          "attributes": {
            "flag": "🇧🇻",
            "display_name": "Bouvet Island"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Bw",
          "name": "bw",
          "type": "enum_value",
          "integer_value": 37,
          "attributes": {
            "flag": "🇧🇼",
            "display_name": "Botswana"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cf",
          "name": "cf",
          "type": "enum_value",
          "integer_value": 38,
          "attributes": {
            "flag": "🇨🇫",
            "display_name": "Central African Republic"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ca",
          "name": "ca",
          "type": "enum_value",
          "integer_value": 39,
          "attributes": {
            "flag": "🇨🇦",
            "display_name": "Canada"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cc",
          "name": "cc",
          "type": "enum_value",
          "integer_value": 40,
          "attributes": {
            "flag": "🇨🇨",
            "display_name": "Cocos (Keeling) Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ch",
          "name": "ch",
          "type": "enum_value",
          "integer_value": 41,
          "attributes": {
            "flag": "🇨🇭",
            "display_name": "Switzerland"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cl",
          "name": "cl",
          "type": "enum_value",
          "integer_value": 42,
          "attributes": {
            "flag": "🇨🇱",
            "display_name": "Chile"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cn",
          "name": "cn",
          "type": "enum_value",
          "integer_value": 43,
          "attributes": {
            "flag": "🇨🇳",
            "display_name": "China"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ci",
          "name": "ci",
          "type": "enum_value",
          "integer_value": 44,
          "attributes": {
            "flag": "🇨🇮",
            "display_name": "Côte d'Ivoire"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cm",
          "name": "cm",
          "type": "enum_value",
          "integer_value": 45,
          "attributes": {
            "flag": "🇨🇲",
            "display_name": "Cameroon"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cd",
          "name": "cd",
          "type": "enum_value",
          "integer_value": 46,
          "attributes": {
            "flag": "🇨🇩",
            "display_name": "Congo, The Democratic Republic of the"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cg",
          "name": "cg",
          "type": "enum_value",
          "integer_value": 47,
          "attributes": {
            "flag": "🇨🇬",
            "display_name": "Congo"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ck",
          "name": "ck",
          "type": "enum_value",
          "integer_value": 48,
          "attributes": {
            "flag": "🇨🇰",
            "display_name": "Cook Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Co",
          "name": "co",
          "type": "enum_value",
          "integer_value": 49,
          "attributes": {
            "flag": "🇨🇴",
            "display_name": "Colombia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Km",
          "name": "km",
          "type": "enum_value",
          "integer_value": 50,
          "attributes": {
            "flag": "🇰🇲",
            "display_name": "Comoros"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cv",
          "name": "cv",
          "type": "enum_value",
          "integer_value": 51,
          "attributes": {
            "flag": "🇨🇻",
            "display_name": "Cabo Verde"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cr",
          "name": "cr",
          "type": "enum_value",
          "integer_value": 52,
          "attributes": {
            "flag": "🇨🇷",
            "display_name": "Costa Rica"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cu",
          "name": "cu",
          "type": "enum_value",
          "integer_value": 53,
          "attributes": {
            "flag": "🇨🇺",
            "display_name": "Cuba"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cw",
          "name": "cw",
          "type": "enum_value",
          "integer_value": 54,
          "attributes": {
            "flag": "🇨🇼",
            "display_name": "Curaçao"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cx",
          "name": "cx",
          "type": "enum_value",
          "integer_value": 55,
          "attributes": {
            "flag": "🇨🇽",
            "display_name": "Christmas Island"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ky",
          "name": "ky",
          "type": "enum_value",
          "integer_value": 56,
          "attributes": {
            "flag": "🇰🇾",
            "display_name": "Cayman Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cy",
          "name": "cy",
          "type": "enum_value",
          "integer_value": 57,
          "attributes": {
            "flag": "🇨🇾",
            "display_name": "Cyprus"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Cz",
          "name": "cz",
          "type": "enum_value",
          "integer_value": 58,
          "attributes": {
            "flag": "🇨🇿",
            "display_name": "Czechia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::De",
          "name": "de",
          "type": "enum_value",
          "integer_value": 59,
          "attributes": {
            "flag": "🇩🇪",
            "display_name": "Germany"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Dj",
          "name": "dj",
          "type": "enum_value",
          "integer_value": 60,
          "attributes": {
            "flag": "🇩🇯",
            "display_name": "Djibouti"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Dm",
          "name": "dm",
          "type": "enum_value",
          "integer_value": 61,
          "attributes": {
            "flag": "🇩🇲",
            "display_name": "Dominica"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Dk",
          "name": "dk",
          "type": "enum_value",
          "integer_value": 62,
          "attributes": {
            "flag": "🇩🇰",
            "display_name": "Denmark"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Do",
          "name": "do",
          "type": "enum_value",
          "integer_value": 63,
          "attributes": {
            "flag": "🇩🇴",
            "display_name": "Dominican Republic"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Dz",
          "name": "dz",
          "type": "enum_value",
          "integer_value": 64,
          "attributes": {
            "flag": "🇩🇿",
            "display_name": "Algeria"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ec",
          "name": "ec",
          "type": "enum_value",
          "integer_value": 65,
          "attributes": {
            "flag": "🇪🇨",
            "display_name": "Ecuador"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Eg",
          "name": "eg",
          "type": "enum_value",
          "integer_value": 66,
          "attributes": {
            "flag": "🇪🇬",
            "display_name": "Egypt"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Er",
          "name": "er",
          "type": "enum_value",
          "integer_value": 67,
          "attributes": {
            "flag": "🇪🇷",
            "display_name": "Eritrea"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Eh",
          "name": "eh",
          "type": "enum_value",
          "integer_value": 68,
          "attributes": {
            "flag": "🇪🇭",
            "display_name": "Western Sahara"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Es",
          "name": "es",
          "type": "enum_value",
          "integer_value": 69,
          "attributes": {
            "flag": "🇪🇸",
            "display_name": "Spain"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ee",
          "name": "ee",
          "type": "enum_value",
          "integer_value": 70,
          "attributes": {
            "flag": "🇪🇪",
            "display_name": "Estonia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Et",
          "name": "et",
          "type": "enum_value",
          "integer_value": 71,
          "attributes": {
            "flag": "🇪🇹",
            "display_name": "Ethiopia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Fi",
          "name": "fi",
          "type": "enum_value",
          "integer_value": 72,
          "attributes": {
            "flag": "🇫🇮",
            "display_name": "Finland"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Fj",
          "name": "fj",
          "type": "enum_value",
          "integer_value": 73,
          "attributes": {
            "flag": "🇫🇯",
            "display_name": "Fiji"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Fk",
          "name": "fk",
          "type": "enum_value",
          "integer_value": 74,
          "attributes": {
            "flag": "🇫🇰",
            "display_name": "Falkland Islands (Malvinas)"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Fr",
          "name": "fr",
          "type": "enum_value",
          "integer_value": 75,
          "attributes": {
            "flag": "🇫🇷",
            "display_name": "France"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Fo",
          "name": "fo",
          "type": "enum_value",
          "integer_value": 76,
          "attributes": {
            "flag": "🇫🇴",
            "display_name": "Faroe Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Fm",
          "name": "fm",
          "type": "enum_value",
          "integer_value": 77,
          "attributes": {
            "flag": "🇫🇲",
            "display_name": "Micronesia, Federated States of"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ga",
          "name": "ga",
          "type": "enum_value",
          "integer_value": 78,
          "attributes": {
            "flag": "🇬🇦",
            "display_name": "Gabon"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gb",
          "name": "gb",
          "type": "enum_value",
          "integer_value": 79,
          "attributes": {
            "flag": "🇬🇧",
            "display_name": "United Kingdom"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ge",
          "name": "ge",
          "type": "enum_value",
          "integer_value": 80,
          "attributes": {
            "flag": "🇬🇪",
            "display_name": "Georgia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gg",
          "name": "gg",
          "type": "enum_value",
          "integer_value": 81,
          "attributes": {
            "flag": "🇬🇬",
            "display_name": "Guernsey"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gh",
          "name": "gh",
          "type": "enum_value",
          "integer_value": 82,
          "attributes": {
            "flag": "🇬🇭",
            "display_name": "Ghana"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gi",
          "name": "gi",
          "type": "enum_value",
          "integer_value": 83,
          "attributes": {
            "flag": "🇬🇮",
            "display_name": "Gibraltar"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gn",
          "name": "gn",
          "type": "enum_value",
          "integer_value": 84,
          "attributes": {
            "flag": "🇬🇳",
            "display_name": "Guinea"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gp",
          "name": "gp",
          "type": "enum_value",
          "integer_value": 85,
          "attributes": {
            "flag": "🇬🇵",
            "display_name": "Guadeloupe"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gm",
          "name": "gm",
          "type": "enum_value",
          "integer_value": 86,
          "attributes": {
            "flag": "🇬🇲",
            "display_name": "Gambia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gw",
          "name": "gw",
          "type": "enum_value",
          "integer_value": 87,
          "attributes": {
            "flag": "🇬🇼",
            "display_name": "Guinea-Bissau"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gq",
          "name": "gq",
          "type": "enum_value",
          "integer_value": 88,
          "attributes": {
            "flag": "🇬🇶",
            "display_name": "Equatorial Guinea"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gr",
          "name": "gr",
          "type": "enum_value",
          "integer_value": 89,
          "attributes": {
            "flag": "🇬🇷",
            "display_name": "Greece"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gd",
          "name": "gd",
          "type": "enum_value",
          "integer_value": 90,
          "attributes": {
            "flag": "🇬🇩",
            "display_name": "Grenada"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gl",
          "name": "gl",
          "type": "enum_value",
          "integer_value": 91,
          "attributes": {
            "flag": "🇬🇱",
            "display_name": "Greenland"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gt",
          "name": "gt",
          "type": "enum_value",
          "integer_value": 92,
          "attributes": {
            "flag": "🇬🇹",
            "display_name": "Guatemala"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gf",
          "name": "gf",
          "type": "enum_value",
          "integer_value": 93,
          "attributes": {
            "flag": "🇬🇫",
            "display_name": "French Guiana"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gu",
          "name": "gu",
          "type": "enum_value",
          "integer_value": 94,
          "attributes": {
            "flag": "🇬🇺",
            "display_name": "Guam"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gy",
          "name": "gy",
          "type": "enum_value",
          "integer_value": 95,
          "attributes": {
            "flag": "🇬🇾",
            "display_name": "Guyana"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Hk",
          "name": "hk",
          "type": "enum_value",
          "integer_value": 96,
          "attributes": {
            "flag": "🇭🇰",
            "display_name": "Hong Kong"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Hm",
          "name": "hm",
          "type": "enum_value",
          "integer_value": 97,
          "attributes": {
            "flag": "🇭🇲",
            "display_name": "Heard Island and McDonald Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Hn",
          "name": "hn",
          "type": "enum_value",
          "integer_value": 98,
          "attributes": {
            "flag": "🇭🇳",
            "display_name": "Honduras"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Hr",
          "name": "hr",
          "type": "enum_value",
          "integer_value": 99,
          "attributes": {
            "flag": "🇭🇷",
            "display_name": "Croatia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ht",
          "name": "ht",
          "type": "enum_value",
          "integer_value": 100,
          "attributes": {
            "flag": "🇭🇹",
            "display_name": "Haiti"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Hu",
          "name": "hu",
          "type": "enum_value",
          "integer_value": 101,
          "attributes": {
            "flag": "🇭🇺",
            "display_name": "Hungary"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Id",
          "name": "id",
          "type": "enum_value",
          "integer_value": 102,
          "attributes": {
            "flag": "🇮🇩",
            "display_name": "Indonesia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Im",
          "name": "im",
          "type": "enum_value",
          "integer_value": 103,
          "attributes": {
            "flag": "🇮🇲",
            "display_name": "Isle of Man"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::In",
          "name": "in",
          "type": "enum_value",
          "integer_value": 104,
          "attributes": {
            "flag": "🇮🇳",
            "display_name": "India"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Io",
          "name": "io",
          "type": "enum_value",
          "integer_value": 105,
          "attributes": {
            "flag": "🇮🇴",
            "display_name": "British Indian Ocean Territory"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ie",
          "name": "ie",
          "type": "enum_value",
          "integer_value": 106,
          "attributes": {
            "flag": "🇮🇪",
            "display_name": "Ireland"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ir",
          "name": "ir",
          "type": "enum_value",
          "integer_value": 107,
          "attributes": {
            "flag": "🇮🇷",
            "display_name": "Iran, Islamic Republic of"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Iq",
          "name": "iq",
          "type": "enum_value",
          "integer_value": 108,
          "attributes": {
            "flag": "🇮🇶",
            "display_name": "Iraq"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Is",
          "name": "is",
          "type": "enum_value",
          "integer_value": 109,
          "attributes": {
            "flag": "🇮🇸",
            "display_name": "Iceland"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Il",
          "name": "il",
          "type": "enum_value",
          "integer_value": 110,
          "attributes": {
            "flag": "🇮🇱",
            "display_name": "Israel"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::It",
          "name": "it",
          "type": "enum_value",
          "integer_value": 111,
          "attributes": {
            "flag": "🇮🇹",
            "display_name": "Italy"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Jm",
          "name": "jm",
          "type": "enum_value",
          "integer_value": 112,
          "attributes": {
            "flag": "🇯🇲",
            "display_name": "Jamaica"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Je",
          "name": "je",
          "type": "enum_value",
          "integer_value": 113,
          "attributes": {
            "flag": "🇯🇪",
            "display_name": "Jersey"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Jo",
          "name": "jo",
          "type": "enum_value",
          "integer_value": 114,
          "attributes": {
            "flag": "🇯🇴",
            "display_name": "Jordan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Jp",
          "name": "jp",
          "type": "enum_value",
          "integer_value": 115,
          "attributes": {
            "flag": "🇯🇵",
            "display_name": "Japan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Kz",
          "name": "kz",
          "type": "enum_value",
          "integer_value": 116,
          "attributes": {
            "flag": "🇰🇿",
            "display_name": "Kazakhstan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ke",
          "name": "ke",
          "type": "enum_value",
          "integer_value": 117,
          "attributes": {
            "flag": "🇰🇪",
            "display_name": "Kenya"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Kg",
          "name": "kg",
          "type": "enum_value",
          "integer_value": 118,
          "attributes": {
            "flag": "🇰🇬",
            "display_name": "Kyrgyzstan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Kh",
          "name": "kh",
          "type": "enum_value",
          "integer_value": 119,
          "attributes": {
            "flag": "🇰🇭",
            "display_name": "Cambodia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ki",
          "name": "ki",
          "type": "enum_value",
          "integer_value": 120,
          "attributes": {
            "flag": "🇰🇮",
            "display_name": "Kiribati"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Kn",
          "name": "kn",
          "type": "enum_value",
          "integer_value": 121,
          "attributes": {
            "flag": "🇰🇳",
            "display_name": "Saint Kitts and Nevis"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Kr",
          "name": "kr",
          "type": "enum_value",
          "integer_value": 122,
          "attributes": {
            "flag": "🇰🇷",
            "display_name": "South Korea"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Kw",
          "name": "kw",
          "type": "enum_value",
          "integer_value": 123,
          "attributes": {
            "flag": "🇰🇼",
            "display_name": "Kuwait"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::La",
          "name": "la",
          "type": "enum_value",
          "integer_value": 124,
          "attributes": {
            "flag": "🇱🇦",
            "display_name": "Lao People's Democratic Republic"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Lb",
          "name": "lb",
          "type": "enum_value",
          "integer_value": 125,
          "attributes": {
            "flag": "🇱🇧",
            "display_name": "Lebanon"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Lr",
          "name": "lr",
          "type": "enum_value",
          "integer_value": 126,
          "attributes": {
            "flag": "🇱🇷",
            "display_name": "Liberia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ly",
          "name": "ly",
          "type": "enum_value",
          "integer_value": 127,
          "attributes": {
            "flag": "🇱🇾",
            "display_name": "Libya"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Lc",
          "name": "lc",
          "type": "enum_value",
          "integer_value": 128,
          "attributes": {
            "flag": "🇱🇨",
            "display_name": "Saint Lucia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Li",
          "name": "li",
          "type": "enum_value",
          "integer_value": 129,
          "attributes": {
            "flag": "🇱🇮",
            "display_name": "Liechtenstein"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Lk",
          "name": "lk",
          "type": "enum_value",
          "integer_value": 130,
          "attributes": {
            "flag": "🇱🇰",
            "display_name": "Sri Lanka"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ls",
          "name": "ls",
          "type": "enum_value",
          "integer_value": 131,
          "attributes": {
            "flag": "🇱🇸",
            "display_name": "Lesotho"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Lt",
          "name": "lt",
          "type": "enum_value",
          "integer_value": 132,
          "attributes": {
            "flag": "🇱🇹",
            "display_name": "Lithuania"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Lu",
          "name": "lu",
          "type": "enum_value",
          "integer_value": 133,
          "attributes": {
            "flag": "🇱🇺",
            "display_name": "Luxembourg"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Lv",
          "name": "lv",
          "type": "enum_value",
          "integer_value": 134,
          "attributes": {
            "flag": "🇱🇻",
            "display_name": "Latvia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mo",
          "name": "mo",
          "type": "enum_value",
          "integer_value": 135,
          "attributes": {
            "flag": "🇲🇴",
            "display_name": "Macao"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mf",
          "name": "mf",
          "type": "enum_value",
          "integer_value": 136,
          "attributes": {
            "flag": "🇲🇫",
            "display_name": "Saint Martin (French part)"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ma",
          "name": "ma",
          "type": "enum_value",
          "integer_value": 137,
          "attributes": {
            "flag": "🇲🇦",
            "display_name": "Morocco"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mc",
          "name": "mc",
          "type": "enum_value",
          "integer_value": 138,
          "attributes": {
            "flag": "🇲🇨",
            "display_name": "Monaco"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Md",
          "name": "md",
          "type": "enum_value",
          "integer_value": 139,
          "attributes": {
            "flag": "🇲🇩",
            "display_name": "Moldova"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mg",
          "name": "mg",
          "type": "enum_value",
          "integer_value": 140,
          "attributes": {
            "flag": "🇲🇬",
            "display_name": "Madagascar"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mv",
          "name": "mv",
          "type": "enum_value",
          "integer_value": 141,
          "attributes": {
            "flag": "🇲🇻",
            "display_name": "Maldives"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mx",
          "name": "mx",
          "type": "enum_value",
          "integer_value": 142,
          "attributes": {
            "flag": "🇲🇽",
            "display_name": "Mexico"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mh",
          "name": "mh",
          "type": "enum_value",
          "integer_value": 143,
          "attributes": {
            "flag": "🇲🇭",
            "display_name": "Marshall Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mk",
          "name": "mk",
          "type": "enum_value",
          "integer_value": 144,
          "attributes": {
            "flag": "🇲🇰",
            "display_name": "North Macedonia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ml",
          "name": "ml",
          "type": "enum_value",
          "integer_value": 145,
          "attributes": {
            "flag": "🇲🇱",
            "display_name": "Mali"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mt",
          "name": "mt",
          "type": "enum_value",
          "integer_value": 146,
          "attributes": {
            "flag": "🇲🇹",
            "display_name": "Malta"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mm",
          "name": "mm",
          "type": "enum_value",
          "integer_value": 147,
          "attributes": {
            "flag": "🇲🇲",
            "display_name": "Myanmar"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Me",
          "name": "me",
          "type": "enum_value",
          "integer_value": 148,
          "attributes": {
            "flag": "🇲🇪",
            "display_name": "Montenegro"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mn",
          "name": "mn",
          "type": "enum_value",
          "integer_value": 149,
          "attributes": {
            "flag": "🇲🇳",
            "display_name": "Mongolia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mp",
          "name": "mp",
          "type": "enum_value",
          "integer_value": 150,
          "attributes": {
            "flag": "🇲🇵",
            "display_name": "Northern Mariana Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mz",
          "name": "mz",
          "type": "enum_value",
          "integer_value": 151,
          "attributes": {
            "flag": "🇲🇿",
            "display_name": "Mozambique"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mr",
          "name": "mr",
          "type": "enum_value",
          "integer_value": 152,
          "attributes": {
            "flag": "🇲🇷",
            "display_name": "Mauritania"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ms",
          "name": "ms",
          "type": "enum_value",
          "integer_value": 153,
          "attributes": {
            "flag": "🇲🇸",
            "display_name": "Montserrat"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mq",
          "name": "mq",
          "type": "enum_value",
          "integer_value": 154,
          "attributes": {
            "flag": "🇲🇶",
            "display_name": "Martinique"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mu",
          "name": "mu",
          "type": "enum_value",
          "integer_value": 155,
          "attributes": {
            "flag": "🇲🇺",
            "display_name": "Mauritius"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Mw",
          "name": "mw",
          "type": "enum_value",
          "integer_value": 156,
          "attributes": {
            "flag": "🇲🇼",
            "display_name": "Malawi"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::My",
          "name": "my",
          "type": "enum_value",
          "integer_value": 157,
          "attributes": {
            "flag": "🇲🇾",
            "display_name": "Malaysia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Yt",
          "name": "yt",
          "type": "enum_value",
          "integer_value": 158,
          "attributes": {
            "flag": "🇾🇹",
            "display_name": "Mayotte"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Na",
          "name": "na",
          "type": "enum_value",
          "integer_value": 159,
          "attributes": {
            "flag": "🇳🇦",
            "display_name": "Namibia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Nc",
          "name": "nc",
          "type": "enum_value",
          "integer_value": 160,
          "attributes": {
            "flag": "🇳🇨",
            "display_name": "New Caledonia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ne",
          "name": "ne",
          "type": "enum_value",
          "integer_value": 161,
          "attributes": {
            "flag": "🇳🇪",
            "display_name": "Niger"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Nf",
          "name": "nf",
          "type": "enum_value",
          "integer_value": 162,
          "attributes": {
            "flag": "🇳🇫",
            "display_name": "Norfolk Island"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ng",
          "name": "ng",
          "type": "enum_value",
          "integer_value": 163,
          "attributes": {
            "flag": "🇳🇬",
            "display_name": "Nigeria"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ni",
          "name": "ni",
          "type": "enum_value",
          "integer_value": 164,
          "attributes": {
            "flag": "🇳🇮",
            "display_name": "Nicaragua"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Nu",
          "name": "nu",
          "type": "enum_value",
          "integer_value": 165,
          "attributes": {
            "flag": "🇳🇺",
            "display_name": "Niue"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Nl",
          "name": "nl",
          "type": "enum_value",
          "integer_value": 166,
          "attributes": {
            "flag": "🇳🇱",
            "display_name": "Netherlands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::No",
          "name": "no",
          "type": "enum_value",
          "integer_value": 167,
          "attributes": {
            "flag": "🇳🇴",
            "display_name": "Norway"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Np",
          "name": "np",
          "type": "enum_value",
          "integer_value": 168,
          "attributes": {
            "flag": "🇳🇵",
            "display_name": "Nepal"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Nr",
          "name": "nr",
          "type": "enum_value",
          "integer_value": 169,
          "attributes": {
            "flag": "🇳🇷",
            "display_name": "Nauru"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Nz",
          "name": "nz",
          "type": "enum_value",
          "integer_value": 170,
          "attributes": {
            "flag": "🇳🇿",
            "display_name": "New Zealand"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Om",
          "name": "om",
          "type": "enum_value",
          "integer_value": 171,
          "attributes": {
            "flag": "🇴🇲",
            "display_name": "Oman"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pk",
          "name": "pk",
          "type": "enum_value",
          "integer_value": 172,
          "attributes": {
            "flag": "🇵🇰",
            "display_name": "Pakistan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pa",
          "name": "pa",
          "type": "enum_value",
          "integer_value": 173,
          "attributes": {
            "flag": "🇵🇦",
            "display_name": "Panama"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pn",
          "name": "pn",
          "type": "enum_value",
          "integer_value": 174,
          "attributes": {
            "flag": "🇵🇳",
            "display_name": "Pitcairn"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pe",
          "name": "pe",
          "type": "enum_value",
          "integer_value": 175,
          "attributes": {
            "flag": "🇵🇪",
            "display_name": "Peru"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ph",
          "name": "ph",
          "type": "enum_value",
          "integer_value": 176,
          "attributes": {
            "flag": "🇵🇭",
            "display_name": "Philippines"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pw",
          "name": "pw",
          "type": "enum_value",
          "integer_value": 177,
          "attributes": {
            "flag": "🇵🇼",
            "display_name": "Palau"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pg",
          "name": "pg",
          "type": "enum_value",
          "integer_value": 178,
          "attributes": {
            "flag": "🇵🇬",
            "display_name": "Papua New Guinea"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pl",
          "name": "pl",
          "type": "enum_value",
          "integer_value": 179,
          "attributes": {
            "flag": "🇵🇱",
            "display_name": "Poland"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pr",
          "name": "pr",
          "type": "enum_value",
          "integer_value": 180,
          "attributes": {
            "flag": "🇵🇷",
            "display_name": "Puerto Rico"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Kp",
          "name": "kp",
          "type": "enum_value",
          "integer_value": 181,
          "attributes": {
            "flag": "🇰🇵",
            "display_name": "North Korea"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pt",
          "name": "pt",
          "type": "enum_value",
          "integer_value": 182,
          "attributes": {
            "flag": "🇵🇹",
            "display_name": "Portugal"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Py",
          "name": "py",
          "type": "enum_value",
          "integer_value": 183,
          "attributes": {
            "flag": "🇵🇾",
            "display_name": "Paraguay"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ps",
          "name": "ps",
          "type": "enum_value",
          "integer_value": 184,
          "attributes": {
            "flag": "🇵🇸",
            "display_name": "Palestine, State of"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pf",
          "name": "pf",
          "type": "enum_value",
          "integer_value": 185,
          "attributes": {
            "flag": "🇵🇫",
            "display_name": "French Polynesia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Qa",
          "name": "qa",
          "type": "enum_value",
          "integer_value": 186,
          "attributes": {
            "flag": "🇶🇦",
            "display_name": "Qatar"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Re",
          "name": "re",
          "type": "enum_value",
          "integer_value": 187,
          "attributes": {
            "flag": "🇷🇪",
            "display_name": "Réunion"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ro",
          "name": "ro",
          "type": "enum_value",
          "integer_value": 188,
          "attributes": {
            "flag": "🇷🇴",
            "display_name": "Romania"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ru",
          "name": "ru",
          "type": "enum_value",
          "integer_value": 189,
          "attributes": {
            "flag": "🇷🇺",
            "display_name": "Russian Federation"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Rw",
          "name": "rw",
          "type": "enum_value",
          "integer_value": 190,
          "attributes": {
            "flag": "🇷🇼",
            "display_name": "Rwanda"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sa",
          "name": "sa",
          "type": "enum_value",
          "integer_value": 191,
          "attributes": {
            "flag": "🇸🇦",
            "display_name": "Saudi Arabia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sd",
          "name": "sd",
          "type": "enum_value",
          "integer_value": 192,
          "attributes": {
            "flag": "🇸🇩",
            "display_name": "Sudan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sn",
          "name": "sn",
          "type": "enum_value",
          "integer_value": 193,
          "attributes": {
            "flag": "🇸🇳",
            "display_name": "Senegal"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sg",
          "name": "sg",
          "type": "enum_value",
          "integer_value": 194,
          "attributes": {
            "flag": "🇸🇬",
            "display_name": "Singapore"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Gs",
          "name": "gs",
          "type": "enum_value",
          "integer_value": 195,
          "attributes": {
            "flag": "🇬🇸",
            "display_name": "South Georgia and the South Sandwich Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sh",
          "name": "sh",
          "type": "enum_value",
          "integer_value": 196,
          "attributes": {
            "flag": "🇸🇭",
            "display_name": "Saint Helena, Ascension and Tristan da Cunha"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sj",
          "name": "sj",
          "type": "enum_value",
          "integer_value": 197,
          "attributes": {
            "flag": "🇸🇯",
            "display_name": "Svalbard and Jan Mayen"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sb",
          "name": "sb",
          "type": "enum_value",
          "integer_value": 198,
          "attributes": {
            "flag": "🇸🇧",
            "display_name": "Solomon Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sl",
          "name": "sl",
          "type": "enum_value",
          "integer_value": 199,
          "attributes": {
            "flag": "🇸🇱",
            "display_name": "Sierra Leone"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sv",
          "name": "sv",
          "type": "enum_value",
          "integer_value": 200,
          "attributes": {
            "flag": "🇸🇻",
            "display_name": "El Salvador"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sm",
          "name": "sm",
          "type": "enum_value",
          "integer_value": 201,
          "attributes": {
            "flag": "🇸🇲",
            "display_name": "San Marino"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::So",
          "name": "so",
          "type": "enum_value",
          "integer_value": 202,
          "attributes": {
            "flag": "🇸🇴",
            "display_name": "Somalia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Pm",
          "name": "pm",
          "type": "enum_value",
          "integer_value": 203,
          "attributes": {
            "flag": "🇵🇲",
            "display_name": "Saint Pierre and Miquelon"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Rs",
          "name": "rs",
          "type": "enum_value",
          "integer_value": 204,
          "attributes": {
            "flag": "🇷🇸",
            "display_name": "Serbia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ss",
          "name": "ss",
          "type": "enum_value",
          "integer_value": 205,
          "attributes": {
            "flag": "🇸🇸",
            "display_name": "South Sudan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::St",
          "name": "st",
          "type": "enum_value",
          "integer_value": 206,
          "attributes": {
            "flag": "🇸🇹",
            "display_name": "Sao Tome and Principe"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sr",
          "name": "sr",
          "type": "enum_value",
          "integer_value": 207,
          "attributes": {
            "flag": "🇸🇷",
            "display_name": "Suriname"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sk",
          "name": "sk",
          "type": "enum_value",
          "integer_value": 208,
          "attributes": {
            "flag": "🇸🇰",
            "display_name": "Slovakia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Si",
          "name": "si",
          "type": "enum_value",
          "integer_value": 209,
          "attributes": {
            "flag": "🇸🇮",
            "display_name": "Slovenia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Se",
          "name": "se",
          "type": "enum_value",
          "integer_value": 210,
          "attributes": {
            "flag": "🇸🇪",
            "display_name": "Sweden"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sz",
          "name": "sz",
          "type": "enum_value",
          "integer_value": 211,
          "attributes": {
            "flag": "🇸🇿",
            "display_name": "Eswatini"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sx",
          "name": "sx",
          "type": "enum_value",
          "integer_value": 212,
          "attributes": {
            "flag": "🇸🇽",
            "display_name": "Sint Maarten (Dutch part)"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sc",
          "name": "sc",
          "type": "enum_value",
          "integer_value": 213,
          "attributes": {
            "flag": "🇸🇨",
            "display_name": "Seychelles"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Sy",
          "name": "sy",
          "type": "enum_value",
          "integer_value": 214,
          "attributes": {
            "flag": "🇸🇾",
            "display_name": "Syrian Arab Republic"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tc",
          "name": "tc",
          "type": "enum_value",
          "integer_value": 215,
          "attributes": {
            "flag": "🇹🇨",
            "display_name": "Turks and Caicos Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Td",
          "name": "td",
          "type": "enum_value",
          "integer_value": 216,
          "attributes": {
            "flag": "🇹🇩",
            "display_name": "Chad"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tg",
          "name": "tg",
          "type": "enum_value",
          "integer_value": 217,
          "attributes": {
            "flag": "🇹🇬",
            "display_name": "Togo"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Th",
          "name": "th",
          "type": "enum_value",
          "integer_value": 218,
          "attributes": {
            "flag": "🇹🇭",
            "display_name": "Thailand"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tj",
          "name": "tj",
          "type": "enum_value",
          "integer_value": 219,
          "attributes": {
            "flag": "🇹🇯",
            "display_name": "Tajikistan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tk",
          "name": "tk",
          "type": "enum_value",
          "integer_value": 220,
          "attributes": {
            "flag": "🇹🇰",
            "display_name": "Tokelau"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tm",
          "name": "tm",
          "type": "enum_value",
          "integer_value": 221,
          "attributes": {
            "flag": "🇹🇲",
            "display_name": "Turkmenistan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tl",
          "name": "tl",
          "type": "enum_value",
          "integer_value": 222,
          "attributes": {
            "flag": "🇹🇱",
            "display_name": "Timor-Leste"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::To",
          "name": "to",
          "type": "enum_value",
          "integer_value": 223,
          "attributes": {
            "flag": "🇹🇴",
            "display_name": "Tonga"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tt",
          "name": "tt",
          "type": "enum_value",
          "integer_value": 224,
          "attributes": {
            "flag": "🇹🇹",
            "display_name": "Trinidad and Tobago"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tn",
          "name": "tn",
          "type": "enum_value",
          "integer_value": 225,
          "attributes": {
            "flag": "🇹🇳",
            "display_name": "Tunisia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tr",
          "name": "tr",
          "type": "enum_value",
          "integer_value": 226,
          "attributes": {
            "flag": "🇹🇷",
            "display_name": "Turkey"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tv",
          "name": "tv",
          "type": "enum_value",
          "integer_value": 227,
          "attributes": {
            "flag": "🇹🇻",
            "display_name": "Tuvalu"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tw",
          "name": "tw",
          "type": "enum_value",
          "integer_value": 228,
          "attributes": {
            "flag": "🇹🇼",
            "display_name": "Taiwan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Tz",
          "name": "tz",
          "type": "enum_value",
          "integer_value": 229,
          "attributes": {
            "flag": "🇹🇿",
            "display_name": "Tanzania"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ug",
          "name": "ug",
          "type": "enum_value",
          "integer_value": 230,
          "attributes": {
            "flag": "🇺🇬",
            "display_name": "Uganda"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ua",
          "name": "ua",
          "type": "enum_value",
          "integer_value": 231,
          "attributes": {
            "flag": "🇺🇦",
            "display_name": "Ukraine"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Um",
          "name": "um",
          "type": "enum_value",
          "integer_value": 232,
          "attributes": {
            "flag": "🇺🇲",
            "display_name": "United States Minor Outlying Islands"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Uy",
          "name": "uy",
          "type": "enum_value",
          "integer_value": 233,
          "attributes": {
            "flag": "🇺🇾",
            "display_name": "Uruguay"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Us",
          "name": "us",
          "type": "enum_value",
          "integer_value": 234,
          "attributes": {
            "flag": "🇺🇸",
            "display_name": "United States"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Uz",
          "name": "uz",
          "type": "enum_value",
          "integer_value": 235,
          "attributes": {
            "flag": "🇺🇿",
            "display_name": "Uzbekistan"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Va",
          "name": "va",
          "type": "enum_value",
          "integer_value": 236,
          "attributes": {
            "flag": "🇻🇦",
            "display_name": "Holy See (Vatican City State)"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Vc",
          "name": "vc",
          "type": "enum_value",
          "integer_value": 237,
          "attributes": {
            "flag": "🇻🇨",
            "display_name": "Saint Vincent and the Grenadines"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ve",
          "name": "ve",
          "type": "enum_value",
          "integer_value": 238,
          "attributes": {
            "flag": "🇻🇪",
            "display_name": "Venezuela"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Vg",
          "name": "vg",
          "type": "enum_value",
          "integer_value": 239,
          "attributes": {
            "flag": "🇻🇬",
            "display_name": "Virgin Islands, British"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Vi",
          "name": "vi",
          "type": "enum_value",
          "integer_value": 240,
          "attributes": {
            "flag": "🇻🇮",
            "display_name": "Virgin Islands, U.S."
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Vn",
          "name": "vn",
          "type": "enum_value",
          "integer_value": 241,
          "attributes": {
            "flag": "🇻🇳",
            "display_name": "Vietnam"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Vu",
          "name": "vu",
          "type": "enum_value",
          "integer_value": 242,
          "attributes": {
            "flag": "🇻🇺",
            "display_name": "Vanuatu"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Wf",
          "name": "wf",
          "type": "enum_value",
          "integer_value": 243,
          "attributes": {
            "flag": "🇼🇫",
            "display_name": "Wallis and Futuna"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ws",
          "name": "ws",
          "type": "enum_value",
          "integer_value": 244,
          "attributes": {
            "flag": "🇼🇸",
            "display_name": "Samoa"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Ye",
          "name": "ye",
          "type": "enum_value",
          "integer_value": 245,
          "attributes": {
            "flag": "🇾🇪",
            "display_name": "Yemen"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Za",
          "name": "za",
          "type": "enum_value",
          "integer_value": 246,
          "attributes": {
            "flag": "🇿🇦",
            "display_name": "South Africa"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Zm",
          "name": "zm",
          "type": "enum_value",
          "integer_value": 247,
          "attributes": {
            "flag": "🇿🇲",
            "display_name": "Zambia"
          }
        },
        {
          "source": "Kore::Types::User::Fields::Country::Zw",
          "name": "zw",
          "type": "enum_value",
          "integer_value": 248,
          "attributes": {
            "flag": "🇿🇼",
            "display_name": "Zimbabwe"
          }
        }
      ],
      "values": [
        "aw",
        "af",
        "ao",
        "ai",
        "ax",
        "al",
        "ad",
        "ae",
        "ar",
        "am",
        "as",
        "aq",
        "tf",
        "ag",
        "au",
        "at",
        "az",
        "bi",
        "be",
        "bj",
        "bq",
        "bf",
        "bd",
        "bg",
        "bh",
        "bs",
        "ba",
        "bl",
        "by",
        "bz",
        "bm",
        "bo",
        "br",
        "bb",
        "bn",
        "bt",
        "bv",
        "bw",
        "cf",
        "ca",
        "cc",
        "ch",
        "cl",
        "cn",
        "ci",
        "cm",
        "cd",
        "cg",
        "ck",
        "co",
        "km",
        "cv",
        "cr",
        "cu",
        "cw",
        "cx",
        "ky",
        "cy",
        "cz",
        "de",
        "dj",
        "dm",
        "dk",
        "do",
        "dz",
        "ec",
        "eg",
        "er",
        "eh",
        "es",
        "ee",
        "et",
        "fi",
        "fj",
        "fk",
        "fr",
        "fo",
        "fm",
        "ga",
        "gb",
        "ge",
        "gg",
        "gh",
        "gi",
        "gn",
        "gp",
        "gm",
        "gw",
        "gq",
        "gr",
        "gd",
        "gl",
        "gt",
        "gf",
        "gu",
        "gy",
        "hk",
        "hm",
        "hn",
        "hr",
        "ht",
        "hu",
        "id",
        "im",
        "in",
        "io",
        "ie",
        "ir",
        "iq",
        "is",
        "il",
        "it",
        "jm",
        "je",
        "jo",
        "jp",
        "kz",
        "ke",
        "kg",
        "kh",
        "ki",
        "kn",
        "kr",
        "kw",
        "la",
        "lb",
        "lr",
        "ly",
        "lc",
        "li",
        "lk",
        "ls",
        "lt",
        "lu",
        "lv",
        "mo",
        "mf",
        "ma",
        "mc",
        "md",
        "mg",
        "mv",
        "mx",
        "mh",
        "mk",
        "ml",
        "mt",
        "mm",
        "me",
        "mn",
        "mp",
        "mz",
        "mr",
        "ms",
        "mq",
        "mu",
        "mw",
        "my",
        "yt",
        "na",
        "nc",
        "ne",
        "nf",
        "ng",
        "ni",
        "nu",
        "nl",
        "no",
        "np",
        "nr",
        "nz",
        "om",
        "pk",
        "pa",
        "pn",
        "pe",
        "ph",
        "pw",
        "pg",
        "pl",
        "pr",
        "kp",
        "pt",
        "py",
        "ps",
        "pf",
        "qa",
        "re",
        "ro",
        "ru",
        "rw",
        "sa",
        "sd",
        "sn",
        "sg",
        "gs",
        "sh",
        "sj",
        "sb",
        "sl",
        "sv",
        "sm",
        "so",
        "pm",
        "rs",
        "ss",
        "st",
        "sr",
        "sk",
        "si",
        "se",
        "sz",
        "sx",
        "sc",
        "sy",
        "tc",
        "td",
        "tg",
        "th",
        "tj",
        "tk",
        "tm",
        "tl",
        "to",
        "tt",
        "tn",
        "tr",
        "tv",
        "tw",
        "tz",
        "ug",
        "ua",
        "um",
        "uy",
        "us",
        "uz",
        "va",
        "vc",
        "ve",
        "vg",
        "vi",
        "vn",
        "vu",
        "wf",
        "ws",
        "ye",
        "za",
        "zm",
        "zw"
      ],
      "default": "us"
    },
    "status": {
      "name": "status",
      "source": "User::Fields::Status",
      "type": "enum",
      "choices": [
        {
          "source": "User::Fields::Status::Active",
          "name": "active",
          "type": "enum_value",
          "integer_value": 1,
          "attributes": {
            "label": "Active"
          }
        },
        {
          "source": "User::Fields::Status::Timeout",
          "name": "timeout",
          "type": "enum_value",
          "integer_value": 2,
          "attributes": {
            "label": "On Timeout"
          }
        },
        {
          "source": "User::Fields::Status::Retired",
          "name": "retired",
          "type": "enum_value",
          "integer_value": 3,
          "attributes": {
            "label": "Retired"
          }
        },
        {
          "source": "User::Fields::Status::LookingForTeam",
          "name": "looking_for_team",
          "type": "enum_value",
          "integer_value": 4,
          "attributes": {
            "label": "Looking For Team"
          }
        },
        {
          "source": "User::Fields::Status::Inactive",
          "name": "inactive",
          "type": "enum_value",
          "integer_value": 5,
          "attributes": {
            "label": "Inactive"
          }
        }
      ],
      "values": [
        "active",
        "timeout",
        "retired",
        "looking_for_team",
        "inactive"
      ]
    },
    "region": {
      "name": "region",
      "source": "User::Fields::Region",
      "type": "enum",
      "choices": [
        {
          "source": "User::Fields::Region::Na",
          "name": "na",
          "type": "enum_value",
          "integer_value": 1,
          "attributes": {
            "label": "NA"
          }
        },
        {
          "source": "User::Fields::Region::Eu",
          "name": "eu",
          "type": "enum_value",
          "integer_value": 2,
          "attributes": {
            "label": "EU"
          }
        },
        {
          "source": "User::Fields::Region::Apac",
          "name": "apac",
          "type": "enum_value",
          "integer_value": 3,
          "attributes": {
            "label": "APAC"
          }
        }
      ],
      "values": [
        "na",
        "eu",
        "apac"
      ]
    },
    "roles": {
      "name": "roles",
      "source": "User::Fields::Roles",
      "type": "set"
    }
  }
}