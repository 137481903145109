<template>
  <div class="heading sm:flex sm:items-center sm:justify-between py-8">
    <div class="page-title px-1 font-aldrich hidden sm:block">

    </div>
    <div class="actions flex flex-nowrap space-x-1 justify-end mb-0 font-aldrich uppercase text-color-accent">
      <CollectionSearch :placeholder="'Search Players'" :search="fetchUsers" :recordPath="userShowPath" :image_key="'image_url'" :imageKey="'image_url'"></CollectionSearch>
      <Menu as="span" class="flex relative inline-block text-left">
        <div>
          <MenuButton class=" flex-nowrap menu-button-glass-black-1">
            <span class="uppercase text-sm">Region: {{$parent.region}}
              <ChevronDownIcon class="inline-flex -mr-1 w-5" aria-hidden="true" />
            </span>
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="menu-items-glass-black w-56">
            <div class="py-1">
              <MenuItem v-for="region in regions" :key="region.value" v-slot="{ active }" class="hover-transition-2">
                <a @click="onRegionChange(region.value)" href="#" :class="[active ? '' : ' ', 'block px-4 py-2 text-sm']">{{region.label}}</a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>

      <!-- <Menu as="span" class="flex relative inline-block text-left">
        <div>
          <MenuButton class="flex-nowrap gap-x-0.5 menu-button-glass-black-1">
            <span class="uppercase text-sm">Games:
              <ChevronDownIcon class="inline-flex -mr-1 w-5" aria-hidden="true" />
            </span>
          </MenuButton>
        </div>

        <Transition enter-active-class="transition ease-out duration-500" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="menu-items-glass-black w-33 z-20">
            <div class="py-1">
              <MenuItem v-for="season in seasons" :key="season" v-slot="{ active }" class="hover-transition-2">
                <a @click="onSeasonChange(season)" href="#" :class="[active ? '' : ' ', 'block px-4 py-2 text-sm']">Season: {{season}}</a>
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu> -->

      <div class="flex relative inline-block text-center">
        <div @click="previousPage()" class="flex-nowrap gap-x-0.5 menu-button-glass-black-1 px-1">
          <ChevronLeftIcon class="inline-flex -mr-0 w-5" aria-hidden="true" />
        </div>
      </div>

      <div class="flex relative inline-block text-center">
        <div @click="nextPage()" class="flex-nowrap gap-x-0.5 menu-button-glass-black-1 px-1">
          <ChevronRightIcon class="inline-flex -mr-0 w-5" aria-hidden="true" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import UIComponents from '@/components/ui';
import * as Models from '@/models';

export default {
  name: 'StandingsIndexNavigation',
  components: {
    ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon,
    Menu, MenuButton, MenuItem, MenuItems, ...UIComponents
  },
  props: ['parent', 'pagination', 'onPageChanged'],
  watch: {
  },
  methods: {
    async nextPage() {
      let next_page = (this.pagination.current_page < this.pagination.total_pages) ? this.pagination.current_page + 1 : 1;
      await this.onPageChanged({page: next_page});
    },
    async previousPage() {
      let previous_page = (this.pagination.current_page === 1) ? this.pagination.total_pages : this.pagination.current_page - 1;
      await this.onPageChanged({page: previous_page});
    },
    userShowPath(u) {
      return `/user/${u.handle}/details`
    },
    onRegionChange(region) {
      console.log(region);
      this.$parent.region = region;

      this.$parent.$router.push({
        name: 'StandingsIndexRegionSeason',
        params: {
          slug: this.$parent.$route.params.slug,
          region: region,
          season: 1
        }
      })

      this.$parent.fetch();
    },
    onSeasonChange(season) {
      this.$parent.season = season;

      this.$parent.$router.push({
        name: 'StandingsIndexRegionSeason',
        params: {
          slug: this.$parent.$route.params.slug,
          region: this.$parent.$route.params.region,
          season: season
        }
      })

      this.$parent.fetch();
    },
    async fetchUsers(name) {
      let results = await Models.User.search({
        by_username: name
      })

      return results
    }
  },
  async created() {
    window.standings_nav = this;

    this.regions.push(...[
      {label: 'NA', value: 'na'},
      {label: 'EU', value: 'eu'},
      {label: 'APAC', value: 'apac'},
    ])
  },
  setup() {
    return {
      regions: ref([]),
      seasons: ref([1,2,3])
    }
  }
}
</script>

<style>
</style>
