<template>
  <div class="heading sm:flex sm:items-center sm:justify-between py-8" v-if="is_loaded">
    <div class="page-button" v-if="showJoinLeagueButton()">
      <button @click="joinCurrentLeague()" class="menu-button-glass-black-1 glowing text-shadow-black-sm hover:bg-black">
        <span style="text-shadow: 0px 1px 1px black; color: #fff;" class="uppercase text-md font-aldrich">Join League</span>
      </button>
    </div>

    <StandingsIndexNavigationCountdownTimer v-if="showCountdownTimer()" :season="$parent.league.current_season">

    </StandingsIndexNavigationCountdownTimer>

    <div class="page-title px-1 font-aldrich hidden sm:block">

    </div>
    <div class="actions flex flex-nowrap space-x-1 justify-end mb-0 font-aldrich uppercase text-color-accent">
      <CollectionSearch :placeholder="'Search Teams'" :search="fetchTeams" :recordPath="$parent.teamShowPath" :image_key="'image_url'" :imageKey="'image_url'"></CollectionSearch>

      <Menu v-if="$parent.league.regions.length > 1" as="span" class="flex relative inline-block text-left">
        <div>
          <MenuButton class=" flex-nowrap menu-button-glass-black-1">
            <span class="uppercase text-sm">Region: {{$parent.region}}
              <ChevronDownIcon class="inline-flex -mr-1 w-5 text-glass-white-700" aria-hidden="true" />
            </span>
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="menu-items-glass-black w-56">
            <div class="py-1">
              <MenuItem v-for="region in regions" :key="region.value" v-slot="{ active }" class="hover-transition-2">
                <a @click="onRegionChange(region.value)" href="#" :class="[active ? ' text-white' : ' ', 'block px-4 py-2 text-sm']">{{region.label}}</a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>

      <Menu as="span" class="flex relative inline-block text-left">
        <div>
          <MenuButton class="flex-nowrap gap-x-0.5 menu-button-glass-black-1">
            <span class="uppercase text-sm">Season: {{$parent.season}}
              <ChevronDownIcon class="inline-flex -mr-1 w-5" aria-hidden="true" />
            </span>
          </MenuButton>
        </div>

        <Transition enter-active-class="transition ease-out duration-500" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="menu-items-glass-black w-33 z-20">
            <div class="py-1">
              <MenuItem v-for="season in seasons" :key="season" v-slot="{ active }" class="hover-transition-2">
                <a @click="onSeasonChange(season)" href="#" :class="[active ? 'text-white' : ' ', 'block px-4 py-2 text-sm']">Season: {{season}}</a>
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>

      <div class="flex relative inline-block text-center">
        <div @click="previousPage()" class="flex-nowrap gap-x-0.5 menu-button-glass-black-1 px-1">
          <ChevronLeftIcon class="inline-flex -mr-0 w-5 " aria-hidden="true" />
        </div>
      </div>

      <div class="flex relative inline-block text-center">
        <div @click="nextPage()" class="flex-nowrap gap-x-0.5 menu-button-glass-black-1 px-1">
          <ChevronRightIcon class="inline-flex -mr-0 w-5" aria-hidden="true" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import UIComponents from '@/components/ui';
import * as Models from '@/models';
import StandingsIndexNavigationCountdownTimer from './navigation/_countdown_timer';
import BaseMixin from '@/components/mixins/base_mixin';

const REGIONS = {
  'na': {label: 'North America', value: 'na'},
  'eu': {label: 'EU', value: 'eu'},
  'apac': {label: 'APAC', value: 'apac'}
};

export default {
  name: 'StandingsIndexNavigation',
  components: {
    StandingsIndexNavigationCountdownTimer,
    ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon,
    Menu, MenuButton, MenuItem, MenuItems, ...UIComponents
  },
  mixins: [ BaseMixin ],
  props: ['parent', 'pagination', 'onPageChanged'],
  watch: {
  },
  methods: {
    async nextPage() {
      let next_page = (this.pagination.current_page < this.pagination.total_pages) ? this.pagination.current_page + 1 : 1;
      this.$$updateQuery({page: next_page});
    },
    async previousPage() {
      let previous_page = (this.pagination.current_page === 1) ? this.pagination.total_pages : this.pagination.current_page - 1;
      this.$$updateQuery({page: previous_page});
    },
    goToTeam(id) {
      this.$parent.$router.push({
        name: 'TeamShow',
        params: {
          slug: this.$parent.$route.params.slug,
          region: region,
          season: 1
        }
      })
    },
    onRegionChange(region) {
      this.$parent.region = region;
      this.$$goTo('StandingsIndexRegionSeason', {region: region, season: 1}, {page: 1});
    },
    onSeasonChange(season) {
      this.$parent.season = season;
      this.$$goTo('StandingsIndexRegionSeason', {season: season}, {page: 1});
    },
    async fetchTeams(name) {
      let results = await Models.Team.search({
        by_league_id: this.$parent.league.id,
        by_name: name
      })

      return results;
    },
    joinCurrentLeague() {
      this.$router.push({ name: 'MyLeagues', query: { join_league_id: this.$parent.league.id } });
    },
    showJoinLeagueButton() {
      if(!this.$root.current_user.signed_in || !this.$parent.league.is_active) { return false }
      return !_.includes(this.$root.current_user.league_ids, this.$parent.league.id);
    },
    showCountdownTimer() {
      return false;
      // return this.$parent.league.current_season.status === 'preseason' && this.$parent.league.current_season.started_at.length > 0;
    }
  },
  async created() {
    window.nnn = this;
    this.seasons = _.map(this.$parent.league.seasons, 'number');

    _.each(this.$parent.league.regions, (r) => {
      this.regions.push(REGIONS[r]);
    })

    this.is_loaded = true;
  },
  setup() {
    return {
      is_loaded: ref(false),
      regions: ref([]),
      seasons: ref([])
    }
  }
}
</script>

<style>
.nested-menu {
  .background: #fff;
}
</style>
