<template>
  <div class="transition-page-inner-wrapper">
    <div v-if="is_loaded">
      <UserShowStats :title="'Worldwide XRL League Stats'" :rating="parent.rating" />
      <div class="section">
        <div class="section-header-1">Current Teams</div>
        <ul role="list" class="grid grid-cols-4 gap-3 sm:grid-cols-4">
          <UserShowTeamCard v-for="team in parent.teams" :key="team.id" :team="team" :league="leagueFor(team)" />
        </ul>
      </div>

      <template v-for="league_rating in parent.league_ratings" :key="league_rating.rating.id">
        <UserShowStats :image_src="$root.logoFor(league_rating.game.attributes.slug)" :title="league_rating.league.name+' Player Stats (Worldwide)'" :rating="league_rating.rating" :url="rootLeaguePathFor(league_rating.league.slug)" />
      </template>
    </div>
    <div v-else>
      Loading...
    </div>

    <!-- <UserShowStats :title="'All Time '+$root.league.name + ' (Global) Player Stats'" :rating="parent.rating" /> -->
    <!-- <UserShowStats v-if="parent.team_rating"
                        :showMatchHistory="false"
                        :title="'Current Team ('+ parent.team_rating.team.name + ') Stats'"
                        :rating="parent.team_rating" />
    <UserShowTeamHistory :teamRatings="parent.team_ratings" /> -->
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import UserShowStats from '@/components/players/show/_stats';
import UserShowTeamHistory from './_team_history';
import UserShowTeamCard from './_team_card';
import TeamShowSeasonData from '@/components/team/show/_season_data';
import Mixins from '@/components/mixins';

export default {
  name: 'UserShowDetails',
  mixins: _.values(Mixins),
  components: {
    UserShowTeamCard,
    UserShowStats,
    TeamShowSeasonData,
    UserShowTeamHistory
  },
  props: ['parent'],
  methods: {
    leagueFor(team) {
      return this.$root.findLeagueById(team.league_id);
    }
  },
  async created() {
    window.user_show_details = this;
    this.is_loaded = true;
  },

  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      teams: ref([]),
      user: ref({}),
      player: ref({}),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
</style>
