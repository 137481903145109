<template>
  <router-link as="a" :to="teamPathFor(match.home_team.id)" class="home_team relative flex items-center space-x-2 rounded-lg border border-gray-300 bg-glass-white-500 px-4 py-4 shadow-sm hover:border-gray-400 hover-transition-2">
    <div class="flex-shrink-0 mr-1">
      <img class="h-10 w-10 rounded-full" :src="match.home_team.image_url" alt="" />
    </div>
    <div class="min-w-0 flex-1">
      <span>
        <span class="absolute inset-0" aria-hidden="true" />
        <p class="text-sm font-medium text-gray-900">{{ match.home_team.name }}</p>
        <!-- <p class="outcome-text truncate text-sm">
          {{ isHomeTeamWinner(match) ? 'Win' : 'Loss' }}
          {{ isHomeTeamWinner(match) ? ` by ${match.result.differential}` : '' }}
        </p> -->
      </span>
    </div>
  </router-link>

  <div class=" flex items-center justify-center gap-8">
    <div class="match-date font-semibold text-gray-800 p-4 bg-white/20 rounded shadow">
      <div class="month text-xl">
        <span class="mr-1">{{ match.scheduled_at ? parseMatchDate(match.scheduled_at)['month'] : "TBD"}} </span>
        <span>{{ match.scheduled_at ? parseMatchDate(match.scheduled_at)['day'] : ""}}</span>
        <!-- <ArrowTopRightOnSquareIcon class=" w-6 h-6 inline-block open-arrow" /> -->
      </div>

      <!-- <div class="year text-xs uppercase text-center">
        {{parseMatchDate(match.played_at || match.created_at)['year']}}
      </div> -->
    </div>

  </div>

  <router-link as="a" :to="teamPathFor(match.away_team.id)" class="away_team relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-glass-white-500 px-6 py-5 shadow-sm hover:border-gray-400 hover-transition-2">
    <div class="flex-shrink-0">
      <img class="h-10 w-10 rounded-full" :src="match.away_team.image_url" alt="" />
    </div>
    <div class="details min-w-0 flex-1">
      <span>
        <span class="absolute inset-0" aria-hidden="true" />
        <p class="text-sm font-medium text-gray-900">{{ match.away_team.name }}</p>
      </span>
    </div>
  </router-link>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Subscriptions } from '@/lib/subscriptions';
import { formatDate } from '@/lib/template_helpers';
import { InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon } from '@heroicons/vue/24/solid';
import * as TemplateHelpers from '@/lib/template_helpers';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';

export default {
  name: 'TeamShowUpcomingMatch',
  components: {
    InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon
  },
  props: ['parent', 'match'],
  mixins: [TemplateHelpersMixin],
  methods: {
    parseMatchDate(date) {
      return formatDate(date);
    }
  },
  async created() {
    window.completed_match = this;

    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      team: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
.home_team, .away_team {
  @apply font-aldrich;
}
</style>
