<template>
  <Spinner v-if="!is_loaded"></Spinner>

  <div v-if="is_loaded" class="h-max-content flow-root backdrop-blur-md px-4 -mt-8 text-shadow-lg flex-grow team-show">
    <UserShowFormPopout v-if="form_visible" :close="closeForm" />

    <UserShowHeading :user="user" showEditButton="true" />

    <div class="tab-navigation-wrapper mb-4 font-agency text-lg">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <nav class="border-b border-gray-500 pb-2 -mb-px flex space-x-12" aria-label="Tabs">
          <router-link v-for="tab in tabs" :key="tab.name" :to="tab.to" class=" hover-transition-3 px-4" :class="[isCurrentTab(tab) ? 'border-secondary text-glass-black-800 rounded-md bg-glass-white-400' : 'border-transparent text-glass-white-600 rounded-md item-hover-2', 'group inline-flex items-center border-b-1 py-2 text-xl uppercase font-medium']">
            <component :is="tab.icon" :class="[isCurrentTab(tab) ? 'text-gray-800' : 'text-gray-200 text-shadow-sm-black-inset group-hover:text-gray-100', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
            <span>{{ tab.name }}</span>
          </router-link>
        </nav>
      </div>
    </div>

    <div class="tab-wrapper transition-page-wrapper pb-4">
      <Transition name="page-transition-2" mode="in-out">
        <router-view :parent="this" :key="route_key" />
      </Transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import UserShowDetails from './show/details';
import UIComponents from '@/components/ui';
import Mixins from '@/components/mixins';
import { InformationCircleIcon, UserGroupIcon, ArrowTrendingUpIcon, GlobeAltIcon, ClipboardIcon, FilmIcon, PencilIcon } from '@heroicons/vue/24/solid'
import { sortAndPrepareMatchesBySeasons, sortAndPrepareRatingsBySeasons } from '@/lib/helpers';
import UserShowFormPopout from './show/_form_popout';
import UserShowSocialButtons from './show/_social_buttons';
import UserShowHeading from './show/_heading';

export default {
  name: 'UserShow',
  mixins: _.values(Mixins),
  components: {
    UserShowDetails, UserShowFormPopout, UserShowHeading,
    InformationCircleIcon, UserGroupIcon, ArrowTrendingUpIcon,
    PencilIcon, GlobeAltIcon, ClipboardIcon, FilmIcon, UserShowSocialButtons,
    ...UIComponents
  },
  props: ['league'],
  methods: {
    isCurrentTab(tab) {
      return tab.to.path === this.$route.path.split("/").pop();
    },
    closeForm() {
      console.log('should be closing form', this);
      this.form_visible = false;
    },
    openForm() {
      this.form_visible = true;
    },
    async initializeComponent() {
      this.is_loaded = false;

      this.tabs.push(...[
        { name: `Details / Stats`, icon: InformationCircleIcon, to: { name: 'UserShowDetails', path: 'details'} },
        // { name: 'Stats / All Leagues', icon: GlobeAltIcon, to: { name: 'UserShowLeagues', path: 'leagues'} },
        { name: 'Clips', icon: FilmIcon, to: { name: 'UserShowClips', path: 'clips'} }
      ]);

      window.user_show = this;
      let users = await Models.User.search({by_handle: this.$route.params.handle});
      this.user = users[0];
      this.record = this.user;
      let ratings = await Models.UserRating.search({by_user_id: this.user.id});
      this.rating = ratings[0];
      this.player_ratings = await Models.PlayerRating.search({by_user_id: this.user.id});

      this.league_ratings = _.map(this.player_ratings, (r) => {
        let league = _.find(this.$root.leagues, {id: r.league_id});
        return {game: league.game, league: league, rating: r};
      })

      this.players = await Models.Player.search({ by_user_id: this.user.id });
      this.teams = await Models.Team.search({ by_user_id: this.user.id });

      // this.grouped_teams = _.groupBy(this.teams, 'league_id');
      // this.grouped_player_ratings = _.groupBy(this.global_player_ratings, 'league_id');

      // debugger;

      // if(this.player.team_id) {
      //   let team_ratings = await Models.Rating.search({
      //     by_team_id: this.player.team_id
      //   })
      //
      //   this.team_rating = team_ratings[0];
      // } else {
      //   this.team_rating = false;
      // }
      //
      // if(this.player.team_ids.length > 0) {
      //   this.team_ratings = await Models.Rating.search({
      //     by_team_ids: this.player.team_ids
      //   })
      //
      //   this.team_ratings = sortAndPrepareRatingsBySeasons(this.team_ratings, this.$root.league.ordered_seasons);
      // }

      this.is_loaded = true;
    },
    isCurrentUser() {
      return this.$root.current_user.signed_in && this.user.id === this.$root.current_user.id;
    },
    goToUrl(url) {
      window.open(url, "_blank");
    },
    async copyDiscord() {
      await this.copyToClipboard(this.user.discord_name || 'unknown_discord');
    },
    async copyXrl() {
      await this.copyToClipboard(`https://${window.location.host}${this.$route.fullPath}`, 'XRL Profile link copied to clipboard');
    },
    $$reload() {
      this.route_key +=1;
    }
  },
  async created() {
    window.user_show = this;
    await this.initializeComponent();
  },
  mounted() {
    console.log(this.$route.params.slug);
  },
  watch: {
    async '$route.params.id'(to, from) {
      if(to) {
        await this.initializeComponent();
      }
    }
  },
  setup() {
    return {
      league_ratings: ref([]),
      record: ref({}),
      stats: ref([]),
      user: ref({}),
      teams: ref([]),
      player_rating: ref({}),
      team_ratings: ref([]),
      team_rating: ref({}),
      is_loaded: ref(false),
      tabs: ref([]),
      form_visible: ref(false),
      route_key: ref(0)
    }
  }
}
</script>

<style>
.transition-page-wrapper {
  transform-style: preserve-3d;
}
</style>
