import {BaseModel, Model} from './base_model';

@Model({
  type: 'team',
  url: 'api/front/teams',
  attributes: {
    name: {},
    placeholder_logo_url: {},
    creator_id: {},
    captain_id: {},
    co_captain_id: {},
    image_url: {},
    league_id: {},
    region: { default_value: 'na' },
    status: {}
  },
  belongs_to: {
    league: { type: 'league' }
  },
  has_one: {
    rating: { type: 'rating' }
  }
})

export class Team extends BaseModel {
  get image_url() {
    return `${this.placeholder_logo_url}`
  }
}
