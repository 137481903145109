<template>
  <Popover class="relative">
    <PopoverButton @click="showDatePicker()" :class="buttonClass">
      <CalendarDaysIcon class="h-5 w-5 mr-2"></CalendarDaysIcon>
      Schedule Match
    </PopoverButton>

    <transition
    enter-active-class="transition duration-200 ease-out"
    enter-from-class="translate-y-1 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition duration-150 ease-in"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel v-if="is_visible" class="absolute left-1/2 z-10 mt-3 w-content max-w-sm -translate-x-2/3 transform mr-18 px-4 sm:px-0 lg:max-w-3xl ">
        <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 mb-8">
          <div class="relative bg-white p-2 backdrop-blur-md">
            <v-date-picker :transparent="'true'" :trim-weeks="true" mode="datetime" :min-date="minDate" :max-date="maxDate" v-model="selectedDate" :minute-increment="15" />
            <button class="ml-2 mt-2" style="margin-left:0px;" :class="buttonClass"  @click="confirmDate(selectedDate)">Confirm Date / Time</button>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
 /* eslint-disable */
import { ref } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { CalendarDaysIcon } from '@heroicons/vue/20/solid';
export default {
  name: 'DateTimePicker',
  components: {
    Popover, PopoverButton, PopoverPanel, CalendarDaysIcon
  },
  props: {
    mode: String,
    text: String,
    buttonClass: String,
    onConfirm: Function,
    record: Object,
    property: String
  },
  created() {
    if(this.mode === "current_week") {
      this.minDate = this.startOfWeek();
      this.maxDate = this.endOfWeek();
    }
  },
  setup() {
    return {
      is_visible: ref(false),
      selectedDate: ref(null),
      minDate: ref(null),
      maxDate: ref(null)
    }
  },
  methods: {
    showDatePicker() {
      this.is_visible = true;
    },
    async confirmDate(val) {
      await this.onConfirm(val);
      this.is_visible = false;
    },
    startOfWeek() {
      let now = new Date();
      let dayOfWeek = now.getUTCDay(); // Sunday - 0, Monday - 1, etc.
      let monday = new Date(now);
      monday.setUTCDate(now.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Adjust to Monday
      monday.setUTCHours(18, 0, 0, 0); // Set to 11 AM MST (18 UTC)
      return monday;
    },
    endOfWeek() {
      let now = new Date();
      let dayOfWeek = now.getUTCDay(); // Sunday - 0, Monday - 1, etc.
      let sunday = new Date(now);
      sunday.setUTCDate(now.getUTCDate() - dayOfWeek + 7); // Adjust to next Sunday
      sunday.setUTCHours(19, 0, 0, 0); // Set to 12 PM MST (19 UTC)
      return sunday;
    },
  }

}
</script>
