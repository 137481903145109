/* eslint-disable */
import {BaseModel, Model} from './base_model';
import _ from 'lodash';

@Model({
  type: 'match',
  url: 'api/front/matches',
  attributes: {
    name: {},
    slug: {},
    played_at: {},
    status: {},
    scheduled_at: {}
  },
  belongs_to: {
    home_team: { type: 'Team' },
    away_team: { type: 'Team' }
  },
  has_one: {
    result: { type: 'MatchResult' }
  }
})
export class Match extends BaseModel {
  //hack for now to get confirmation working
  canUserConfirm(user) {
    console.log(this.$$relations.result);
    return !_.isUndefined(_.get(this.$$relations, 'result.data.id'));
    // return false;
    // return this.result !== undefined;
  }
}
