<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-glass-black-500 backdrop-blur-sm transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex justify-center p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform rounded-lg bg-glass-white-200 top-20 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-sm sm:p-6 h-auto">
              <Combobox as="div" v-model="selected">
                <div class="relative">
                  <ComboboxInput :placeholder="placeholder" class="placeholder-glass-white-400 w-full rounded-lg border-0 bg-glass-black-500 py-2 pl-3 pr-12 text-glass-white-500 shadow-md focus:ring-offset-glass-black-50 ring-1 ring-inset ring-glass-white-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-glass-black-200 sm:text-sm sm:leading-6" @change="throttledFetch($event.target.value)" :display-value="(record) => record?.name" />
                  <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <MagnifyingGlassIcon class="h-5 w-5 text-glass-white-500" aria-hidden="true" />
                  </ComboboxButton>

                  <ComboboxOptions v-if="records.length > 0" class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-glass-black-300 backdrop-blur-sm py-1 text-glass-white-500 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm divide-y divide-glass-black-50">
                    <ComboboxOption v-for="record in records" :key="record.id" :value="record" as="template" v-slot="{ active, selected }">
                      <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-secondary bg-opacity-80 text-glass-white-800 hover-transition-1' : 'text-glass-white-600']">
                        <router-link as="div" :to="recordPath(record.id)" class="flex items-center">
                          <img v-if="image_key" :src="record[image_key]" alt="" class="h-6 w-6 flex-shrink-0 rounded-full" />
                          <span :class="['ml-3 truncate', selected && 'font-semibold']">
                            {{ record.name }}
                          </span>
                        </router-link>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>

                  <!-- <ComboboxOptions v-if="records.length > 0" class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-glass-white-200 backdrop-blur-sm py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="record in records" :key="record.id" :value="record" as="template" v-slot="{ active, selected }">
                      <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-primary bg-opacity-50 text-white hover-transition-1' : 'text-gray-800']">
                        <router-link as="div" :to="recordPath(record.id)" class="flex items-center">
                          <img v-if="image_key" :src="record[image_key]" alt="" class="h-6 w-6 flex-shrink-0 rounded-full" />
                          <span :class="['ml-3 truncate', selected && 'font-semibold']">
                            {{ record.name }}
                          </span>
                        </router-link>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions> -->
                </div>
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <div class="menu-button-glass-black-1 backdrop-blur-md py-1">
    <MagnifyingGlassIcon @click="toggle()" class="h-6 w-6 cursor-pointer hover:text-white" aria-hidden="true" />
  </div>

  <!-- <Combobox as="div" v-model="selected">
    <div class="relative">
      <ComboboxInput :placeholder="placeholder" class=" placeholder-glass-accent-500 w-full rounded-md border-0 bg-glass-secondary-500 py-1 pl-3 pr-12 text-accent shadow-sm focus:ring-offset-white/50 ring-1 ring-inset ring-glass-secondary-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6" @change="throttledFetch($event.target.value)" :display-value="(record) => record?.name" />
      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <MagnifyingGlassIcon class="h-5 w-5 text-glass-accent-900" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions v-if="records.length > 0" class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        <ComboboxOption v-for="record in records" :key="record.id" :value="record" as="template" v-slot="{ active, selected }">
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
            <router-link as="div" :to="recordPath(record.id)" class="flex items-center">
              <img v-if="image_key" :src="record[image_key]" alt="" class="h-6 w-6 flex-shrink-0 rounded-full" />
              <span :class="['ml-3 truncate', selected && 'font-semibold']">
                {{ record.name }}
              </span>
            </router-link>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox> -->
</template>

<script>
 /* eslint-disable */
import { ref, computed } from 'vue';
import _ from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
  Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot
} from '@headlessui/vue'

export default {
  name: 'CollectionSearch',
  components: {
    ChevronLeftIcon, ChevronRightIcon, MagnifyingGlassIcon,
    CheckIcon, ChevronUpDownIcon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, XMarkIcon
  },
  props: ['search', 'image_key', 'goToRecord', 'recordPath', 'placeholder'],
  methods: {
    async fetch(value) {
      if(value.length > 2) {
        this.records = await this.search(value);
        if(!this.records) { this.records = [] }
      } else {
        this.records.length = 0;
      }
    },
    toggle() {
      this.open = !this.open;
    }
  },
  async created() {
    this.throttledFetch = _.debounce(this.fetch, 250);
  },
  setup() {
    return {
      records: ref([]),
      selected: ref({}),
      open: ref(false)
    }
  }
}
</script>

<style>
</style>
