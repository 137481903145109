/* eslint-disable */
import _ from 'lodash';

// to handle a2, otherwise it puts a dash
export function parameterize(s) {
  let parameterized = s.replace(/[^a-zA-Z0-9]+/g, '-');

  parameterized = parameterized.replace(/([A-Z])/g, (match, p1, offset) => {
    return (offset > 0 ? '-' : '') + match.toLowerCase();
  });

  return parameterized.replace(/^-+|-+$/g, '');
}

// export function parameterize(s) {
//   return _.snakeCase(s).split('_').join('-');
// }

export function isUndefinedOrNull(val) {
  return _.isUndefined(val) || _.isNull(val);
}

export function compactObject(obj) {
  return _.omitBy(obj, _.isUndefined);
}

export function sortAndPrepareMatchesBySeasons(matches, seasons) {
  let grouped_matches = _.groupBy(matches, "season_id");
  seasons = _.sortBy(seasons, "number");
  let result = {}

  _.each(seasons, (season) => {
    if(grouped_matches[season.id]) {
      result[season.number] = _.reverse(_.sortBy(grouped_matches[season.id], 'scheduled_at'));
    }
  })

  return result;
}

export function sortAndPrepareRatingsBySeasons(ratings, seasons) {
  let grouped_ratings = _.groupBy(ratings, "season_id");
  seasons = _.sortBy(seasons, "number");
  let result = {}

  _.each(seasons, (season) => {
    if(grouped_ratings[season.id]) {
      result[season.number] = grouped_ratings[season.id][0];
    }
  })

  return result;
}

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}
_.mixin({ isObjectEmpty });

function withoutEmptyObjects(a=[]) {
  return _.filter(a, (o) => { return !isObjectEmpty(o) });
}
_.mixin({ withoutEmptyObjects });

function findMostFrequentValue(items_array, property) {
  const grouped_items = _.groupBy(items_array, property);

  const item_counts = _.map(grouped_items, (items, value) => ({
    [property]: value,
    count: items.length
  }));

  return _.maxBy(item_counts, 'count')[property];
}

_.mixin({ findMostFrequentValue });
