<template>
  <div class="transition-page-inner-wrapper" v-if="is_loaded">
    <PlayerShowStats :title="'Worldwide XRL League Stats'" :rating="rating" />
    <template v-for="league_rating in league_ratings" :key="league_rating.rating.id">
      <PlayerShowStats :image_src="$root.logoFor(league_rating.game.attributes.slug)" :title="league_rating.league.name+' Player Stats (Worldwide)'" :rating="league_rating.rating" :url="rootLeaguePathFor(league_rating.league.slug)" />
    </template>

    <!-- <PlayerShowStats v-if="parent.team_rating"
                        :showMatchHistory="false"
                        :title="'Current Team ('+ parent.team_rating.team.name + ') Stats'"
                        :rating="parent.team_rating" />
    <PlayerShowTeamHistory :teamRatings="parent.team_ratings" /> -->
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import PlayerShowStats from './_stats';
import PlayerShowTeamHistory from './_team_history';
import TeamShowSeasonData from '@/components/team/show/_season_data';
import TemplateHelpers from '@/components/mixins/template_helpers';

export default {
  name: 'PlayerShowLeagues',
  components: {
    PlayerShowStats,
    TeamShowSeasonData,
    PlayerShowTeamHistory
  },
  mixins: [TemplateHelpers],
  props: ['parent'],
  methods: {
  },
  async created() {
    let ratings = await Models.UserRating.search({by_user_id: this.parent.player.user_id});
    this.rating = ratings[0];
    this.player_ratings = await Models.PlayerRating.search({by_user_id: this.parent.player.user_id});

    this.league_ratings = _.map(this.player_ratings, (r) => {
      let league = _.find(this.$root.leagues, {id: r.league_id});
      return {game: league.game, league: league, rating: r};
    })

    window.player_show_leagues = this;
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      rating: ref({}),
      player: ref([]),
      league_ratings: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
.team-show {

}
</style>
