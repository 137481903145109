<template>
  <div class="hexagon-container flex items-center justify-center" :style="{ width: computedWidth + 'px', height: computedHeight + 'px' }">
    <svg :style="{ width: computedWidth + 'px', height: computedHeight + 'px' }" :class="svgClass" fill="none" :stroke="color" :stroke-width="edgeSize" role="img" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <path class="hexagon-path" d="M50 5 L90 25 L90 75 L50 95 L10 75 L10 25 Z" />
    </svg>
    <div class="hexagon-content flex items-center justify-center" :style="{ color: color }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HollowHexagon',
  props: {
    svgClass: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'black',
    },
    edgeSize: {
      type: Number,
      default: 2,
    },
    size: {
      type: Number,
      default: 100, // Default size of the SVG
    },
  },
  computed: {
    computedWidth() {
      return this.size;
    },
    computedHeight() {
      return this.size * Math.sqrt(3) / 2;
    },
  },
};
</script>

<style>
.hexagon-container {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.hexagon-content {
  position: absolute;
  text-align: center;
  width: 100%;
}
</style>
