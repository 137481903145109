<template>
  <div :class="container_class" class="popover-container" ref="container">
    <div ref="button" class="popover-button" @click="togglePopover">
      <slot name="button"></slot>
    </div>

    <transition name="fade">
      <div ref="popover" class="z-20 popover-content bg-whiteout" v-show="show" :class="{'hidden': !show}">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
  name: 'Popover',
  props: {
    container_class: String,
    position: {
      type: String,
      default: 'top'
    }
  },
  data() {
    return {
      show: false,
      popperInstance: null,
    };
  },
  created() {
  },
  methods: {
    togglePopover() {
      this.show = !this.show;

      this.$nextTick(() => {
        if (this.show) {
          this.createPopperInstance();
        } else {
          this.destroyPopperInstance();
        }
      });
    },
    createPopperInstance() {
      if (this.popperInstance) {
        this.popperInstance.destroy();
      }

      this.popperInstance = createPopper(this.$refs.button, this.$refs.popover, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            },
          },
        ],
      });
    },
    destroyPopperInstance() {
      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
    },
    handleClickOutside(event) {
      if (!this.$refs.container.contains(event.target)) {
        this.show = false;
        this.destroyPopperInstance();
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    this.destroyPopperInstance();
  },
};
</script>

<style scoped>
.popover-button {
  cursor: pointer;
}

.popover-content {
  border-radius: 4px;
  padding: 0px;
  z-index: 100;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-out;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>
