<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-20" @close="handleClose">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm transition-opacity"></div>
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-glass-white-600 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 font-aldrich uppercase">{{ title }}</DialogTitle>
                  <div class="mt-2 py-2">
                    <p class="text-md text-gray-500">{{ text }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button @click="confirmAction" class="bg-glass-black-900 button-glass-black-2 bg-glass-black-900 hover-transition-1 inline-flex w-full justify-center sm:ml-3 sm:w-auto sm:text-sm" type="submit">
                  {{confirmText}}
                </button>
                <button type="button" class="mt-3 inline-flex w-full justify-center button-glass-white-1 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm hover-transition-1" @click="handleClose">
                  {{ declineText }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'ConfirmActionModal',
  components: {
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, ExclamationTriangleIcon
  },
  props: {
    text: String,
    title: String,
    confirmText: String,
    declineText: String,
    onConfirm: Function,
    onClose: Function
  },
  setup() {
    return {
      isOpen: ref(true)
    }
  },
  methods: {
    handleClose() {
      if(this.onClose) { this.onClose() }
      this.isOpen = false;
    },
    async confirmAction() {
      if (this.onConfirm) {
        await this.onConfirm();
      }
      this.handleClose();
    }
  }
}
</script>
