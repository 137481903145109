<template>
  <div class="rounded-md  home-page-casts-collection shadow-inner shadow-lg w-1/2 max-w-1/2 min-h-80">
  <!-- <div class="rounded-md bg-glass-white-100/20 backdrop-saturate-200 backdrop-blur-md shadow-inner shadow-lg w-1/2 max-w-1/2"> -->
    <div class="overflow-hidden">
      <div class="py-3 px-4 font-agency uppercase text-lg text-orange-200 text-shadow-black-sm"><RssIcon class="h-8 w-8 -mt-1.5 inline scale-x-[-1]" /> Casts / <span class="text-orange-100">{{title}}</span></div>
      <div class="max-h-80 h-42 overflow-y-scroll text-gray-300 space-y-2 p-3 pt-1 px-4">
        <Cast :cast="cast" v-for="cast in casts" :key="cast.id">
        </Cast>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import Mixins from '@/components/mixins';
import Cast from './cast';
import { RssIcon } from '@heroicons/vue/20/solid';

export default {
  name: 'CastsCollection',
  props: ['title', 'casts'],
  mixins: _.values(Mixins),
  components: {
    Cast,
    RssIcon
  },
  methods: {
  },
  async created() {

  },
  setup() {
    return {
      // casts: ref([]),
      is_loaded: ref(true)
    }
  }
}
</script>

<style>

</style>
