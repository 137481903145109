<template>
  <div class="transition-page-inner-wrapper w-full h-full">
    <div class="tournaments min-h-full flex flow-root backdrop-blur-sm px-4 flex-grow w-full">
      <div v-if="is_loaded" class="space-y-6">
        <section v-for="tournament in $root.league.tournaments" :key="tournament.id">
          <Tournament :tournament="tournament"></Tournament>
        </section>
      </div>

      <div v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import TemplateHelpers from '@/components/mixins/template_helpers';
import Tournament from './index/tournament';

export default {
  name: 'TournamentsIndex',
  components: {
    Tournament
  },
  props: ['parent'],
  methods: {
  },
  async created() {
    // this.tournaments = this.$root.league.tournaments;
    window.tournaments_index = this;
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      tournaments: ref([]),
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
