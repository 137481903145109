
export default {
  'max-value': {
    mounted(el, binding, vnode) {
      vnode;
      el.addEventListener('input', () => {
        let max = binding.value;
        if (el.value > max) {
          el.value = max;
          el.dispatchEvent(new Event('input'));
        }
      });
    }
  }
}
