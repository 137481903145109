<template>
  <div class="transition-page-inner-wrapper">
    <PlayersList :players="parent.players" :team="parent.team" :title="parent.team.name + ' / Roster'" />
    <TeamShowSeasonData v-if="parent.season_ratings[parent.current_season.number]"
                        :title="'Season ' + parent.current_season.number + ' Stats'"
                        :showMatchHistory="true"
                        :season="parent.current_season"
                        :rating="parent.season_ratings[parent.current_season.number]"
                        :matches="parent.season_matches[parent.current_season.number]"
                        :upcomingMatches="parent.upcoming_matches">
    </TeamShowSeasonData>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import TeamShowSeasonData from './_season_data';
import PlayersList from './_players_list';

export default {
  name: 'TeamShowDetails',
  components: {
    TeamShowSeasonData,
    PlayersList
  },
  props: ['parent'],
  methods: {
    playerRole(player) {
      if(this.team.captain_id === player.id) {
        return "Captain"
      } else if(this.team.co_captain_id === player.id) {
        return "Co-Captain"
      } else if(player.team_id !== this.team.id) {
        return "Former Player"
      } else {
        return "Player"
      }
    }
  },
  async created() {
    window.team_show_details = this;
    this.is_loaded = true;
  },

  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      team: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
.team-show {

}
</style>
