<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end pt-12">
      <template v-for="item in items" :key="item.id">
        <Component :is="item.type" :heading="item.heading" :body="item.body" :icon="item.icon" :id="item.id" :wrapper_class="item.wrapper_class" :icon_class="item.icon_class"  />
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Subscriptions } from '@/lib/subscriptions';
import { formatDate } from '@/lib/template_helpers';
import * as TemplateHelpers from '@/lib/template_helpers';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';
import BaseMixin from '@/components/mixins/base_mixin';
import Notification from './notifications/notification';

export default {
  name: 'Notifications',
  components: {
    Notification
  },
  props: [],
  mixins: [BaseMixin, TemplateHelpersMixin],
  methods: {
    close(id) {
      let i = _.findIndex(this.items, {id: id});
      if(i > -1) {
        this.items.splice(i, 1);
      }
    },
    showNotification(n) {
      let ttl = n.ttl || 5000,
          id = _.random(1000);

      this.items.push({
        id: id,
        icon: 'InformationCircleIcon',
        type: 'Notification',
        icon_class: 'text-green-400',
        wrapper_class: 'bg-glass-white-700',
        ttl: ttl,
        ...n
      })

      if(ttl > 0) {
        window.setTimeout(() => {
          this.close(id)
        }, ttl);
      }
    }
  },
  async created() {
    window.notifications = this;
    this.$$initialize();

    this.$$on('notification.show', (n) => { this.showNotification(n) });
    this.$$on('notification.error', (n) => {
      console.log('got notification', n);
      this.showNotification({
        icon: 'ExclamationTriangleIcon',
        wrapper_class: 'bg-glass-primary-500 text-white',
        icon_class: 'text-white',
        ttl: 7500,
        ...n
      })
    });

    this.$$on('notification.info', (n) => {
      console.log('got notification', n);
      this.showNotification({
        icon: 'ExclamationTriangleIcon',
        wrapper_class: 'bg-glass-primary-500 text-white',
        icon_class: 'text-white',
        ttl: 7500,
        ...n
      })
    });

    this.$$on('notification.success', (n) => {
      console.log('got notification', n);
      this.showNotification({
        icon: 'CheckCircleIcon',
        wrapper_class: 'bg-green-500 bg-opacity-20 text-white',
        icon_class: 'text-white',
        ttl: 7500,
        ...n
      })
    });

    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false),
      items: ref([])
    }
  }
}
</script>

<style>

</style>
