<template>
  <div id="casts" class="flex justify-between space-x-2 mt-2">
    <CastsCollection :title="'XRL'" :casts="casts['xrl']" />
    <CastsCollection :title="'XRL Community'" :casts="casts['community']" />
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import CastsCollection from './casts/collection';

export default {
  name: 'HomeCasts',
  components: {
    CastsCollection
  },
  async created() {
    Models.Cast.search({by_is_hidden: false, sort_by_starts_at_desc: true, per_page: 10000}).then((casts) => {
      let all_casts = [...casts];
      this.casts = _.groupBy(all_casts, 'cast_source');
      this.casts['xrl'] = (this.casts['xrl'] || []).slice(0,3);
      this.casts['community'] = (this.casts['community'] || []).slice(0,3);
    });
  },
  setup() {
    return {
      casts: ref([]),
      is_loaded: ref(true)
    }
  }
}
</script>

<style>

</style>
