import {BaseModel, Model} from './base_model';

@Model({
  type: 'user_rating',
  url: 'api/front/user_ratings',
  attributes: {
    rating: {},
    rank: {},
    win_count: { default_value: 0 },
    loss_count: { default_value: 0 },
    draw_count: { default_value: 0 },
    season_id: {},
    division: {}
  },
  belongs_to: {
    user: { type: 'user' }
  }
})

export class UserRating extends BaseModel {

}
