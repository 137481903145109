<template>
  <div class="transition-page-inner-wrapper w-full h-full">
    <div class="my-clips min-h-full flex flow-root backdrop-blur-sm px-4 flex-grow w-full">
      <div class="section-header-1 border-b border-glass-white-200 mt-6 min-w-0 flex-1 text-shadow-white-inset font-aldrich flex justify-between items-center">
        <h1 class="truncate text-2xl">My Clips</h1>

        <div class="actions">
          <button @click="addNew()" class="inline-flex items-center menu-button-glass-black-1 py-12 text-lg uppercase">
            <PlusIcon class="h-5 w-5 mr-1" />
            Add Video / Clip
          </button>
        </div>
      </div>

      <TransitionRoot as="template" :show="newRecordVisible()">
        <Dialog as="div" class="relative z-10" @close="new_record = false">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-glass-black-300 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="dialog-1 font-aldrich">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="close-button " @click="new_record = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mt-1 text-center sm:ml-4 sm:text-left">
                      <DialogTitle as="h3" class="text-xl font-semibold leading-6 text-gray-900 uppercase font-aldrich">Add New Clip</DialogTitle>
                      <div class="mt-2 py-12">
                        <div>
                          <label for="title" class="block text-sm font-medium leading-6 text-gray-900">Clip Title (optional)</label>
                          <div class="mt-2">
                            <input type="text" name="title" id="title" v-model="new_record.title" class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Clip title" aria-describedby="title" />
                          </div>
                        </div>

                        <div class="mt-4">
                          <label for="url" class="block text-sm font-medium leading-6 text-gray-900">Clip URL</label>
                          <div class="mt-2">
                            <input type="url" name="url" id="url" v-model="new_record.url" class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="http://my-video-url" aria-describedby="url-description" />
                          </div>
                          <div class="mt-4 text-sm text-gray-800" id="url-description">
                            <div class="text-md mb-1">Supports YouTube, TikTok, Twitch videos.</div>
                            <div>For YouTube, click share and copy the link, it should look like the following url</div>
                            <div><a class="text-blue-500" href="https://youtu.be/H32_yKFZzek?feature=shared" target="_blank">https://youtu.be/H32_yKFZzek?feature=shared</a></div>
                            <div>For twitch, if it's a clip, click more at the top right and then copy link, it should look like the following:</div>
                            <div><a class="text-blue-500" href="https://clips.twitch.tv/BeautifulArtsyPeppermintEagleEye-43JFpKVvnalCtD8g" target="_blank">https://clips.twitch.tv/BeautifulArtsyPeppermintEagleEye-43JFpKVvnalCtD8g</a></div>
                            <div>For TikTok, click the copy link button (don't copy the embed)</div>
                            <div><a class="text-blue-500" href="https://www.tiktok.com/@xr.league/video/7330025899120921902" target="_blank">https://www.tiktok.com/@xr.league/video/7330025899120921902</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-glass-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="save()">Save Clip</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="new_record = false">Cancel</button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <div v-if="clips.length === 0" class="flex black-section-wrapper h-max-content items-center py-40">
        <button @click="addNew()" type="button" class="relative block w-full bg-glass-white-500 rounded-lg border-2 border-dashed border-gray-300 p-24 text-center hover:border-glass-primary-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <FilmIcon class="mx-auto h-12 w-12 text-gray-700" aria-hidden="true" />
          <span class="mt-2 block text-md font-semibold text-gray-900">Add a Youtube, TikTok, or Twitch Video/Clip to be shown on your profile. It can be linked to a match, game, or just a fun VR clip you'd like to showcase! You can share your XRL profile link with your VR friends!</span>
        </button>
      </div>

      <div v-if="is_loaded" class="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 items-center">
        <div v-for="clip in clips" :key="clip.id" class="relative rounded-lg border border-gray-300 bg-glass-white-600 px-6 py-5 shadow-sm hover:border-glass-primary-900">
          <div class="flex items-start justify-between">
            {{clip.title}}
          </div>

          <div class="mt-2">
            <p class="truncate text-sm text-gray-500">
              {{clip.url}}
            </p>
          </div>
        </div>
      </div>

      <div v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot  } from '@headlessui/vue';
import { XMarkIcon, FilmIcon } from '@heroicons/vue/24/outline';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/vue/20/solid';
import Mixins from '@/components/mixins';

export default {
  name: 'MyClips',
  mixins: _.values(Mixins),
  components: {
     Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
     XMarkIcon, EllipsisVerticalIcon, PlusIcon, FilmIcon
  },
  props: ['parent'],
  data() {
    return {
      clips: [],
      is_loaded: false
    }
  },
  methods: {
    addNew() {
      this.new_record = new Models.Clip({
        user_id: this.$root.current_user.id
      });
    },
    newRecordVisible() {
      return !!this.new_record
    },
    async save() {
      try {
        await this.new_record.save();
        this.clips.push(this.new_record);
        this.new_record = false;
      } catch(e) {

      }
    }
  },
  async created() {
    this.ensureCurrentUserLoggedIn();

    this.clips = await Models.Clip.search({
      by_user_id: this.$root.current_user.id
    });
    window.my_clips = this;
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      new_record: ref(false),
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
