<template>
  <tr class="ui-table-1-row team-item">
    <td class="tracking-wider text-grey-100 text-center px-3 py-1.5 font-bold sm:text-2xl md:text-3xl text-shadow-md-black-inset">{{rating.rank}}</td>
    <td :class="rating.division" class="division-bg tracking-wider text-grey-100 text-center px-1 py-1.5 font-bold text-3xl text-shadow-md-black-inset"></td>
    <td class="whitespace-nowrap py-5 px-0 sm:px-1 ">
      <div class="flex items-center">
        <div class="h-8 w-8 sm:h-11 sm:w-11 flex-shrink-0">
          <img loading="lazy" class="h-8 w-8 sm:h-11 sm:w-11 rounded-full" :src="rating.user.image_url" alt="" />
        </div>
        <div class="ml-1 sm:ml-2">
          <router-link :to="userPathFor(rating.user.handle)" class="hover:text-white font-medium">
            {{ rating.user.name }}
          </router-link>
        </div>
      </div>
    </td>
    <td class="whitespace-nowrap px-1 py-5">
      <dl class="flex -space-x-0.5">
      <dd v-for="league_id in this.rating.user.league_ids" :key="league_id">
        <img class="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" :src="gameImageForLeagueId(league_id)" :alt="league_id" />
      </dd>
      </dl>
    </td>
    <td class="whitespace-nowrap px-1 py-5"><span>{{ rating.win_count }} / {{rating.loss_count || 0}}</span></td>
    <td class="whitespace-nowrap px-1 py-5 font-bold">{{rating.rating}}</td>
  </tr>
</template>
<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import UIComponents from '@/components/ui';
import * as Models from '@/models';
import BaseMixin from '@/components/mixins/base_mixin';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';

export default {
  name: 'StandingsIndexItem',
  mixins: [BaseMixin, TemplateHelpersMixin],
  components: {
    ...UIComponents
  },
  props: ['rating'],
  watch: {
  },
  methods: {
  },

  async created() {
    // debugger;
    window.standings_index_item = this;
  },
  setup() {
    return {
    }
  }
}
</script>

<style>

</style>
