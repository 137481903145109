<template>
  <div class="transition-page-inner-wrapper w-full h-full">
    <div class="my-teams min-h-full flex flow-root px-4 flex-grow w-full">
      <div class="section-header-1 border-b border-glass-white-200 mt-6 min-w-0 flex-1 text-shadow-white-inset font-aldrich flex justify-between items-center">
        <h1 class="truncate text-2xl">My Teams</h1>

        <div class="actions">
          <button v-if="can_create_any_teams" @click="addNew()" class="inline-flex items-center menu-button-glass-black-1 py-12 text-lg uppercase">
            <PlusIcon class="h-5 w-5 mr-1" />
            Create Team
          </button>
        </div>
      </div>

      <MyTeamsFormPopout v-if="formVisible()" :record="record" :teams="teams" :players="players" :close="closeForm"  />

      <ul v-if="is_loaded" role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
        <MyTeamsTeam v-for="team in teams" :key="team.id" :team="team" :league="leagueFor(team)" />
      </ul>

      <div v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot  } from '@headlessui/vue';
import { XMarkIcon, UserGroupIcon } from '@heroicons/vue/24/outline';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/vue/20/solid';
import TemplateHelpers from '@/components/mixins/template_helpers';
import MyTeamsFormPopout from './teams/_form_popout';
import MyTeamsTeam from './teams/_team';
import Mixins from '@/components/mixins';

export default {
  name: 'MyTeams',
  mixins: _.values(Mixins),
  components: {
     Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
     XMarkIcon, EllipsisVerticalIcon, PlusIcon, UserGroupIcon, MyTeamsFormPopout, MyTeamsTeam
  },
  props: ['parent'],
  data() {
    return {
      teams: [],
      is_loaded: false
    }
  },
  watch: {
    is_loaded: () => {
      this.$$initializeComponent();
    }
  },
  methods: {
    closeForm() {
      this.record = false;
    },
    addNew() {
      this.record = new Models.Team({is_active: true});

      console.log(this.record);
    },
    leagueFor(team) {
      return this.$root.findLeagueById(team.league_id);
    },
    formVisible() {
      return !!this.record
    },
    async save() {
      try {
        await this.record.save();
        this.teams.push(this.record);
        this.record = false;
      } catch(e) {

      }
    },
    async $$initializeComponent() {
      this.is_loaded = false;
      this.teams = await Models.Team.$search({
        by_user_id: this.$root.current_user.id
      });

      this.players = await Models.Player.$search({
        by_user_id: this.$root.current_user.id
      });

      let team_league_ids = _.map(this.teams, 'league_id');
      let player_league_ids = _.map(this.players, 'league_id');

      this.leagues = _.filter(this.$root.leagues, (l) => {
        return _.includes(player_league_ids, l.id) && !_.includes(team_league_ids, l.id) && !l.is_singles && l.is_active
      })

      this.can_create_any_teams = this.leagues.length > 0;
      this.is_loaded = true;
    }
  },
  async created() {
    window.my_teams = this;
    this.ensureCurrentUserLoggedIn();
    await this.$$initializeComponent();
    window.my_teams = this;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      record: ref(false),
      is_loaded: ref(false),
      can_create_any_teams: ref(false)
    }
  }
}
</script>

<style>

</style>
