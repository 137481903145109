<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <transition enter-active-class="transform ease-out duration-500 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
    <div :class="wrapper_class" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 backdrop-blur-md">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <Component :is="icon" :class="icon_class + ' h-6 w-6'" aria-hidden="true" />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium ">{{heading}}</p>
            <p class="mt-1 text-sm">{{body}}</p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button type="button" @click="$parent.close(this.id)" class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Subscriptions } from '@/lib/subscriptions';
import { formatDate } from '@/lib/template_helpers';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import {
  InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon, CheckCircleIcon, WrenchScrewdriverIcon,
  ExclamationTriangleIcon
} from '@heroicons/vue/24/solid';
import * as TemplateHelpers from '@/lib/template_helpers';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';

export default {
  name: 'Notifications',
  components: {
    InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon, XMarkIcon, CheckCircleIcon, WrenchScrewdriverIcon,
    ExclamationTriangleIcon
  },
  props: ['parent', 'icon', 'heading', 'body', 'id', 'wrapper_class', 'icon_class'],
  mixins: [TemplateHelpersMixin],
  methods: {

  },
  async created() {
    window.notification = this;
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
</style>
