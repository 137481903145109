<template>
  <footer class="home">
    <div class="mx-auto overflow-hidden px-6 py-6 sm:py-4 lg:px-8">
      <div class="mt-8 flex justify-center space-x-14 mb-6">
        <!-- <span class="label text-sm font-agency mt-2 text-gray-400 uppercase">Follow Us On:</span> -->
        <a v-for="item in navigation.social" :key="item.name" :href="item.url" class="p-2 hover-transition-1 rounded-md hover:ring-2 hover:ring-primary/50 hover:ring-rounded-md text-gray-200 hover:text-primary">
          <span class="sr-only">{{ item.name }}</span>
          <component :is="item.icon" class="h-7 w-7" aria-hidden="true" />
        </a>
      </div>

      <nav class="font-agency text-xl uppercase columns-2 sm:flex sm:justify-center sm:space-x-12 mb-2" aria-label="Footer">
        <div v-for="item in navigation.main" :key="item.name" class="">
          <a :href="item.href"
             target="_blank"
             rel="noopener noreferrer"
             class="py-2 px-2 text-sm leading-6 hover-transition-3 text-glass-white-600 hover:text-glass-white-900">
             {{ item.name }}
          </a>
        </div>
      </nav>

      <p class="mt-4 text-center text-xs leading-5 text-glass-white-500 font-aldrich">©2024 XR League</p>
    </div>
  </footer>
</template>

<script setup>
import DiscordIcon from '@/components/icons/discord_icon';
import TwitchIcon from '@/components/icons/twitch_icon';
import YoutubeIcon from '@/components/icons/youtube_icon';
import TiktokIcon from '@/components/icons/tiktok_icon';

const navigation = {
  main: [
    { name: 'About', href: './about' },
    { name: 'Contact Us', href: 'mailto:league@xrl.pro' },
    { name: 'Events', href: './#events' },
    { name: 'Partners', href: './#sponsors' },
  ],
  social: [
    {
      icon: 'DiscordIcon',
      tooltip: 'Discord',
      url: 'https://discord.gg/pQePaGnMSD'
    },
    {
      icon: 'TwitchIcon',
      tooltip: 'Twitch',
      url: 'https://www.twitch.tv/xr_league'
    },
    {
      icon: 'YoutubeIcon',
      tooltip: 'YouTube',
      url: 'https://www.youtube.com/@XR_League'
    },
    {
      icon: 'TiktokIcon',
      tooltip: 'TikTok',
      url: 'https://www.tiktok.com/@xr.league'
    }
  ]
}
</script>

<script>

export default {
  name: 'HomePageFooter',
  components: {
    DiscordIcon,
    TwitchIcon,
    YoutubeIcon,
    TiktokIcon
  },
  async created() {
  }
}
</script>

<style>
.home .footer {
}
</style>
