<template>
  <div class="section-header-1">
    {{title}}
  </div>

  <div class="stats bg-glass-white-500 rounded-lg mb-4 font-aldrich">
    <div class="mx-auto max-w-7xl">
      <div class="grid grid-cols-2 gap-px sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6">
        <div v-for="stat in stats" :key="stat.name" :class="stat.classname" class="px-4 bg-glass-white-100 py-6 sm:px-6 lg:px-8">
          <p class="text-sm font-medium leading-6 text-glass-black-600">{{ stat.name }}</p>
          <p class="mt-2 flex items-baseline gap-x-2">
            <Tooltip v-if="stat.tooltip" :text="stat.tooltip" :position="'bottom'">
              <div style="width: 48px; height: 48px;" class="text-5xl font-semibold tracking-tight text-white text-shadow-sm-black-inset">{{ stat.value }}</div>
            </Tooltip>
            <span v-else class="text-5xl font-semibold tracking-tight text-white text-shadow-sm-black-inset">
              {{ stat.value }}
            </span>
            <span v-if="stat.unit" class="text-sm text-gray-400">{{ stat.unit }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="matches" v-if="upcomingMatches && upcomingMatches.length > 0">
    <div class="section-header-1">
      Upcoming Matches
    </div>

    <div class="match grid grid-cols-3 gap-4 sm:grid-cols-3 mb-3" v-for="match in upcomingMatches" :key="match.id">
      <TeamShowUpcomingMatch :match="match" />
    </div>
  </div>

  <div class="matches" v-if="true">
    <div class="section-header-1">
      Match History
    </div>

    <div class="match grid grid-cols-3 gap-4 sm:grid-cols-3 mb-3" v-for="match in matches" :key="match.id">
      <TeamShowCompletedMatch v-if="match.result" :match="match" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Subscriptions } from '@/lib/subscriptions';
import { formatDate } from '@/lib/template_helpers';
import { InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon } from '@heroicons/vue/24/solid';
import * as TemplateHelpers from '@/lib/template_helpers';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';
import TeamShowCompletedMatch from './_completed_match';
import TeamShowUpcomingMatch from './_upcoming_match';
import UIComponents from '@/components/ui';

const TeamShowSeasonData = {
  name: 'TeamShowSeasonData',
  components: {
    InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon,
    TeamShowCompletedMatch, TeamShowUpcomingMatch, ...UIComponents
  },
  props: ['parent', 'matches', 'upcomingMatches', 'season', 'rating', 'showMatchHistory', 'title'],
  mixins: [TemplateHelpersMixin],
  methods: {
    divisionName(name) {
      let _name = name.split("_")
      return `${_name[0]} ${_name[1]}`
    }
  },
  async created() {
    window.season_data = this;
    this.stats = [
      {name: 'Rank', value: this.rating.rank},
      {name: 'Record', value: `${this.rating.win_count} - ${this.rating.loss_count || 0}`},
      {name: 'Rating', value: `${this.rating.rating}`},
      {name: 'Division', value: ' ', classname: `division-bg ${this.rating.division}`, tooltip: this.divisionName(this.rating.division) },
      {name: 'Points Differential', value: this.rating.differential},
      {name: 'Games Played', value: this.rating.match_count || 0}
    ];

    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      team: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}

export default TeamShowSeasonData;
</script>

<style>
.stats {
  .division-bg {
    background-size: 64px 64px !important;
    background-origin: bottom left;
    background-position: left 18px bottom 16px !important;
  }
}

.team-show {

}
</style>
