<template>
  <button type="button" @click="toggleNotifications" class="relative rounded-full bg-blur-sm bg-glass-white-50 backdrop-saturate-10 p-1 text-glass-white-600 hover:text-glass-white-900 hover:backdrop-saturate-200 focus:outline-none hover:ring-2 hover:ring-primary">
    <span class="absolute -inset-1.5" />
    <span class="sr-only">View notifications</span>
    <BellIcon class="h-6 w-6" aria-hidden="true" />

    <span v-if="notifications.length" class="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
      {{ notifications.length }}
    </span>
  </button>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10 font-aldrich" @close="closePanel">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-glass-white-750 backdrop-blur-sm backdrop-saturate-200 py-6 shadow-xl">
                  <div class="px-4 sm:px-6 pb-6 notifications-title">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-semibold leading-6 text-gray-700 flex items-center font-aldrich uppercase">
                        <BellIcon class="h-6 w-6 mr-2" aria-hidden="true" />
                        My Notifications
                      </DialogTitle>
                      <div class="ml-3 flex h-6 items-center">
                        <button type="button" class="relative rounded-md bg-transparent hover:bg-glass-white-500  hover:ring-glass-black-400 text-gray-400 hover:text-gray-500" @click="closePanel">
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="glass-white-shadow-divider"></div>
                  <div class="relative mt-2 flex-1">
                    <div v-if="notifications.length > 0" class="flex flex-col">
                      <Notification v-for="notification in notifications" :key="notification.id" :notification="notification" class="px-6 glass-white-shadow-divider-3">
                      </Notification>
                    </div>
                    <div v-else class="text-center p-4">
                      No new notifications
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
/* eslint-disable */
import { ref, watch } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import * as Models from '@/models';
import Mixins from '@/components/mixins';
import Notification from './notifications/_notification';

export default {
  name: 'Notifications',
  mixins: _.values(Mixins),
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    XMarkIcon,
    Notification
  },
  methods: {
    closePanel() {
      this.isOpen = false;
    }
  },
  async created() {
    this.notifications = await Models.Notification.fetchAll();
  },
  setup() {
    const isOpen = ref(false);
    const notifications = ref([]);

    const toggleNotifications = async () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value && notifications.value.length === 0) {
        notifications.value = await Models.Notification.fetchAll();
      }
    }

    return { isOpen, notifications, toggleNotifications };
  },
};
</script>
