<template>
<Menu as="div" class="relative ml-4" ref="userMenu">
  <div>
    <MenuButton class="relative flex shadow-md rounded-full bg-white/50 text-sm hover:ring-2 hover:ring-glass-white-100 focus:outline-none hover-transition-1 opacity-80 hover:opacity-100">
      <span class="absolute -inset-1.5" />
      <span class="sr-only">Open user menu</span>
      <img class="h-10 w-10 rounded-full" :src="$root.current_user.image_url" alt="" />
    </MenuButton>
  </div>
  <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
    <MenuItems class="menu-items-glass-black py-1 focus:outline-none tracking-wide divide-y divide-glass-black-50">
      <MenuItem v-for="item in menu" :key="item.href" v-slot="{ active }">
        <a @click="goTo(item.href)" class="cursor-pointer" :class="[active ? 'bg-glass-black-300 text-glass-white-800' : '', 'block px-4 py-2 text-sm ']">{{item.label}}</a>
      </MenuItem>

      <MenuItem v-for="item in admin_menu" :key="item.href" v-slot="{ active }">
        <a :href="item.href" class="cursor-pointer" :class="[active ? 'bg-glass-black-300 text-glass-white-800' : '', 'block px-4 py-2 text-sm ']">{{item.label}}</a>
      </MenuItem>

      <MenuItem>
        <a @click="signOut()" class="sign-out" :class="[active ? '' : '', 'block px-4 py-2 text-sm text-gray-700']">Sign Out</a>
      </MenuItem>
    </MenuItems>
  </transition>
</Menu>
</template>

<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import {
  Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems
} from '@headlessui/vue';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'SignedIn',
  components: {
    Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
    Bars3Icon, BellIcon, XMarkIcon
  },
  methods: {
    async signOut() {
      await this.$root.signOut();
    },
    goTo(path) {
      this.$router.push(path);
    },
  },
  async created() {
    if(_.includes(this.$root.current_user.roles, 'admin')) {
      this.admin_menu.push({ label: 'Admin Panel', href: '/admin' })
    }

    if(_.includes(this.$root.current_user.roles, 'caster')) {
      this.admin_menu.push({ label: 'Caster Admin', href: '/casting' })
    }

    this.menu.push({label: 'My Profile', href: `/user/${this.$root.current_user.handle}/details`});
  },
  setup() {
    return {
      admin_menu: ref([]),
      menu: ref(
        [
          {  label: 'My Leagues', href: '/my/leagues' },
          {  label: 'My Teams', href: '/my/teams' },
          {  label: 'My Matches', href: '/my/matches' },
          {  label: 'My Clips', href: '/my/clips' }
        ]
      )
    }
  }
}
</script>

<style>
.sign-out {
  cursor: pointer;
}
</style>
