<!-- <template>
  <div v-if="isVisible" class="notification fixed top-20 z-40 left-5 bg-secondary/50 backdrop-blur-md rounded-md text-white p-4 font-aldrich">
    <p>{{ message }}</p>
    <button @click="watch" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded font-agency uppercase">
      Watch
    </button>
    <button @click="dismiss" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded  font-agency uppercase">
      Dismiss
    </button>
  </div>
</template> -->

<template>
  <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
    <div v-if="isVisible" class="z-10 top-20 left-4 fixed pointer-events-auto flex w-full max-w-sm divide-x divide-secondary/20 rounded-md bg-secondary/50 backdrop-blur-md shadow-lg ring-1 ring-black ring-opacity-5">
      <div class="flex w-0 flex-1 items-center p-4 rounded-md">
        <div class="w-full ">
          <p class="text-base font-medium text-orange-300 uppercase font-agency">XRL Match Streaming Notification</p>
          <a rel="noopener noreferrer" target="_blank" :href="cast.url" class="mt-1 text-sm text-orange-100 break-words font-aldrich">{{ message }}</a>
        </div>
      </div>
      <div class="flex">
        <div class="flex flex-col divide-y divide-secondary/20">
          <div class="flex h-0 flex-1">
            <button type="button" @click="watch" class="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-bold text-orange-200 hover:text-orange-100 hover:bg-orange-500/90 focus:z-10 focus:outline-none focus:ring-2 focus:ring-secondary/50 font-agency uppercase hover-transition-1">Watch Now</button>
          </div>
          <div class="flex h-0 flex-1">
            <button type="button" @click="dismiss" class="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-bold text-orange-200 hover:text-orange-100 hover:bg-orange-500/90 focus:z-10 focus:outline-none focus:ring-2 focus:ring-secondary/50 font-agency uppercase hover-transition-1">Dismiss</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['cast'],
  data() {
    return {
      isVisible: true,
    };
  },
  computed: {
    message() {
      return this.cast.cached_live_notification;
    },
  },
  methods: {
    watch() {
      window.open(this.cast.url, '_blank');
      this.dismiss();
    },
    dismiss() {
      this.isVisible = false;
      localStorage.setItem(`xrl:seen_cast_${this.cast.id}`, 'true');
    },
    // markAsSeen() {
    //   localStorage.setItem(`xrl:seen_cast_${this.cast.id}`, 'true');
    //   this.$emit('dismissed', this.cast.id);
    // },
  },
};
</script>
