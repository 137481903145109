import {BaseModel, Model} from './base_model';

@Model({
  type: 'rating',
  url: 'api/front/ratings',
  attributes: {
    rating: { default_value: 0 },
    rank: {},
    win_count: { default_value: 0 },
    loss_count: { default_value: 0 },
    draw_count: { default_value: 0 },
    season_id: {},
    division: {}
  },
  has_one: {
    team: { type: 'team' }
  }
})

export class Rating extends BaseModel {
}
