<template>
  <div class="px-6 py-3 sm:px-1 sm:py-10 lg:px-1 home-page-widget">
    <div class="xrllogo"></div>
    <div class="relative mx-auto flex max-w-3xl flex-col items-center text-center">
      <p class="mt-3 text-lg text-white font-aldrich p-4 subtle-orange-to-sky-text-gradient animate-gradient-text">
        XR League offers a unique experience for any virtual, augmented, or mixed reality consumer,
        by providing a premier option to meet with players and play your favorite games.
        We desire to maintain a place for organized play and tournaments,
        as well as giving you or your team the recognition you deserve.
        The XRL leadership team strives to be open and inclusive.  This is your league.
      </p>

      <router-link class="mt-8 block w-full rounded-md border border-transparent glowing-orange-500-to-sky-500 home-page-glass-button px-8 py-3 text-base font-lg text-gray-900 sm:w-auto font-agency uppercase hover-transition-1" to="/about">
        Read our story
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomePageHero',
    async created() {
    }
  }
</script>

<style>
.xrllogo {
  background: url('/public/images/logos/xrllogo.png');
  height: 100px;
  position: inherit;
  z-index: 5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.home .hero {

}
</style>
