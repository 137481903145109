<template>
  <div class="home mt-1 flow-root text-shadow-lg px-2 py-2 backdrop-blur-md h-max-content h-min-screen font-aldrich bg-gradient-to-b from-glass-black-300 to-glass-black-600" v-if="is_loaded">
    <div id="hero" class="border-b border-glass-white-100"> <!-- Hero Section -->
      <AboutHero as="div" />
    </div>
    <div id="xr" class="border-b border-glass-white-100"> <!-- People Section -->
      <AboutXR as="div" />
    </div>
    <div id="people" class="border-b border-glass-white-100"> <!-- People Section -->
      <AboutPeople as="div" />
    </div>
    <div> <!-- Footer Section -->
      <HomeFooter as="div" />
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import AboutHero from './about/hero.vue';
import AboutPeople from './about/people.vue';
import AboutXR from './about/define_xr.vue';
import HomeFooter from './home/footer.vue';

export default {
  name: 'About',
  components: {
    AboutHero,
    AboutPeople,
    AboutXR,
    HomeFooter
  },
  async created() {

  },
  setup() {
    return {
      is_loaded: ref(true)
    }
  }
}
</script>

<style>
.about {

}
</style>
