<template>
  <div class="transition-page-inner-wrapper">
    <PlayerShowStats :title="'All Time '+$root.league.name + ' (Global) Player Stats'" :rating="parent.rating" />
    <PlayerShowStats v-if="parent.team_rating"
                        :showMatchHistory="false"
                        :title="'Current Team ('+ parent.team_rating.team.name + ') Stats'"
                        :rating="parent.team_rating" />
    <PlayerShowTeamHistory :teamRatings="parent.team_ratings" />
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import PlayerShowStats from './_stats';
import PlayerShowTeamHistory from './_team_history';
import TeamShowSeasonData from '@/components/team/show/_season_data';

export default {
  name: 'PlayerShowDetails',
  components: {
    PlayerShowStats,
    TeamShowSeasonData,
    PlayerShowTeamHistory
  },
  props: ['parent'],
  methods: {
  },
  async created() {
    window.player_show_details = this;
    this.is_loaded = true;
  },

  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      player: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
.team-show {

}
</style>
