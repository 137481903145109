<template>
  <div class="transition-page-inner-wrapper">
    <div v-if="is_loaded">
      <clip-component :clip="clip" v-for="clip in clips" :key="clip.id" class="mb-4" />
    </div>
    <div class="py-80" v-else>
      <Spinner></Spinner>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import ClipComponent from '@/components/shared/clip';
import UIComponents from '@/components/ui';
import Mixins from '@/components/mixins';

export default {
  name: 'TeamShowClips',
  mixins: _.values(Mixins),
  components: {
    ClipComponent, ...UIComponents
  },
  props: ['parent'],
  data() {
    return {
      clips: [],
      is_loaded: false
    }
  },
  methods: {
    loadTikTokScript() {
      if (!document.querySelector('script[src="https://www.tiktok.com/embed.js"]')) {
        const script = document.createElement('script');
        script.src = "https://www.tiktok.com/embed.js";
        script.async = true;
        document.body.appendChild(script);
      }
    }
  },
  async created() {
    this.clips = await Models.Clip.search({
      by_team_id: this.$route.params.team_id
    });

    window.team_show_clips = this;
    this.is_loaded = true;
  },
  mounted() {
    this.parentDomain = window.location.hostname;
    this.loadTikTokScript();
  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
</style>
