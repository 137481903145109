/* eslint-disable */
import {BaseModel, Model} from './base_model';
import Schema from '@/constants/schema';
const CountryData = _.keyBy(Schema.User.country.choices, 'name');

@Model({
  type: 'user',
  url: '/api/front/users',
  attributes: {
    'signed_in': { default_value: false },
    'bio': {},
    'nationality': {},
    'name': {},
    'username': {},
    'image_url': {},
    'hero_image_url': { default_value: false },
    'twitch_url': {},
    'tiktok_url': {},
    'youtube_url': {},
    'show_hero_banner': {},
    'country': {},
    'handle': { read_only: true }
  },
  has_many: {
    'players': {type: 'player'}
  }
})

export class User extends BaseModel {
  get country_data() { return CountryData[this.country] }
  get country_flag() {
    if(this.country_data) {
      return this.country_data.attributes.flag;
    }
  }
}
