<template>
  <dl class="flex w-full flex-none justify-between gap-x-8 sm:w-auto" v-if="is_loaded">
    <div class="flex -space-x-0.5">
      <dt class="sr-only">Player History</dt>
      <dd v-for="player in players" :key="player.id">
        <img class="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" :src="player.image_url" :alt="player.name" />
      </dd>
    </div>
    <div class="flex w-16 gap-x-2.5">
      <dt>
        <UserGroupIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
      </dt>
      <dd class="text-sm leading-6 text-gray-900">{{ players.length }}</dd>
    </div>
  </dl>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import * as Models from '@/models';
import { ChevronRightIcon, ArrowLongDownIcon, UserGroupIcon } from '@heroicons/vue/24/solid';
import * as TemplateHelpers from '@/lib/template_helpers';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';

export default {
  name: 'PlayerShowTeamHistoryPlayers',
  components: {
    ChevronRightIcon
  },
  props: ['parent', 'team'],
  mixins: [ TemplateHelpersMixin ],
  methods: {
  },
  async created() {
    window.team_history_players = this;

    this.players = await Models.Player.search({by_team_id: this.team.id});
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      players: ref([]),
      is_loaded: ref(false)
    }
  }
}
</script>

<style>

</style>
