<template>
  <router-link as="a" :to="teamPathFor(match.home_team.id)" :class="isHomeTeamWinner(match) ? 'winner' : 'loser'" class="home_team font-aldrich relative flex items-center space-x-2 rounded-lg border border-gray-300 bg-glass-white-500 px-4 py-4 shadow-sm hover-transition-3">
    <div class="flex-1 flex items-center justify-between">
      <div class="flex items-center">
        <div class="flex-shrink-0 mr-3">
          <img class="h-10 w-10 rounded-full" :src="match.home_team.image_url" alt="" />
        </div>
        <div class="min-w-0">
          <p class="text-sm font-medium text-gray-900">{{ match.home_team.name }}</p>
          <p class="outcome-text truncate text-sm">
            {{ isHomeTeamWinner(match) ? 'Win' : 'Loss' }}
            {{ isHomeTeamWinner(match) ? ` by ${match.result.differential}` : '' }}
          </p>
        </div>
      </div>
      <div :class="isHomeTeamWinner(match) ? 'winner' : 'loser '" class="rounded-md -mr-2 p-0 border flex outcome-arrow items-center justify-center">
        <ArrowLongUpIcon v-if="isHomeTeamWinner(match)" class="w-6 h-6 text-green-700" />
        <ArrowLongDownIcon v-if="!isHomeTeamWinner(match)" class="w-6 h-6 text-red-600" />
      </div>
    </div>
  </router-link>

  <div class=" flex items-center justify-center gap-4 sm:gap-2 md:gap-3.5 rounded-md">
    <div class="flex flex-col justify-center font-semibold text-gray-800 p-4 py-0 px-2 pt-0.5 bg-white/20 rounded shadow">
      <div v-if="match.result.confirmer_id" class="match-result-wrapper flex justify-between">
        <div class="match-date">
          <div class="year text-xs uppercase text-center -mb-2">
            {{match.played_at ? parseMatchDate(match.played_at || match.created_at)['month'] : 'tbd'}}
          </div>
          <div class="month text-2xl">
            <span>{{match.played_at ? parseMatchDate(match.played_at || match.created_at)['day'] : 'NA'}}</span>
          </div>
        </div>

        <TeamShowRoundResults v-if="match.result.round_results" :match="match" />
      </div>

      <div v-else>
        <div class="year text-xs uppercase text-center -mb-2 pb-3">
          <div>Awaiting</div>
          <div>Confirmation</div>
        </div>
      </div>
    </div>
  </div>

  <router-link as="a" :to="teamPathFor(match.away_team.id)" :class="!isHomeTeamWinner(match) ? 'winner' : 'loser'" class="away_team relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-glass-white-500 px-6 py-5 shadow-sm hover-transition-2">
    <div class="flex-1 flex items-center justify-between">
      <div :class="!isHomeTeamWinner(match) ? 'winner' : 'loser '" class="rounded-md -ml-3 p-0 border flex outcome-arrow items-center justify-center">
        <ArrowLongUpIcon v-if="!isHomeTeamWinner(match)" class="w-6 h-6 text-green-700" />
        <ArrowLongDownIcon v-if="isHomeTeamWinner(match)" class="w-6 h-6 text-red-600" />
      </div>
      <div class="flex items-center">
        <div class="min-w-0">
          <p class="text-sm font-medium text-gray-900">{{ match.away_team.name }}</p>
          <p class="outcome-text truncate text-sm">
            {{ !isHomeTeamWinner(match) ? 'Win' : 'Loss' }}
            {{ !isHomeTeamWinner(match) ? ` by ${match.result.differential}` : '' }}
          </p>
        </div>
        <div class="flex-shrink-0 ml-3">
          <img class="h-10 w-10 rounded-full" :src="match.away_team.image_url" alt="" />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Subscriptions } from '@/lib/subscriptions';
import { formatDate } from '@/lib/template_helpers';
import { InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon } from '@heroicons/vue/24/solid';
import * as TemplateHelpers from '@/lib/template_helpers';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';
import TeamShowRoundResults from './_round_results';
import UIComponents from '@/components/ui';

export default {
  name: 'TeamShowCompletedMatch',
  components: {
    InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon,
    TeamShowRoundResults,
    ...UIComponents
  },
  props: ['parent', 'match'],
  mixins: [TemplateHelpersMixin],
  methods: {
    isHomeTeamWinner(match) {
      return match.home_team === match.result.winner;
    },
    parseMatchDate(date) {
      return this.formatDate(date);
    },
    classesForHomeTeam(match) {
      if(this.isHomeTeamWinner(match)) {
        return 'is-winner';
      } else {
        return 'is-loser';
      }
    }
  },
  async created() {
    window.completed_match = this;

    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      team: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
.home_team, .away_team {
  @apply font-aldrich hover:border-glass-white-100 hover:ring-glass-black-200 hover:ring;
}
</style>
