<template>
<div class="mr-2">
  <div><h1 class="home-page-title p-3 text-shadow-md-black">XRL League Stats</h1></div>
  <dl class="mx-auto grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4" v-if="is_loaded">
    <div v-for="stat in stats" :key="stat.name" class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-4 py-4 sm:px-6 xl:px-8 home-page-widget-2">
      <dt class="text-base font-medium leading-6 text-orange-100 font-agency uppercase ">{{ stat.name }}</dt>
      <dd class="w-full flex-none text-4xl font-medium leading-10 tracking-tight text-gray-300">{{ stat.value }}</dd>
    </div>
  </dl>
</div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import {Stat} from '@/models/stat';
import BaseMixin from '@/components/mixins/base_mixin';

export default {
  name: 'HomePageStats',
  mixins: [ BaseMixin ],
  async created() {
    Stat.$axios.get(`api/front/stats.json`).then((r) => {
      _.each(r.data, (v, k) => {
        this.stats.push({
          value: v,
          name: k,
          changeType: 'positive',
          change: ''
        })
      })

      this.is_loaded = true;
    });

  },
  setup() {
    return {
      is_loaded: ref(true),
      stats: ref([])
    }
  }
}
</script>

<style>
.home .stats {
}
</style>
