import Spinner from './ui/spinner';
import GlobalLoading from './ui/global_loading';
import LogoSpinner from './ui/logo_spinner';
import Overlay from './ui/overlay';
import DiscordIcon from './icons/discord_icon';
import TwitchIcon from './icons/twitch_icon';
import TiktokIcon from './icons/tiktok_icon';
import YoutubeIcon from './icons/youtube_icon';
import XrlIcon from './icons/xrl_icon';
import CollectionSearch from './ui/collection_search';
import CountrySelect from './ui/country_select';
import Tooltip from './ui/tooltip';
import Popover from './ui/popover';

export default {
  CollectionSearch,
  CountrySelect,
  Spinner,
  GlobalLoading,
  DiscordIcon,
  TwitchIcon,
  YoutubeIcon,
  TiktokIcon,
  XrlIcon,
  Overlay,
  Tooltip,
  Popover,
  LogoSpinner
}
