<template>
  <div class="p-4 flex">
    <div class="flex-shrink-0 pt-0.5">
      <img :src="notification.sender_image_url" class="h-10 w-10 rounded-full" alt="" />
    </div>
    <div class="ml-3 flex-1">
      <div class="flex justify-between mb-1">
        <p class="text-md font-medium text-gray-900">{{ notification.sender_name }}</p>
        <p class="text-sm text-gray-500 font-agency uppercase">{{ timeAgo(notification.created_at) }}</p>
      </div>
      <p class="text-sm text-gray-500">{{ notification.message }}</p>
      <div class="mt-4 flex">
        <button @click="acceptTeamInvitation()" type="button" class="uppercase inline-flex items-center rounded-md button-glass-black-3 hover-transition-1">Join Team</button>
        <button @click="declineTeamInvitation()" type="button" class="uppercase ml-2 inline-flex items-center rounded-md bg-glass-black-100 border-glass-black-200 py-2 px-4 text-sm font-semibold text-gray-900 shadow-sm hover:ring hover:ring-glass-black-150 hover-transition-1">Decline Invitation</button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/components/mixins/base_mixin';
import TemplateHelpers from '@/components/mixins/template_helpers';
//todo: break this off into different notifications per the kind, and especially,
//do a submitted scores notification

export default {
  name: 'MyNotificationsNotification',
  props: ['notification'],
  mixins: [ BaseMixin, TemplateHelpers ],
  components: {
  },
  methods: {
    async acceptTeamInvitation() {
      try {
        await this.notification.acceptTeamInvitation();
        this.showSuccessMessage("You have joined the team", "Congratulations!");
        this.$router.push('/my/teams');
        this.$parent.closePanel();
      } catch(e) {
        console.log(e);
      }
    },
    async declineTeamInvitation() {
      await this.notification.declineTeamInvitation();
    }
  },
  async created() {
  }
}
</script>

<style>
</style>
