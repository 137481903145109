import {BaseModel, Model} from './base_model';

@Model({
  type: 'player_rating',
  url: 'api/front/player_ratings',
  attributes: {
    rating: {},
    rank: {},
    win_count: { default_value: 0 },
    loss_count: { default_value: 0 },
    draw_count: { default_value: 0 },
    season_id: {},
    division: {}
  },
  belongs_to: {
    player: { type: 'player' }
  }
})

export class PlayerRating extends BaseModel {

}
