<template>
  <div :class="classes" class="flex flex-col justify-stretch sm:flex-row sm:space-x-2 sm:space-y-0">
    <button @click="copyDiscord()" type="button" class="inline-flex justify-center rounded-md icon-button-glass-black-1">
      <DiscordIcon class="h-6 w-6" aria-hidden="true" />
    </button>

    <button v-if="user.twitch_url.length > 0" @click="goToUrl(user.twitch_url)" type="button" class="inline-flex justify-center rounded-md icon-button-glass-black-1">
      <TwitchIcon class="h-6 w-6" aria-hidden="true" />
    </button>

    <button v-if="user.youtube_url.length > 0" @click="goToUrl(user.youtube_url)" type="button" class="inline-flex justify-center rounded-md icon-button-glass-black-1">
      <YoutubeIcon class="h-6 w-6" aria-hidden="true" />
    </button>

    <button v-if="user.tiktok_url.length > 0" @click="goToUrl(user.tiktok_url)" type="button" class="inline-flex justify-center rounded-md icon-button-glass-black-1">
      <TiktokIcon class="h-6 w-6" aria-hidden="true" />
    </button>

    <button @click="copyXrl()" type="button" class="inline-flex justify-center rounded-md icon-button-glass-black-1">
      <XrlIcon :svgClass="'h-6 w-6'" aria-hidden="true" />
    </button>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import UIComponents from '@/components/ui';
import Mixins from '@/components/mixins';

export default {
  name: 'UserShowSocialButtons',
  mixins: _.values(Mixins),
  components: {
    ...UIComponents
  },
  props: ['user', 'classes'],
  data() {
    return {
      is_loaded: false
    }
  },
  methods: {
    goToUrl(url) {
      window.open(url, "_blank");
    },
    async copyDiscord() {
      await this.copyToClipboard(this.user.discord_name || 'unknown_discord');
    },
    async copyXrl() {
      await this.copyToClipboard(`https://${window.location.host}${this.$route.fullPath}`, 'XRL Profile link copied to clipboard');
    }
  },
  async created() {
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
</style>
