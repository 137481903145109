<template>
  <div class="font-aldrich flex text-gray-300 countdown" v-if="is_loaded">
    <!-- <ChevronDownIcon class="mr-2" /> -->
    <!-- Countdown Timer -->
    Season Starts in {{ countdown.days }} Days, {{ countdown.hours }}:{{ countdown.minutes }}:{{ countdown.seconds }}:S
  </div>
</template>

<script>
/* eslint-disable */
import { ref, onMounted, onUnmounted } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';

export default {
  name: 'StandingsIndexNavigationCountdownTimer',
  components: {
    ChevronDownIcon
  },
  props: ['season'],
  methods: {

  },
  created() {
    this.is_loaded = true;
  },
  setup(props) {
    const countdown = ref(null);

    const calculateCountdown = () => {
      const now = new Date().getTime();
      const seasonStart = new Date(props.season.started_at).getTime();
      const timeLeft = seasonStart - now;

      if (timeLeft > 0) {
        countdown.value = {
          days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
          hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
        };
      } else {
        countdown.value = null;
      }
    };

    const interval = setInterval(calculateCountdown, 1000);

    onMounted(calculateCountdown);
    onUnmounted(() => clearInterval(interval));

    return {
      countdown,
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
.countdown {
  min-width: 400px;
}
/* Add your styling here */
</style>
