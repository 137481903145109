import {BaseModel, Model} from './base_model';

@Model({
  type: 'current_user',
  url: '/users/sessions',
  attributes: {
    'signed_in': { default_value: false },
    'email': {},
    'bio': {},
    'nationality': {},
    'username': {},
    'image_url': {}
  },
  has_many: {
    'players': {type: 'player'}
  }
})

export class CurrentUser extends BaseModel {
  async destroy() {
    let response = await this.constructor.$axios.delete(`/users/sign_out`);
    return response;
  }
}
