<template>
  <div class="home-page-widget-2 discord p-2">
    <iframe src="https://discord.com/widget?id=1166815836191850558&theme=dark"
      class="opacity-80 hover:opacity-100 hover-transition-2"
      id="discord-iframe"
      width="100%" height="100%" allowtransparency="true" frameborder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
    </iframe>
  </div>
</template>

<script>
  export default {
    name: 'HomePageDiscord',
    mounted() {
    },
    async created() {

    },
  }
</script>

<style>
.home .discord {
}
</style>
