<template>
  <div class="about people">
    <div class="relative py-5 sm:py-20">
      <div class="mx-auto px-6 lg:px-8">
        <div class="relative mx-auto max-w-2xl sm:text-center">
          <h2 class="text-4xl font-bold text-glass-white-900 text-secondary uppercase font-agency">Meet our team</h2>
          <p class="mt-6 text-lg leading-8 text-glass-white-800">Just a group of friends who met in VR who wanted to help grow the community and create a competitive XR league, so XRL was formed!</p>
        </div>
        <ul role="list" class="relative mx-auto mt-14 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
          <li v-for="person in people" :key="person.name" class="flex flex-col gap-6 xl:flex-row">
            <img class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" :src="person.imageUrl" alt="" />
            <div class="flex-auto">
              <h3 class="text-3xl font-semibold leading-8 tracking-tight text-glass-white-900 font-agency uppercase mb-1 border-b border-glass-white-300 pb-1">{{ person.name }}</h3>
              <p class="text-base leading-7 text-glass-white-800"><span class="font-agency uppercase"><DiscordIcon class="discord-icon h-5 w-5 mb-1 mr-1" />Discord</span> - {{ person.discord }}</p>
              <p class="text-base text-lg leading-7 text-glass-white-800 font-agency">{{ person.role }}</p>
              <p class="mt-4 text-base text-md leading-6 text-white font-aldrich">{{ person.bio }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<!-- Script for stats from Tailwind -->
<script setup>
const people = [
  {
    name: 'Jason Ayre',
    role: 'Co-Founder / Chief Technology Officer',
    discord: 'thrice801',
    imageUrl: 'https://xrlpro.s3.amazonaws.com/headshots/jason.jpg',
    bio: 'Jason is a programmer from Draper Utah. When he’s not building things on the interwebs, he’s working on a sound design app. On a powder day, he can be found on the slopes of Brighton UT. He loves making music, epic soundtracks, and talking peoples ears off about the future of VR sports.',
  },
  {
    name: 'Kyle Glaser',
    role: 'Co-Founder / Chief Creative Officer',
    discord: 'dmxdash',
    imageUrl: 'https://xrlpro.s3.amazonaws.com/headshots/kyle.jpg',
    bio: 'Kyle Glaser is a touring Lighting designer from Los Angeles who now lives in Las Vegas. He is a VR enthusiast who was the Captain for Ancient Athletes for 5 Competitive Seasons in Echo VR. When he is not running lights or playing VR he is spending it with his wife and child.',
  },
  {
    name: 'David Tuell',
    role: 'Co-Founder / Chief Operating Officer',
    discord: 'martianmantn',
    imageUrl: 'https://xrlpro.s3.amazonaws.com/headshots/david.jpg',
    bio: 'David is a CIO at a small private University in east Tennessee. A long-time technology guy, he picked up VR back in the cardboard days and has been hooked ever since.  When not playing VR, he is spending time with family or volunteering his technical abilities in other local organizations.',
  },
  {
    name: 'Sonya Haskins',
    role: 'Community Manager',
    discord: 'hasko7',
    imageUrl: 'https://xrlpro.s3.amazonaws.com/headshots/sonya.jpg',
    bio: 'Sonya Haskins was the first female player, oldest player and only seated player to qualify for the NA Regional Championships of the Oculus-sponsored VR League in 2017. Sonya has traveled the world to promote immersive reality in various roles as advisor, journalist, and events coordinator. She advocates positive, inclusive environments.',
  },

  // More people...
]
</script>

<script>
import DiscordIcon from '@/components/icons/discord_icon';
  export default {
    name: 'HomePagePeople',
    components: 'DiscordIcon',
    async created() {
    },
  }
</script>

<style>

.about .people {

}

.people .discord-icon {
  display: inline-block;
}
</style>
