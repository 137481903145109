<template>
<div class="section-header-1" v-if="is_loaded">
  <router-link v-if="url" :to="url">
    <template v-if="image_src">
      <span class="image-wrapper"><img class="h-10 w-10 rounded-full inline-flex mr-3" :src="image_src" /></span>
    </template>
    <span class="section-title">{{title}}</span>
  </router-link>

  <span v-else>
    <template v-if="image_src">
      <span class="image-wrapper"><img class="h-10 w-10 rounded-full inline-flex mr-3" :src="image_src" /></span>
    </template>

    <span class="section-title">{{title}}</span>
  </span>
</div>

<div class="stats bg-glass-white-500 rounded-lg mb-4 font-aldrich">
  <div class="mx-auto max-w-7xl">
    <div class="grid grid-cols-2 gap-px sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6">
      <div v-for="stat in stats" :key="stat.name" :class="stat.classname" class="px-4 bg-glass-white-100 py-6 sm:px-6 lg:px-8">
        <p class="text-sm font-medium leading-6 text-glass-black-600">{{ stat.name }}</p>
        <p class="mt-2 flex items-baseline gap-x-2">
          <span class="text-5xl font-semibold tracking-tight text-white text-shadow-sm-black-inset">{{ stat.value }}</span>
          <span v-if="stat.unit" class="text-sm text-gray-400">{{ stat.unit }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Subscriptions } from '@/lib/subscriptions';
import { formatDate } from '@/lib/template_helpers';
import { InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon } from '@heroicons/vue/24/solid';
import * as TemplateHelpers from '@/lib/template_helpers';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';

const PlayerShowStats = {
  name: 'PlayerShowStats',
  components: {
    InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon
  },
  props: ['parent', 'season', 'rating', 'title', 'image_src', 'url'],
  mixins: [TemplateHelpersMixin],
  methods: {

  },
  async created() {
    window.season_data = this;
    // console.log(this.rating.league.id)
    console.log(this.rating.league);
    this.stats = [
      {name: 'Rank', value: this.rating.rank},
      {name: 'Record', value: `${this.rating.win_count} - ${this.rating.loss_count || 0}`},
      {name: 'Rating', value: `${this.rating.rating}`},
      {name: 'Division', value: '', classname: `division-bg ${this.rating.division}`},
      {name: 'Points Differential', value: this.rating.differential || 0},
      {name: 'Games Played', value: this.rating.match_count || 0}
    ];

    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      team: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}

export default PlayerShowStats;
</script>

<style>
.stats {
  .division-bg {
    background-size: 64px 64px !important;
    background-origin: bottom left;
    background-position: left 18px bottom 16px !important;
  }
}
.team-show {

}
</style>
