<template>
  <!-- <HollowHexagon :svgClass="'absolute hex-spinner drop-shadow-lg transform-scale-0'" :color="'#fd7334'" :size="48" :edgeSize="4"> -->
  <div :class="classNames()" class="award mr-2 rounded-md px-1 drop-shadow-lg shadow-inner ring-2" >
    <div class="text -mt-1 flex">
      <XrlIcon :svgClass="'h-6 w-6 mt-1 -ml-1 drop-shadow-lg xrl-logo-spinner'" />
      <div>{{record.text}}</div>
    </div>
    <div class="label text-xs font-agency uppercase -mt-2">
      {{record.label}}
    </div>
  </div>
</template>
<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import UIComponents from '@/components/ui';
import * as Models from '@/models';
import BaseMixin from '@/components/mixins/base_mixin';
import TemplateHelpers from '@/components/mixins/template_helpers';

export default {
  name: 'StandingsIndexItemAward',
  mixins: [BaseMixin, TemplateHelpers],
  components: {
    ...UIComponents
  },
  props: ['record'],
  watch: {},
  methods: {
    async created() {
    },
    classNames() {
      const classesForLabel = {
        '1st place': 'bg-orange-600/70 ring-orange-700/50 text-orange-100',
        '2nd place': 'bg-purple-500/50 ring-purple-700/50 text-purple-100',
        '3rd place': 'bg-sky-500 ring-sky-700/50 text-sky-100'
      }

      return classesForLabel[this.record.label];
    }
  },
  setup() {
    return {
    }
  }
}
</script>

<style>
.xrl-logo-spinner {
animation: rotateY360 25.25s cubic-bezier(0.37, 0, 0.63, 1) infinite;  /*sin ease */
will-change: transform;
}
@keyframes rotateY360 {
  0%, 100% {
    transform: rotateY(0deg) scale(1.1);
  }
  50% {
    transform: rotateY(-360deg) scale(0.95);
  }
}
</style>
