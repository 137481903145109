<template>
  <img :class="svgClass" :src="$root.logoFor('xrl')" />
</template>

<script>
export default {
  name: 'XrlIcon',
  props: {
    svgClass: {
      type: String,
      default: '',
    },
  },
};
</script>
