<template>
  <div class="heading sm:flex sm:items-center sm:justify-between sm:flex-auto py-8">
    <div class="page-title  px-1 font-aldrich hidden sm:block">

    </div>
    <div class="actions flex flex-nowrap space-x-1 justify-end mb-0 font-aldrich uppercase text-color-accent">
      <!-- <CollectionSearch :placeholder="'Search Teams'" :search="fetchTeams" :recordPath="$parent.teamShowPath" :image_key="'image_url'" :imageKey="'image_url'"></CollectionSearch> -->
      <CollectionSearch :placeholder="'Search Players'" :search="fetchPlayers" :recordPath="$parent.playerShowPath" :image_key="'image_url'" :imageKey="'image_url'"></CollectionSearch>
      <!-- Hide for now since we're not doing region splits yet -->
      <Menu v-if="false" as="span" class="relative inline-block text-left">
        <div>
          <MenuButton class="flex-nowrap menu-button-glass-black-1">
            <span class="uppercase text-sm">Region: {{$route.params.region || 'All'}}
              <ChevronDownIcon class="inline-flex -mr-1 w-5" aria-hidden="true" />
            </span>
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="menu-items-glass-black w-56">
            <div class="py-1">
              <MenuItem v-for="region in regions" :key="region.name" v-slot="{ active }" class="text-sm hover-transition-2">
                <a @click="onRegionChange(region.name)" href="#" :class="[active ? ' text-sm' : ' ', 'block px-4 py-2 text-sm']">
                  <span>{{region.attributes.label}}</span>
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>

      <Menu as="span" class=" relative inline-block text-left">
        <div>
          <MenuButton class="flex-nowrap gap-x-0.5 menu-button-glass-black-1 max-w-xxs truncate">
            <span class="uppercase text-sm">Team Status: {{this.$route.query.by_team_status || 'All'}}
              <ChevronDownIcon class="inline-flex -mr-1 w-5" aria-hidden="true" />
            </span>
          </MenuButton>
        </div>

        <Transition enter-active-class="transition ease-out duration-500" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="menu-items-glass-black w-33">
            <div class="py-1">
              <MenuItem v-for="status in statuses" :key="status.name" v-slot="{ active }" class="hover-transition-2">
                <a @click="onStatusChange(status.name)" href="#" :class="[active ? '' : ' ', 'block px-4 py-2 text-sm']">{{status.attributes.label}}</a>
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>

      <div class="relative inline-block text-center">
        <button :disabled="!anyQueryParams()" @click="reset()" class="flex-nowrap gap-x-0.5 menu-button-glass-black-1 px-1">
          <FunnelIcon class="inline-flex -mr-0 w-5" aria-hidden="true" />
          <XMarkIcon class="inline-flex -mr-0 w-5" aria-hidden="true" />
        </button>
      </div>

      <div class="relative inline-block text-center">
        <div @click="previousPage()" class="flex-nowrap gap-x-0.5 menu-button-glass-black-1 px-1">
          <ChevronLeftIcon class="inline-flex -mr-0 w-5" aria-hidden="true" />
        </div>
      </div>

      <div class="relative inline-block text-center">
        <div @click="nextPage()" class="flex-nowrap gap-x-0.5 menu-button-glass-black-1 px-1">
          <ChevronRightIcon class="inline-flex -mr-0 w-5" aria-hidden="true" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import { XCircleIcon, XMarkIcon, FunnelIcon } from '@heroicons/vue/24/outline';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import CollectionSearch from '@/components/ui/collection_search';
import * as Models from '@/models';
import Schema from '@/constants/schema';

export default {
  name: 'PlayersIndexNavigation',
  components: {
    ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, XCircleIcon, XMarkIcon, FunnelIcon,
    Menu, MenuButton, MenuItem, MenuItems, CollectionSearch
  },
  props: ['parent', 'pagination', 'onPageChanged'],
  watch: {
  },
  methods: {
    async nextPage() {
      let next_page = (this.pagination.current_page < this.pagination.total_pages) ? this.pagination.current_page + 1 : 1;
      await this.onPageChanged({page: next_page});
    },
    async previousPage() {
      let previous_page = (this.pagination.current_page === 1) ? this.pagination.total_pages : this.pagination.current_page - 1;
      await this.onPageChanged({page: previous_page});
    },
    anyQueryParams() {
      return _.keys(this.$route.query).length > 0;
    },
    reset() {
      this.$parent.$router.push({
        name: 'PlayersIndexRegion',
        query: {}
      })
    },
    onRegionChange(region) {
      this.$parent.region = region;

      this.$parent.$router.push({
        name: 'PlayersIndexRegion',
        params: {
          region: region
        }
      })

      this.$parent.fetch();
    },
    onStatusChange(status) {
      this.$parent.$router.push({
        name: 'PlayersIndexRegion',
        query: {
          by_team_status: status
        }
      })
    },
    async fetchPlayers(name) {
      let results = await Models.Player.search({
        by_league_id: this.$parent.league.id,
        by_name: name
      })

      return results;
    }
  },
  async created() {
    window.players_nav = this;
    this.schema = Schema;

    this.regions.push(...Schema.User.region.choices);
    // this.statuses.push(...Schema.Player.status.choices);
    this.statuses = [
      {name: "all", attributes: {label: "All"}},
      {name: "needs_teammate", attributes: {label: "Needs Teammate"}},
      {name: "on_active_team", attributes: {label: "On Active Team"}}
    ];
    this.status = this.$route.query.status;
  },
  setup() {
    return {
      statuses: ref([]),
      status: ref(""),
      regions: ref([]),
      seasons: ref([1,2,3])
    }
  }
}
</script>

<style>
</style>
