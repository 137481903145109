<template>
<div class="section-header-1">
  Team History
</div>

<div class="mb-4 font-aldrich" v-if="is_loaded">
  <ul role="list" class="">
    <li v-for="rating in team_ratings" :key="rating.id" class="relative flex item-hover-1 bg-glass-white-500 mb-1 rounded-lg hover-transition-1 hover:rounded-md hover:text-accent justify-between gap-x-6 px-4 py-5 sm:px-6 lg:px-8">
      <div class="flex min-w-0 gap-x-4 hover-text-white">
        <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="rating.team.image_url" alt="" />
        <div class="min-w-0 flex-auto">
          <p class="text-sm font-semibold leading-6 text-gray-900 hover-text-black">
            <router-link :to="teamPathFor(rating.team.id)" class=" font-medium">
              <span class="absolute inset-x-0 -top-px bottom-0" />
              {{ rating.team.name }}
            </router-link>
          </p>
          <p class="mt-1 flex text-xs leading-5 text-gray-800 hover-text-black">
            <span class="relative truncate hover:underline">Joined on Season X</span>
          </p>
        </div>
      </div>
      <div class="flex shrink-0 items-center gap-x-4">
        <div class="hidden sm:flex sm:flex-col sm:items-end">
          <PlayerShowTeamHistoryPlayers :team="rating.team" />
        </div>
        <ChevronRightIcon class="h-5 w-5 flex-none text-gray-800" aria-hidden="true" />
      </div>
    </li>
  </ul>
</div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import { ChevronRightIcon, ArrowLongDownIcon } from '@heroicons/vue/24/solid';
import * as TemplateHelpers from '@/lib/template_helpers';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';
import PlayerShowTeamHistoryPlayers from './_team_history_players';

export default {
  name: 'PlayerShowTeamHistory',
  components: {
    ChevronRightIcon, PlayerShowTeamHistoryPlayers
  },
  props: ['parent', 'season', 'teamRatings', 'title'],
  mixins: [TemplateHelpersMixin],
  methods: {

  },
  async created() {
    this.team_ratings = _.uniqBy(_.values(this.teamRatings), 'record_id');
    window.team_history = this;
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      team: ref([]),
      team_ratings: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>

</style>
