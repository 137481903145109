async function resizeImage(file, maxSize, filename) {
  return new Promise((resolve, reject) => {
    // Create an image element
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      // Create a canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set dimensions
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      const mimeType = file.type || 'image/jpeg';

      // Convert canvas to Blob and then create FormData
      canvas.toBlob(blob => {
        const formData = new FormData();
        formData.append('name', filename);
        formData.append('file', blob);
        resolve(formData);
      }, mimeType);
    };

    img.onerror = reject;
  });
}

function resizeAndCropImage(file, filename, maxHeight) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas width to image's original width
      const width = img.width;

      // Determine if the image needs to be cropped
      let height = img.height;
      let cropY = 0; // No cropping by default
      if (height > maxHeight) {
        cropY = (height - maxHeight) / 2; // Calculate crop amount to keep the crop centered
        height = maxHeight; // Set the new height to the maximum allowed height
      }

      // Set canvas dimensions
      canvas.width = width;
      canvas.height = height;

      // Draw the image on the canvas, cropping if necessary
      ctx.drawImage(img, 0, cropY, width, height, 0, 0, width, height);

      // Determine the MIME type
      const mimeType = file.type || 'image/png'; // Default to PNG if the file type is not specified

      // Convert canvas to Blob
      canvas.toBlob(blob => {
        const formData = new FormData();
        formData.append('name', filename);
        formData.append('file', blob, filename); // Append the file with the original filename
        resolve(formData);
      }, mimeType); // Use the determined MIME type
    };

    img.onerror = reject;
  });
}



// async function resizeAndCropImage(file, filename, maxSize = 128) {
//   return new Promise((resolve, reject) => {
//
//     const img = new Image();
//     img.src = URL.createObjectURL(file);
//
//     img.onload = () => {
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//       ctx.imageSmoothingEnabled = true;
//       ctx.imageSmoothingQuality = 'high';
//       canvas.width = maxSize;
//       canvas.height = maxSize;
//
//       let scale, offsetX, offsetY;
//       if (img.width > img.height) {
//         scale = maxSize / img.height;
//         offsetX = (img.width * scale - maxSize) / 2;
//         offsetY = 0;
//       } else {
//         scale = maxSize / img.width;
//         offsetX = 0;
//         offsetY = (img.height * scale - maxSize) / 2;
//       }
//
//       ctx.drawImage(img, -offsetX, -offsetY, img.width * scale, img.height * scale);
//       const mimeType = file.type || 'image/jpeg';
//
//       canvas.toBlob(blob => {
//         const formData = new FormData();
//         formData.append('name', filename);
//         formData.append('file', blob);
//         resolve(formData);
//       }, mimeType);
//     };
//
//     img.onerror = reject;
//   });
// }


async function scaleImageToFixedHeight(file, filename, fixedHeight = 192) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const scale = fixedHeight / img.height;

      // Scaled dimensions
      const scaledWidth = img.width * scale;
      const scaledHeight = fixedHeight;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = scaledWidth;
      canvas.height = scaledHeight;

      ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);

      const mimeType = file.type || 'image/jpeg';

      canvas.toBlob(blob => {
        const formData = new FormData();
        formData.append('name', filename);
        formData.append('file', blob, filename);
        resolve(formData);
      }, mimeType);
    };

    img.onerror = () => reject(new Error('Image loading error'));
  });
}


async function resizeNonSquareImage(file, maxSize, filename) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let width = img.width;
      let height = img.height;

      const scale = Math.min(maxSize / width, maxSize / height);
      if (scale < 1) {
        width = Math.floor(width * scale);
        height = Math.floor(height * scale);
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      const mimeType = file.type || 'image/jpeg';

      canvas.toBlob(blob => {
        const formData = new FormData();
        formData.append('name', filename);
        formData.append('file', blob);
        resolve(formData);
      }, mimeType);
    };

    img.onerror = reject;
  });
}

export default {
  methods: {
    resizeImage,
    resizeNonSquareImage,
    scaleImageToFixedHeight,
    resizeAndCropImage
  }
}
