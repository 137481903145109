<template>
  <div class="spinner-wrapper flex items-center justify-center w-full h-full" :class="classes()">
    <HollowHexagon :svgClass="'hex-spinner drop-shadow-lg'" :color="'#fd7334'" :size="132" :edgeSize="4">
      <img class="xrl-logo-spinner drop-shadow-lg h-20 w-20 rounded-full block z-10" :src="$root.logoFor('xrl')" />
    </HollowHexagon>
  </div>
</template>

<script>
 /* eslint-disable */
import { ref, computed } from 'vue';
import _ from 'lodash';
import HollowHexagon from '@/components/icons/hollow_hexagon'

export default {
  name: 'LogoSpinner',
  components: {
    HollowHexagon
  },
  props: {
    classNames: String,
    overlay: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    classes() {
      let _classes = this.classNames;

      if(this.overlay) {
        _classes = `${_classes} bg-gradient-to-r from-neutral-950/40 via-neutral-800/60 to-neutral-900/40`;
      }

      return _classes;
    }
  },
  async created() {
  },
  setup() {
    return {}
  }
}
</script>

<style>
.hexagon-container {
  --dash-length: 29;
  --gap-length: 261;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@keyframes spindash {
  to {
    stroke-dashoffset: 0;
  }
}

.hexagon-path {
  animation: spindash 0.75s linear infinite, dash-gap-change 16s cubic-bezier(0.65, 0, 0.35, 1) infinite;
  /* animation: spindash 0.75s cubic-bezier(0.65, 0, 0.35, 1) infinite, dash-gap-change 16s cubic-bezier(0.65, 0, 0.35, 1) infinite; */
  stroke-dasharray: var(--dash-length) var(--gap-length);
  stroke-dashoffset: 290;
}

@keyframes dash-gap-change {
  0%, 5%, 95%, 100% {
    --dash-length: 29;
    --gap-length: 261;
  }
  50% {
    --dash-length: 58;
    --gap-length: 232;
  }
}

@keyframes rotateY360 {
  0%, 100% {
    transform: rotateY(0deg) scale(1.1);
  }
  50% {
    transform: rotateY(-360deg) scale(0.95);
  }
}

@keyframes rotateZ360 {
  0%, 40%, 60%, 100% {
    transform: rotate3d(0deg);
  }
  50% {
    transform: rotate3d(-360deg);
  }
}

.xrl-logo-spinner {
  animation: rotateY360 1.25s cubic-bezier(0.37, 0, 0.63, 1) infinite;  /*sin ease */
  will-change: transform;
}
.hex-spinner {}

</style>
