<template>
  <!-- <div class="overflow-hidden" style="height: 1280px; width: 1280px; position: fixed; top: 0; left: 0;"> -->
    <div class="animate-flare home-lens-flare fixed" style="height:1280px; width:1280px;"></div>
    <!-- <div class="animate-flare home-lens-flare fixed h-vw w-vw" ></div> -->
  <!-- </div> -->
  <!-- <div :class="{ 'pause-flare': !isScrolling }" class="animate-flare home-lens-flare fixed h-vh w-vw" style="height:100%; width:100%;"></div> -->
  <div class="home flow-root text-shadow-lg flex-grow px-2 py-2 h-max-content h-min-screen" v-if="is_loaded">
    <div id="hero">
      <HomeHero as="div" />
    </div>

    <HomeCasts></HomeCasts>

    <div class="flex my-2">
      <HomeLeagues_Stats as="div" class=" w-2/3" />
      <HomeDiscord as="div" class=" w-1/3" />
    </div>

    <HomeGames />

    <div id="sponsors">
      <HomeSponsors as="div" />
    </div>

    <div class="rounded-md bg-glass-black-700 backdrop-blur-md">
      <HomeFooter as="div" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import HomeDiscord from './home/discord.vue';
import HomeHero from './home/hero.vue';
import HomeEvents from './home/events.vue';
import HomeContent from './home/content.vue';
import HomeSponsors from './home/sponsors.vue';
import HomeFooter from './home/footer.vue';
import HomeLeagues_Stats from './home/leagues_stats.vue';
import HomeCasts from './home/casts.vue';
import HomeGames from './home/games.vue';
import * as Models from '@/models';

export default {
  name: 'Home',
  components: {
    HomeDiscord,
    HomeHero,
    HomeCasts,
    HomeEvents,
    HomeContent,
    HomeSponsors,
    HomeFooter,
    HomeLeagues_Stats,
    HomeGames
  },

  created() {
    window.home = this;
    // setTimeout(() => {
    //   this.scrollableElement = document.querySelector('.main-router-wrapper');
    //   this.scrollableElement.addEventListener('scroll', this.handleScroll);
    // }, 1000);
  },

  // destroyed() {
  //   this.scrollableElement.removeEventListener('scroll', this.handleScroll);
  // },
  methods: {
  },
  setup() {
    return {
      is_loaded: ref(true)
    }
  }
}
</script>

<style>
.animate-flare {
  animation: lens-flare-animation 30s infinite alternate ease-in-out;
  transition: --animation-timing-function 0.5s;
}
.pause-flare {
  animation-play-state: paused;
}
@keyframes lens-flare-animation {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 0.0;
  }
  10% {
    transform: scale(1) rotate(25deg);
    opacity: 0.7
  }
  25% {
    transform: scale(1.5) rotate(90deg);
    opacity: 0.8;
  }
  50% {
    transform: scale(0.0) rotate(180);
    opacity: 1.0;
  }
  100% {
    transform: scale(3.5) rotate(360deg);
    opacity: 0.8;
  }
}
.home {

}

.home .section {
  @apply border border-glass-white-50 rounded-sm backdrop-blur-md;

  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.2),   /* Top shadow */
              1px 0 0 0 rgba(255, 255, 255, 0.2),   /* Right shadow */
              0 -1px 0 0 rgba(255, 255, 255, 0.2),  /* Bottom shadow */
              -1px 0 0 0 rgba(255, 255, 255, 0.2);  /* Left shadow */
}

.home .section .title {
  @apply font-aldrich text-xl text-gray-200 p-4 glass-white-shadow-divider;
}
</style>
