import {BaseModel, Model} from './base_model';
import {Helpers} from 'traxjs';
import _ from 'lodash';

@Model({
  type: 'league',
  url: 'api/front/leagues',
  attributes: {
    name: {},
    slug: {},
    overview: {},
    display_name: { read_only: true },
    hide_from_nav: { read_only: true }
  },
  belongs_to: {
    game: { type: 'game' }
  },
  has_many: {
    seasons: { type: 'season' },
    maps: { type: 'map' },
    tournaments: { type: 'tournament' }
  }
})

export class League extends BaseModel {
  get current_season() {
    return _.last(this.ordered_seasons);
    // return Helpers.fetch(this, '_current_season', () => {
    //   return _.last(_.orderBy(this.seasons, 'number'));
    // })
  }

  get ordered_seasons() {
    return Helpers.fetch(this, '_ordered_seasons', () => {
      return _.orderBy(this.seasons, 'number');
    })
  }
}
