<template>
  <div class="w-full" v-if="is_loaded" @click="clearSelectedCountry()">
    <Combobox v-model="selected_country" as="div" class="relative" @click.stop="">
      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2 left-2">
        <MagnifyingGlassIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
      </ComboboxButton>
      <ComboboxInput
        @input="filterCountries($event.target.value)"
        class="w-full rounded-md border border-gray-300 p-2 shadow-md pl-8"
        placeholder="Select a country"
        :display-value="displayValue"
      />
      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronUpDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions v-if="countries.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg">
        <ComboboxOption v-for="country in countries" :key="country.name" :value="country">
          <template #default="{ active }">
            <li :class="['cursor-default select-none py-2 pl-3 pr-9', {'bg-glass-secondary-900 text-shadow-white-inset-sm hover-transition-3': active}]">
              {{ country.attributes.flag }} {{ country.attributes.display_name }}
            </li>
          </template>
        </ComboboxOption>
      </ComboboxOptions>
    </Combobox>
  </div>
</template>

<script>
 /* eslint-disable */
import { ref, computed, watch } from 'vue';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { MagnifyingGlassIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import Schema from '@/constants/schema';
const CountryData = _.values(Schema.User.country.choices);

export default {
  name: 'CountrySelect',
  components: {
    Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, MagnifyingGlassIcon, ChevronUpDownIcon
  },
  props: ['record'],
  methods: {
    clearSelectedCountry() {
      this.selected_country = null;
      this.query = "";
    },
    displayValue(selected_country) {
      if(!selected_country) { return "" }
      return `${selected_country.attributes.flag} ${selected_country.attributes.display_name}`
    },
    filterCountries(search_query) {
      if (search_query === '') {
        this.countries = CountryData;
      } else {
        this.countries = this.countries.filter(country =>
          country.attributes.display_name.toLowerCase().includes(search_query.toLowerCase())
        );
      }
    }
  },
  created() {
    this.selected_country = _.find(CountryData, (r) => { return r.name === this.record.country});
    this.is_loaded = true;
  },
  watch: {
    'selected_country': function(newVal) {
      if(this && newVal) {
        this.record.country = newVal.name;
        this.query = "";
      }
    }
  },
  setup() {
    const query = ref('');
    const countries = ref(CountryData);

    return {
      selected_country: ref({}),
      countries,
      query
    };
  }
};
</script>

<style scoped>
</style>
