<template>

<div class="py-2 sm:px-6 lg:px-4 flex-grow">
  <PlayersIndexNavigation :pagination="pagination" :onPageChanged="fetch"></PlayersIndexNavigation>
  <Transition name="fade-transition-1" mode="in-out">
    <GlobalLoading v-if="!is_loaded" />
  </Transition>
  <Transition name="page-transition-2" mode="in-out">
  <div class="flow-root text-shadow-lg flex-grow" v-if="is_loaded">
    <div class="relative -mx-6 -my-2 sm:-mx-6 lg:-mx-6">
      <!-- below fixes the hover not expanding outside of container issue, not really sure what's happening, think padding 1 fine for now tho -->
      <!-- <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"> -->
      <div class="inline-block min-w-full py-2 align-middle sm:px-1 lg:px-1 overflow-x-visible">
        <table class="ui-table-1">
          <thead class="">
             <tr>
              <th scope="col" class="px-1 py-3.5 pl-4 pr-3 text-left font-semibold uppercase tracking-wide">Player Name</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold uppercase tracking-wide">Status</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold uppercase tracking-wide">Region</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold uppercase tracking-wide">Joined</th>
            </tr>
          </thead>
          <tbody class="">
            <tr v-for="player in players" :key="player.id" class="ui-table-1-row player-item">
              <td class=" px-8 py-3 pl-6 pr-3">
                <div class="flex items-center">
                  <div class="h-11 w-11 flex-shrink-0">
                    <img loading="lazy" class="h-11 w-11 rounded-full" :src="player.image_url" alt="" />
                  </div>
                  <div class="ml-4 w-40 truncate">
                    <router-link :to="playerShowPath(player.id)" class="hover:text-white font-medium truncate">
                      {{ player.name }}
                    </router-link>
                  </div>
                </div>
              </td>

              <td class="whitespace-nowrap px-3 py-3"><span>{{player.status}}</span></td>
              <td class="whitespace-nowrap px-3 py-3"><span>{{player.region}}</span></td>
              <td class="whitespace-nowrap px-3 py-3 font-bold font-sm">{{displayDate(player.created_at)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- <Pagination></Pagination> -->
  </Transition>
</div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import Pagination from '@/components/ui/Pagination';
import PlayersIndexNavigation from './index/navigation';
import UIComponents from '@/components/ui';
import BaseMixin from '@/components/mixins/base_mixin';
import TemplateHelpers from '@/components/mixins/template_helpers';
import { compactObject } from '@/lib/helpers';

export default {
  name: 'PlayersIndex',
  mixins: [BaseMixin, TemplateHelpers],
  components: {
    PlayersIndexNavigation, Pagination, ...UIComponents
  },
  props: ['league'],
  data() {
    return {
      pagination: {}
    }
  },
  methods: {
    async fetch(query={}) {
      this.is_loaded = false;
      this.currentQuery = compactObject({
        ...this.$route.query,
        ...query,
        ...this.$route.params,
        by_league_id: this.league.id,
        per_page: 100
      });

      console.log("QUERY IS", this.currentQuery);

      this.players = await Models.Player.search(this.currentQuery);
      this.pagination = this.players.meta.pagination;
      this.is_loaded = true;
    },
    playerShowPath(id) {
      return `/leagues/${this.$route.params.slug}/player/${id}/details`
    }
  },
  async created() {
    this.$$initialize();
    this.$root.inner_content_loading = true;
    // console.log(this.$route.params.slug);
    window.players_index = this;

    this.region = this.$route.params.region || 'na';

    await this.fetch({
      ...this.currentQuery,
      by_region: this.$route.params.region,
      per_page: 100
    });

    this.is_loaded = true;
    this.$root.inner_content_loading = false;
  },

  mounted() {
    console.log(this.$route.params.slug);
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(new_params, old_params) {
        console.log(new_params, old_params);
        _.assign(this, new_params);
        this.setLeague();
        this.fetch();
      }
    },
    '$router.currentRoute.value.query'(data) {
      console.log('query changed', data);

      // debugger;
    },
    '$router.currentRoute.value.query.per_page'(per_page) {
      this.fetch({per_page, page: page});
    },
    '$router.currentRoute.value.query.page'(page) {
      this.fetch({page: page});
    }
  },
  setup() {
    return {
      players: ref([]),
      league: ref({}),
      region: ref(undefined),
      currentQuery: ref({}),
      is_loaded: ref(false),
    }
  }
}
</script>

<style>
.team-item {
  &:hover {
    cursor: pointer;
  }
}
</style>
