<template>
  <div><h1 class="home-page-title p-3 text-shadow-md-black">Games We Support</h1></div>

  <div class="games home-page-widget-4 mb-2 py-8 pb-6">
    <div class="grid grid-cols-2 gap-4 justify-items-center">
      <div v-for="game in games" :key="game.name" class="rounded-md border border-transparent px-4 py-2 text-sm font-medium -mt-12 filter sepia-[0.5] opacity-80 saturate-100 hover:sepia-[0.0] hover:opacity-100 hover:scale-125 hover-transition-3">
        <a :href="game.href" target="_blank"><img :class="game.classes" :src="game.src" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

const GAMES = [
  {name: 'Racket Club', src: '/images/logos/racket-club.png', href: 'https://www.resolutiongames.com/racketclub', classes: 'h-48 w-48'},
  {name: 'Nock - Bow and Arrow Soccker', src: '/images/logos/nock.png', href: 'https://nock.game/', classes: 'h-48 w-48'},
  {name: 'Eleven Table Tennis', src: '/images/logos/eleven.png', href: 'https://elevenvr.com/', classes: 'h-32 w-32'},
  {name: 'Gravity League', src: '/images/logos/gravity-league.png', href: 'https://www.blue-duck-studios.com/gravity-league', classes: 'h-32 w-32'}
];

export default {
  name: 'HomePageGames',
  async created() {
  },
  setup() {
    return {
      games: ref(GAMES)
    }
  }
}
</script>

<style>
.home .games {
}
</style>
