<template>
  <div class="home section content">
    <h1 class="title">Featured Content</h1>
  </div>
  <div class="flex">
    <div align = center class="home section w-1/3">
      <h2 class = "title">Racket Club</h2>
      <iframe class = "flex p-3" width="100%" height="316"
        src="https://www.youtube.com/embed/LQi3k70STGU?si=LNSFLvu1VlVSb6Ix">
      </iframe>
    </div>
    <div align = center class="home section w-1/3">
      <h2 class = "title">Gravity League</h2>
      <iframe class = "flex p-3" width="100%" height="315"
        src="https://www.youtube.com/embed/vGsE07GoWgQ?si=pu5WpOfnVWQoU28o">
      </iframe>
    </div>
    <div align = center class="home section w-1/3">
      <h2 class="title">Nock</h2>
      <iframe class="flex p-3" width="100%" height="315"
        src="https://www.youtube.com/embed/5sDSyejhxRs?si=m_Yo1hed-4Jqt7EK">
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePageContent',
  async created() {
  }
}
</script>

<style>
.home .content {
}
</style>
