import {BaseModel, Model} from './base_model';

@Model({
  type: 'tournament',
  url: 'api/front/tournaments',
  attributes: {
    name: {},
    details: {},
    challonge_id: {}
  }
})

export class Tournament extends BaseModel {
}
