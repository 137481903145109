<template>
  <div class="section-header-1 mb-0 border-b-0 border-glass-white-200 mt-6 min-w-0 flex-1 text-shadow-white-inset font-aldrich flex justify-between items-center">
    <h1 class="truncate text-2xl">{{tournament.name}}</h1>
  </div>
  <!-- <header class="flex items-center justify-between p-6 glass-white-shadow-divider">
    <div class="flex items-center">
      <div>
        <h2 class="text-lg font-semibold text-gray-900 font-aldrich uppercase">{{ tournament.name }}</h2>
      </div>
    </div>
  </header> -->

  <div class="p-2 rounded-md overflow-hidden bg-glass-white-200 border-glass-white-900">
    <div class="mb-2 rounded-md p-2 py-3 bg-glass-black-200 font-aldrich text-gray-200 flex justify-between items-center">
      <div class="flex items-center">
        <span class="starts-at-label text-lg -mt-1 mr-3 font-agency uppercase text-gray-300">Starts At:</span>
        <span class="starts-at text-lg align-bottom">{{ startsAt() }}</span>
      </div>

      <div class="flex items-center space-x-2">
        <span class="icon-wrapper p-1 hover:bg-glass-white-200 rounded-md self-center hover-transition-2 text-glass-white-600">
          <Tooltip :text="tournament.details" position="bottom left"><InformationCircleIcon class="h-8 w-8 self-center"></InformationCircleIcon></Tooltip>
        </span>
        <a class="twitch-icon-link p-1 hover:bg-glass-white-400 rounded-md self-center hover-transition-2" href="https://twitch.tv">
          <Tooltip text="Watch on Twitch!" position="left"><TwitchIcon svgClass="h-8 w-8 self-center"></TwitchIcon></Tooltip>
        </a>
      </div>
    </div>
    <iframe class="rounded-md border-4 border-glass-black-200 shadow-inner" :src="iframeSrc()" width="100%" height="700" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
  </div>
</template>

<script>
import TemplateHelpers from '@/components/mixins/template_helpers';
import TwitchIcon from '@/components/icons/twitch_icon';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';

import Tooltip from '@/components/ui/tooltip';

export default {
  name: 'Tournament',
  props: ['tournament'],
  mixins: [ TemplateHelpers ],
  components: {
    TwitchIcon, InformationCircleIcon,
    Tooltip
  },
  methods: {
    iframeSrc() {
      return `${process.env.VUE_APP_CHALLONGE_URL}${this.tournament.challonge_id}/module?theme=2`;
    },
    startsAt() {
      return this.formatLocalDateTime(this.tournament.starts_at)
    }
  },
  async created() {
    console.log(InformationCircleIcon);
  }
}
</script>

<style>
</style>
