import {BaseModel, Model} from './base_model';

@Model({
  type: 'notification',
  url: 'api/front/notifications',
  attributes: {
    message: {},
    sender_id: {},
    data: {},
    user_id: {},
    record_id: {},
    record_type: {},
    sender_name: {},
    sender_image_url: {},
    kind: {},
    created_at: {}
  }
})
export class Notification extends BaseModel {
  async acceptTeamInvitation() {
    let result = await this.memberAction('accept_team_invitation', {

    });
    console.log(result);
  }

  async declineTeamInvitation() {
    let result = await this.memberAction('decline_team_invitation', {

    });
    console.log(result);
  }
}
