import {BaseModel, Model} from './base_model';

@Model({
  type: 'clip',
  url: 'api/front/clips',
  attributes: {
    url: {},
    platform: { serialize: false },
    title: {},
    user_id: {}
  }
})

export class Clip extends BaseModel {

}
