import { createApp } from 'vue'
import './boot/axios';
import App from './App.vue'
import router from './router'
import './assets/styles/tailwind.css';
import './models.js';
import _ from 'lodash';
import Directives from './directives';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

document.documentElement.classList.add('dark');
const app = createApp(App);
app.use(router);
app.use(VCalendar);

_.each(Directives, (v, k) => {
  app.directive(k, v);
})

app.mount('#app')

// new Vue({
//   render: h => h(App),
// }).$mount('#app');
