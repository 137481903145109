<template>
  <div class="transition-page-inner-wrapper">
    <TeamShowSeasonData v-for="(rating, season) in parent.season_ratings" :key="season" :title="'Season ' + season + ' Stats'" :season="season" :rating="rating" :matches="parent.season_matches[season]"></TeamShowSeasonData>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import {Subscriptions} from '@/lib/subscriptions';
import TeamShowSeasonData from './_season_data';

export default {
  name: 'TeamShowMatches',
  components: {
    TeamShowSeasonData
  },
  props: ['parent'],
  methods: {
  },
  async created() {
    window.team_show_players = this;
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      team: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
.team-show {

}
</style>
