<template>
  <tr class="ui-table-1-row team-item" :class="rating.team.status">
    <td class="tracking-wider text-grey-100 text-center px-3 py-1.5 font-bold sm:text-2xl md:text-3xl text-shadow-md-black-inset">{{rating.rank}}</td>
    <td class="px-1 py-1.5 w-16">
      <Tooltip :text="divisionName(rating.division)" :position="'bottom'">
        <img loading="lazy" class="h-14 w-14" :src="divisionIcon()" />
      </Tooltip>
    </td>
    <td class="py-1 px-1 flags">
      <div :class="flagsClassName()" class="m-0 p-0 flex flex-col items-center justify-center h-full">
        <div class="leading-none -mt-0.5 p-0 country-flag" v-for="(flag, index) in flags()" :key="index">
          <Tooltip :text="countryForFlag(flag)" :position="'bottom'">
            {{flag}}
          </Tooltip>
        </div>
      </div>
    </td>
    <td class="whitespace-nowrap py-5 px-0 sm:px-1 auto">
      <div class="flex items-center justify-between"> <!-- Use justify-between to push content to the sides -->
        <div class="flex items-center flex-grow"> <!-- Allow this div to grow and push the awards right -->
          <div class="h-8 w-8 sm:h-11 sm:w-11 flex-shrink-0">
            <img loading="lazy" class="h-8 w-8 sm:h-11 sm:w-11 rounded-full" :src="rating.image_url" alt="" />
          </div>
          <div class="ml-1 sm:ml-2">
            <span class="hover:text-white font-medium flex items-middle">
              <!-- <Tooltip :text="'Team Status: ' + rating.team.status" :position="'bottom'"> -->
                <span>{{ rating.team.name }}</span>
              <!-- </Tooltip> -->
            </span>
          </div>
        </div>

        <div class="flex items-center justify-end h-[36px]">
          <Award v-for="award in rating.team.awards" :record="award" :key="award.id"></Award>
        </div>
      </div>
    </td>
    <td class="whitespace-nowrap px-1 sm:px-3 py-5 w-16 text-xl"><span>{{ rating.r1 }}</span></td>
    <td class="whitespace-nowrap px-1 sm:px-3 py-5 w-16 text-xl"><span>{{ rating.r2 }}</span></td>
    <td class="whitespace-nowrap px-1 sm:px-3 py-5 w-16 text-xl"><span>{{ rating.r3 }}</span></td>
    <td class="whitespace-nowrap px-1 sm:px-3 py-5 w-20 font-bold text-2xl">{{rating.score}}</td>
  </tr>
</template>
<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import UIComponents from '@/components/ui';
import * as Models from '@/models';
import BaseMixin from '@/components/mixins/base_mixin';
import TemplateHelpers from '@/components/mixins/template_helpers';
import Award from './item/award';

export default {
  name: 'StandingsIndexItem',
  mixins: [BaseMixin, TemplateHelpers],
  components: {
    Award,
    ...UIComponents
  },
  props: ['record', 'rating', 'maxPlayers'],
  watch: {},
  methods: {
    divisionName(name) {
      let _name = name.split("_")
      return `${_name[0]} ${_name[1]}`
    },
    divisionIcon() {
      return this.imageUrl(`icons/${this.rating.division}.png`);
    },
    flagsClassName() {
      return `flags-${this.flags().length}`;
    },
    flags() {
      return _.uniq(this.rating.team.flags);
    }
  },
  async created() {
    window.standings_index_item = this;
  },
  setup() {
    return {
    }
  }
}
</script>

<style>
.team-item.inactive {
  opacity: 0.5;
}
</style>
