<template>
  <div class="home border-b-0 section events">
    <h1 class="title">Upcoming Events and Casts</h1>
  </div>

  <div>
    <section class="home section events">
      <ol class="divide-y divide-glass-white-100 text-sm leading-6 ">
        <!-- Events Header-->
        <li class="py-4 sm:flex font-bold">
          <p class="w-40 flex-none">Date</p>
          <p class="mt-2 flex-auto sm:mt-0">Event Name</p>
          <p class="flex-none sm:ml-6">Start Time</p>
        </li>
        <!-- Events List -->
        <li v-for="item in events" :key="item.name" class="py-4 sm:flex">
          <time class="w-40 flex-none">{{ formatDateForTimestamp(item.at) }}</time>
          <p class="mt-2 flex-auto sm:mt-0 text-glass-white-900 hover:text-glass-white-600"><router-link :to="item.href">{{ item.name }}</router-link></p>
          <p class="flex-none sm:ml-6">
            <time>{{formatTimeForTimestamp(item.at)}}</time>
          </p>
        </li>
      </ol>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue';
import TemplateHelpers from '@/components/mixins/template_helpers';
// const TWITCH_URL = process.env.VUE_APP_TWITCH_URL;

const EVENTS = [
  { name: 'Website Launch', href: '#', at: new Date('2023-12-22T23:00:00-05:00').getTime() },
  { name: 'Racket Club Season 0 Tournament', href: '/leagues/racket-club/tournaments', at: new Date('2024-01-06T12:00:00-07:00').getTime() },
  { name: 'Racket Club Season 1 Start (Doubles and Singles)', href: '/leagues/racket-club', at: new Date('2024-01-22T12:00:00-07:00').getTime() },
  { name: 'Nock Season 1 Start (2v2)', href: '/leagues/nock', at: new Date('2024-02-05T12:00:00-07:00').getTime() },
  { name: 'Gravity League Season 1 Start (1v1)', href: '/leagues/gravity-league', at: new Date('2024-02-19T12:00:00-07:00').getTime() }
];

export default {
  name: 'HomePageEvents',
  mixins: [ TemplateHelpers ],
  async created() {
  },
  setup() {
    return {
      events: ref(EVENTS)
    }
  }
}
</script>

<style>
.home .events {
  @apply font-aldrich text-xl text-gray-200 p-4 glass-white-shadow-divider;
}
</style>
