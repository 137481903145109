<template>
  <div class="transition-page-inner-wrapper w-full h-full">
    <div class="admin-index min-h-full flex flow-root backdrop-blur-sm px-4 flex-grow w-full">
      <div class="section-header-1 border-b border-glass-white-200 mt-6 min-w-0 flex-1 text-shadow-white-inset font-aldrich flex justify-between items-center">
        <h1 class="truncate text-2xl">Admin Index</h1>

        <div class="actions">
          <button @click="generateMatches()" class="inline-flex items-center menu-button-glass-black-1 py-12 text-lg uppercase">
            <PlusIcon class="h-5 w-5 mr-1" />
            Generate Matches
          </button>
        </div>
      </div>

      <AdminIndexGenerateMatchesFormPopout v-if="generate_matches_visible" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot  } from '@headlessui/vue';
import { XMarkIcon, UserGroupIcon } from '@heroicons/vue/24/outline';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/vue/20/solid';
import TemplateHelpers from '@/components/mixins/template_helpers';
// import MyTeamsFormPopout from './teams/_form_popout';

export default {
  name: 'AdminIndex',
  components: {
     Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
     XMarkIcon, EllipsisVerticalIcon, PlusIcon, UserGroupIcon
  },
  props: ['parent'],
  methods: {
    generateMatches() {
      this.generate_matches_visible = true;
    },

    async save() {
      try {
        await this.record.save();
        this.teams.push(this.record);
        this.record = false;
      } catch(e) {

      }
    }
  },
  async created() {

    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      generate_matches_visible: ref(false),
      record: ref(false),
      is_loaded: ref(false)
    }
  }
}
</script>

<style>

</style>
