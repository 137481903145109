<template>
  <Popover container_class="flex items-center justify-center" position="bottom">
    <template v-slot:button>
      <div class="flex items-center justify-center text-center">
        <InformationCircleIcon class="h-6 w-6 ml-1" />
      </div>
    </template>

    <template v-slot:content>
      <div class="popover-inner-content min-w-[320px]">
        <div class="popover-title font-agency uppercase text-xl border-b border-gray-200 px-4 py-4 mb-0">Match Result</div>

        <div class="round-results-wrapper">
          <div class="flex justify-between p-4">
            <div class="home_team flex" :class="$parent.isHomeTeamWinner(match) ? 'winning-team' : ''">
              <div class="flex flex-col items-center">
                <div class="flex-shrink-0">
                  <img class="h-10 w-10 rounded-full" :src="match.home_team.image_url" alt="" />
                </div>
                <div class=" text-center mt-2 min-w-0">
                  <p class="text-sm font-medium text-gray-900">{{ match.home_team.name }}</p>
                </div>
              </div>
            </div>

            <div class="away_team flex" :class="!$parent.isHomeTeamWinner(match) ? 'winning-team' : ''">
              <div class=" flex flex-col items-center">
                <div class="flex-shrink-0">
                  <img class="h-10 w-10 rounded-full" :src="match.away_team.image_url" alt="" />
                </div>
                <div class="flex text-center mt-2 min-w-0">
                  <p class="text-sm text-center font-medium text-gray-900">{{ match.away_team.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="round flex justify-between p-4 text-lg border-b border-gray-100 font-aldrich" v-for="(round, index) in match.result.round_results" :key="index">
            <div class="home-team-score text-center flex" :class="isHomeTeamWinner(round) ? 'round-winner' : ''">
              {{round.home_team_score}}
              <ArrowLongUpIcon v-if="isHomeTeamWinner(round)" class="ml-2 mt-1 w-5 h-5 text-green-700" />
              <ArrowLongDownIcon v-if="!isHomeTeamWinner(round)" class="ml-2 mt-1 w-5 h-5 text-red-600" />
            </div>

            <div class="away-team-score text-center flex" :class="!isHomeTeamWinner(round) ? 'round-winner' : ''">
              {{round.away_team_score}}
              <ArrowLongUpIcon v-if="!isHomeTeamWinner(round)" class="ml-2 mt-1 w-5 h-5 text-green-700" />
              <ArrowLongDownIcon v-if="isHomeTeamWinner(round)" class="ml-2 mt-1 w-5 h-5 text-red-600" />
            </div>
          </div>
        </div>

        <div class="total-scores-footer p-4">
          <div class="flex justify-between text-lg font-medium">
            <div class="font-agency uppercase">Final Score:</div>
            <div class="flex font-aldrich">
              <div>{{ totalScores.homeTeam }}</div>
              <div class="mx-4">-</div>
              <div>{{ totalScores.awayTeam }}</div>
            </div>
          </div>
        </div>

        <div class="wins-footer p-4 border-t border-gray-200">
          <div class="flex justify-between text-lg">
            <div class="font-agency uppercase">Rounds Won:</div>
            <div class="flex font-aldrich">
              <div>{{ winsCount.homeTeamWins }}</div>
              <div class="mx-4">-</div>
              <div>{{ winsCount.awayTeamWins }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { formatDate } from '@/lib/template_helpers';
import { InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon } from '@heroicons/vue/24/solid';
import * as TemplateHelpers from '@/lib/template_helpers';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';
import UIComponents from '@/components/ui';

export default {
  name: 'TeamShowRoundResults',
  components: {
    InformationCircleIcon, ArrowTopRightOnSquareIcon, ArrowLongUpIcon, ArrowLongDownIcon,
    ...UIComponents
  },
  props: ['parent', 'match'],
  mixins: [TemplateHelpersMixin],
  computed: {
    totalScores() {
      return this.match.result.round_results.reduce((acc, round) => {
        acc.homeTeam += round.home_team_score;
        acc.awayTeam += round.away_team_score;
        return acc;
      }, { homeTeam: 0, awayTeam: 0 });
    },
    winsCount() {
      return this.match.result.round_results.reduce((acc, round) => {
        if (round.home_team_score > round.away_team_score) {
          acc.homeTeamWins++;
        } else if (round.home_team_score < round.away_team_score) {
          acc.awayTeamWins++;
        }
        return acc;
      }, { homeTeamWins: 0, awayTeamWins: 0 });
    }
  },
  methods: {
    isHomeTeamWinner(round) {
      return round.home_team_score > round.away_team_score;
    }
  },
  async created() {
    window.round_results = this;

    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      team: ref([]),
      is_loaded: ref(false),
      stats: ref([])
    }
  }
}
</script>

<style>
.winning-team {
  @apply bg-green-700 bg-opacity-50 rounded-md;
}
.home_team, .away_team {
  @apply p-4 rounded-md;
}
</style>
