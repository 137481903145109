<template>
  <div class="relative px-10 py-10">
    <div class="absolute inset-0 overflow-hidden"></div>
    <div class="relative mx-auto flex max-w-5xl flex-col text-left">
      <h2 class="text-4xl font-bold tracking-tight text-glass-white-900 font-agency text-secondary uppercase text-center">What is XR?</h2>
        <p class="mt-6 text-md leading-6 text-glass-white-800">
          XR stands for "extended reality," which covers various forms of immersive technologies, including virtual reality (VR), augmented reality (AR), and mixed reality (MR).
        </p>
        <p class="mt-4 text-md leading-6 text-glass-white-800">
          Extended reality uses technology to augment experiences or environment in the following ways:
          <br>1) VR: immersion in a virtual environment
          <br>2) AR: adds to or augments physical surroundings
          <br>3) MR: blends physical and digital worlds to create an immersive experience
        </p>
        <p class="mt-4 text-md leading-6 text-glass-white-800">
          Development of immersive tech has been happening for decades, but it's only after the release of the first commercial VR headsets in 2016 that consumers have become more aware of options for entertainment, work, gaming, etc.
        </p>
        <p class="mt-4 text-md leading-6 text-glass-white-800">
          One area of growth has been in immersive sport with both AR and VR games. With the release of Quest 3 in 2023, there have been more mixed reality sport games coming into the mix as well.
        </p>
        <p class="mt-4 text-md leading-6 text-glass-white-800">
          All of these are great options that combine physicality and technology in such a way that we continue to use immersive technologies for better health, more advanced gaming, and emerging options for today's athletes.
        </p>
      </div>
  </div>
<!-- Code from Tailwind -->

</template>

<!-- Script for stats from Tailwind -->


<script>
  export default {
    name: 'HomePageXR',
    async created() {
    },
  }
</script>

<style>
.about .define_xr {
}
</style>
