<template>
  <ConfirmActionModal v-if="confirm_kick_player"
                      :text="'This will remove '+player.name+' from your team. Please confirm you wish to do this.'"
                      :title="'Kick Player From Team?'"
                      confirmText="Kick Player"
                      declineText="Cancel"
                      :onConfirm="onKickConfirm" />

  <div class="relative flex justify-between items-center space-x-3 rounded-lg item-hover-1 hover-transition-1 hover:text-white border border-gray-300 bg-glass-white-500 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
    <div class="flex-shrink-0">
      <img loading="lazy" class="h-10 w-10 rounded-full" :src="player.image_url" alt="" />
    </div>
    <div class="min-w-0 flex-1 text-shadow-white-inset">
      <span class="absolute inset-0" aria-hidden="true" />
      <p class="text-sm font-medium text-gray-900 hover-text-secondary">{{ player.name }}</p>
      <p class="truncate text-sm text-gray-500 hover-text-white">{{ playerRole() }} / Joined {{ displayDate(player.created_at) }}</p>
    </div>

    <div class="min-w-0 flex" v-if="userIsCaptain">
      <Menu as="div" class="relative inline-block text-left z-20" v-if="playerRole() !== 'Captain'">
        <div>
          <MenuButton class="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
            <span class="sr-only">Open options</span>
            <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a @click="kickPlayer()" :class="[active ? 'bg-glass-secondary-800 text-gray-100' : 'text-gray-700', 'flex px-4 py-2 text-sm']">
                  <span>Kick Player From Team</span>
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>

      <button v-if="false" @click="actions()" class="p-2 rounded-md bg-glass-white-50 text-gray-800 hover:bg-glass-primary-700 hover:ring-2 hover:ring-glass-primary-900">
        <EllipsisVerticalIcon class="h-6 w-6" />
      </button>
    </div>
  </div>
</template>

<script>
// import TemplateHelpers from '@/components/mixins/template_helpers';
import _ from 'lodash';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import { EllipsisVerticalIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import ConfirmActionModal from '@/components/ui/confirm_action_modal';
import Mixins from '@/components/mixins';

export default {
  name: 'MyTeamsPlayer',
  props: ['player', 'team', 'userIsCaptain'],
  mixins: _.values(Mixins),
  components: {
    EllipsisVerticalIcon, Menu, MenuButton, MenuItems, MenuItem, ConfirmActionModal
  },
  methods: {
    leagueFor(team) {
      return this.$root.findLeagueById(team.league_id);
    },
    edit() {
      console.log('edit');
      this.$parent.record = this.team;
      this.$parent.form_visible = true;
    },
    kickPlayer() {
      this.confirm_kick_player = true;
    },
    async onKickConfirm() {
      await this.player.memberAction('kick');
      this.showSuccessMessage(`${this.player.name} has been removed from your team`);
      this.$root.$$reload();
    },
    playerRole() {
      if(this.team.captain_id === this.player.id) {
        return "Captain"
      } else if(this.team.co_captain_id === this.player.id) {
        return "Co-Captain"
      } else if(this.player.team_id !== this.team.id) {
        return "Former Player"
      } else {
        return "Player"
      }
    }
  },
  async created() {
    // window.team_player = this
  },
  data() {
    return {
      confirm_kick_player: ref(false)
    }
  }
}
</script>

<style>
</style>
