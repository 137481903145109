<!-- This file combines Leagues and stats to help with the tabling in home.vue -->
<!-- This is a nested componant-->
<template>
  <div class="text-shadow-lg flex-grow" v-if="is_loaded">
    <HomeLeagues as="div" />
    <HomeStats as="div" />
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import HomeLeagues from './leagues.vue';
import HomeDiscord from './discord.vue';
import HomeStats from './stats.vue';

export default {
  name: 'HomePageLeagues_Stats',
  components: {
    HomeLeagues,
    HomeDiscord,
    HomeStats
  },
  async created() {

  },
  setup() {
    return {
      is_loaded: ref(true)
    }
  }
}
</script>

<style>
.home .leagues_stats {

}
</style>
