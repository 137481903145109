import {BaseModel, Model} from './base_model';

@Model({
  type: 'match_result',
  url: 'api/front/match_results',
  attributes: {
    differential: { default_value: 0 },
    outcome: { default_value: "completed" },
    submitter_id: {},
    confirmer_id: {},
    winner_id: {},
    loser_id: {},
    match_id: {},
    round_results: {}
  },
  belongs_to: {
    match: { type: 'Match' },
    winner: { type: 'Team' },
    loser: { type: 'Team' }
  }
})

export class MatchResult extends BaseModel {
}
