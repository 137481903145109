<template>
    <LiveCastNotification v-for="cast in casts" :key="cast.id" :cast="cast" />
</template>

<script>
/* eslint-disable */
import LiveCastNotification from './live_cast_notification';
import { BaseModel } from '@/models/base_model';
import _ from 'lodash';

//poll for 5 minutes then stop, so if ppl leave browser tabs open it's not slamming server
// const POLLING_MAX_TIME = 300000;
const POLLING_MAX_TIME = 100000;
const POLLING_INTERVAL = 10000;

export default {
  components: {
    LiveCastNotification
  },
  data() {
    return {
      casts: [],
      pollingInterval: null,
      stopPollingTimeout: null,
    };
  },
  watch: {
    '$route': 'resetPolling'
  },
  created() {
    this.startPolling();
    this.setStopPolling();
  },
  beforeDestroy() {
    this.clearPolling();
  },
  methods: {
    startPolling() {
      this.pollingInterval = setInterval(async () => {
        const response = await this.fetchLiveCasts();
        const data = response.data;
        this.casts = _.filter(data, cast => !localStorage.getItem(`xrl:seen_cast_${cast.id}`));
      }, POLLING_INTERVAL);
    },
    setStopPolling() {
      this.stopPollingTimeout = setTimeout(() => {
        this.clearPolling();
      }, POLLING_MAX_TIME);
    },
    async fetchLiveCasts() {
      return BaseModel.$axios.get('live_casts');
    },
    resetPolling() {
      this.clearPolling();
      this.startPolling();
      this.setStopPolling();
    },
    clearPolling() {
      if (this.pollingInterval) clearInterval(this.pollingInterval);
      if (this.stopPollingTimeout) clearTimeout(this.stopPollingTimeout);
    },
  },
}
</script>
