<template>
<div class="py-2 sm:px-6 lg:px-4 flex-grow user-standings">
  <StandingsIndexNavigation :pagination="pagination" :onPageChanged="fetch"></StandingsIndexNavigation>
  <Transition name="fade-transition-1" mode="in-out">
    <GlobalLoading v-if="!is_loaded" />
  </Transition>
  <Transition name="page-transition-2" mode="in-out">
  <div class="flow-root text-shadow-lg flex-grow" v-if="is_loaded">
    <div class="relative -mx-6 -my-2 sm:-mx-6 lg:-mx-6">
      <div class="inline-block min-w-full py-2 align-middle sm:px-1 lg:px-1 overflow-x-visible">
        <table class="ui-table-1">
          <thead class="">
             <tr>
              <th scope="col" class="text-center tracking-wide pl-0">Rank</th>
              <th scope="col" class="">Division</th>
              <th scope="col" class="">Player</th>
              <th scope="col" class="">Games</th>
              <th scope="col" class="">Record</th>
              <th scope="col" class="tracking-wide">Rating</th>
            </tr>
          </thead>
          <tbody class="">
            <StandingsIndexItem :rating="rating" v-for="rating in ratings" :key="rating.id" />
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </Transition>
</div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import Pagination from '@/components/ui/Pagination';
import StandingsIndexNavigation from './index/navigation';
import StandingsIndexItem from './index/item';
import UIComponents from '@/components/ui';
import BaseMixin from '@/components/mixins/base_mixin';
import TemplateHelpers from '@/components/mixins/template_helpers';
import { compactObject } from '@/lib/helpers';

export default {
  name: 'UserStandingsIndex',
  mixins: [ BaseMixin, TemplateHelpers ],
  components: {
    Pagination, StandingsIndexNavigation, StandingsIndexItem, ...UIComponents
  },
  data() {
    return {
      pagination: {}
    }
  },
  methods: {
    async fetch(query={}) {
      this.is_loaded = false;
      this.currentQuery = compactObject({
        ...this.currentQuery,
        ...query,
        by_region: this.region,
        per_page: 100
      });

      this.ratings = await Models.UserRating.search(this.currentQuery);
      this.pagination = this.ratings.meta.pagination;
      this.is_loaded = true;
    }
  },
  async created() {
    this.$$initialize();
    this.$root.inner_content_loading = true;
    window.standings_index = this;

    if(this.$router.currentRoute.value.query) {
      this.currentQuery = {
        ...this.$router.currentRoute.value.query
      }
    }

    await this.fetch({
      ...this.currentQuery,
      by_region: this.region,
      per_page: 100
    });
    this.is_loaded = true;
    this.$root.inner_content_loading = false;
  },
  mounted() {
    console.log(this.$route.params.slug);
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(new_params, old_params) {
        _.assign(this, new_params);
        console.log(new_params, old_params);
        this.fetch();
      }
    },
    '$router.currentRoute.value.query'(data) {
      console.log('query changed', data);
    },
    '$router.currentRoute.value.query.per_page'(per_page) {
      this.fetch({per_page, page: page});
    },
    '$router.currentRoute.value.query.page'(page) {
      this.fetch({page: page});
    }
  },
  setup() {
    return {
      ratings: ref([]),
      region: ref("na"),
      currentQuery: ref({
        by_region: 'na'
      }),
      is_loaded: ref(false),
    }
  }
}
</script>

<style>
.user-standings th {
  @apply px-1 sm:px-3 py-3.5 text-left font-semibold uppercase tracking-wide;
}
</style>
