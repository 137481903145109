<template>
  <div class="transition-page-inner-wrapper">
    <div v-if="is_loaded">
      <clip-component :clip="clip" v-for="clip in clips" :key="clip.id" class="mb-4" />
    </div>
    <div class="py-80" v-else>
      <Spinner></Spinner>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import ClipComponent from '@/components/shared/clip';

export default {
  name: 'PlayerShowClips',
  components: {
    ClipComponent
  },
  props: ['parent'],
  data() {
    return {
      clips: [],
      is_loaded: false
    }
  },
  methods: {
    isTwitchClip(clip) {
      return clip.platform === "twitch" && _.includes(clip.url.split("."), "https://clips");
    },
    loadTikTokScript() {
      if (!document.querySelector('script[src="https://www.tiktok.com/embed.js"]')) {
        const script = document.createElement('script');
        script.src = "https://www.tiktok.com/embed.js";
        script.async = true;
        document.body.appendChild(script);
      }
    },
    getTikTokUser(url) {
      const match = url.match(/https:\/\/www\.tiktok\.com\/@([^\/]+)\/video\/(\d+)/);
      if (match && match.length > 1) {
        return match[1];
      }
      return null;
    },
    getTikTokVideoId(url) {
      const match = url.match(/https:\/\/www\.tiktok\.com\/@([^\/]+)\/video\/(\d+)/);
      if (match && match.length > 2) {
        return match[2];
      }
      return null;
    }
  },
  async created() {
    this.clips = await Models.Clip.search({
      by_user_id: this.parent.player.user_id
    });

    window.player_show_clips = this;
    this.is_loaded = true;
  },
  mounted() {
    this.parentDomain = window.location.hostname;
    this.loadTikTokScript();
  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-iframe-container blockquote {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tiktok-embed {
  height: 575px;
}

.tiktok-iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0;
}
</style>
