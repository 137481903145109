<template>
  <div id="app-inner" v-if="is_loaded" :class="game_name" class="app-inner site-bg flex-grow flex" style="z-index: 1;">
    <TopNav />

    <div class="flex-grow flex pyt-2 px-4">
      <!-- <div class="main-router-wrapper flex flex-1 overflow-y-auto overflow-x-visible pt-16 flex-grow bg-gradient-to-b from-glass-black-50 to-glass-black-200"> -->
      <div class="main-router-wrapper flex flex-1  pt-16 flex-grow bg-gradient-to-b from-glass-black-50 to-glass-black-200">
        <Transition name="page-transition-2" mode="out">
          <router-view :key="route_key" />
        </Transition>
      </div>
    </div>
  </div>

  <div id="tools" v-if="isToolsRoute()">
    <router-view :key="route_key" />
  </div>

  <LiveCasts />

  <Notifications class="z-40" />
  <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
    <div @click="toggleOverlay()" style="z-index: 5;" class="fixed inset-0 bg-glass-black-500 backdrop-blur-sm transition-opacity z-5" v-if="overlay_visible" />
  </transition>
</template>

<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import * as Models from '@/models';
import TopNav from './components/layout/top_nav.vue';
import Notifications from './components/notifications';
import {Subscriptions} from '@/lib/subscriptions';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Helpers } from 'traxjs';
import LiveCasts from './components/casts/live_casts';
import Mixins from '@/components/mixins';

export default {
  name: 'App',
  mixins: _.values(Mixins),
  components: {
    TopNav, Notifications, LiveCasts,
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot
  },
  methods: {
    isToolsRoute() {
      return _.includes(this.$route.path, 'tools');
    },
    async signOut() {
      await this.current_user.destroy();
      this.current_user = { signed_in: false }
      this.signed_in = false;
    },
    $$reload() {
      this.route_key += 1;
    },
    toggleOverlay() {
      console.log('should be toggling');
      this.overlay_visible = !this.overlay_visible;
    },
    async fetchLeagues() {
      this.leagues = await Models.League.fetchAll();
      this.team_leagues = _.filter(this.leagues, {is_singles: false});
    },
    logos() {
      return Helpers.fetch(this, '_logos', () => {
        const context = require.context('@/assets/images/icons/games', false, /\.(png|jpe?g|svg)$/);

        return context.keys().reduce((images, key) => {
          const filename = key.split('/').pop().replace(/\.\w+$/, '');
          images[filename] = context(key);
          return images;
        }, {});
      })
    },
    logoFor(key) {
      return this.logos()[key];
    },
    findLeagueById(id) {
      return _.find(this.leagues, {id: id});
    },
    initializeTwitch() {
      const script = document.createElement('script');
      script.src = "https://embed.twitch.tv/embed/v1.js";
      script.addEventListener('load', () => {
        this.$$broadcast("twitch_loaded", {ready: true});
        this.twitch_loaded = true;
      });
      document.body.appendChild(script);
    }
  },
  watch: {
    '$route.params.slug': {
      immediate: true,
      async handler(new_value, old_value) {
        if(new_value) {
          if(this.leagues.length === 0) {
            await this.fetchLeagues();
          }
          this.league = _.find(this.leagues, (l) => { return l.subdomain === new_value});
          this.game = this.league.game;
          // this.game_name = this.league.game.attributes.slug;
          this.game_name = this.league.game.attributes.slug;
          this.in_league = true;
        } else {
          this.league = ref({});
          this.game = ref({});
          this.game_name = "xrl";
          this.in_league = false;
        }
      }
    }
  },
  async created() {
    this.Models = Models;
    await this.fetchLeagues();
    // this.leagues = await Models.League.fetchAll();
    //id doesnt matter since we're finding by cookies anyways
    this.current_user = await Models.CurrentUser.find({id: '123'});
    this.signed_in = this.current_user.attributes.id !== undefined;

    this.subscriptions = [];
    //dont think we need this anymore but leaving for now in case something breaks
    // let s1 = Subscriptions.$$on("league.loaded", (l) => {
    //   this.league = l;
    //   console.log('in league loadedd', l);
    //   this.game = this.league.game;
    // })
    //
    // this.subscriptions.push(s1);

    if(this.$route.params.slug) {
      let l = _.find(this.leagues, (l) => { return l.subdomain === this.$route.params.slug})
      this.league = l;
      this.game = this.league.game;
      this.game_name = this.league.game.attributes.slug;
      this.in_league = true;
    }

    window.App = this;
    // this.loadTikTokScript();
    if(!this.isToolsRoute()) {
      this.is_loaded = true;
    }
  },
  setup() {
    return {
      twitch_loaded: ref(false),
      signed_in: ref(false),
      is_loaded: ref(false),
      leagues: ref([]),
      league: ref({}),
      in_league: ref(false),
      game: ref({}),
      game_name: ref("xrl"),
      current_user: ref({}),
      overlay_visible: ref(false),
      route_key: ref(0)
    }
  },
  mounted() {
    document.documentElement.classList.add('dark');
    this.initializeTwitch();
  }
}
</script>

<style>
.app-inner.site-bg {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}
/* .app-inner.xrl {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05)), url('https://xrlpro.s3.amazonaws.com/frontend/public/images/bgs/xrl.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */
.app-inner.xrl {
  background: linear-gradient(to right, rgba(0,0,0,0) 5%, rgba(0, 0, 0, 0.35) 10%,  rgba(0, 0, 0, 0.15) 90%, rgba(0, 0, 0, 0.0)), url('https://xrlpro.s3.amazonaws.com/frontend/public/images/bgs/xrl.jpg');
  /* background: linear-gradient(to right, rgba(0,0,0,0) 5%, rgba(0, 0, 0, 0.35) 10%,  rgba(0, 0, 0, 0.15) 90%, rgba(255, 255, 255, 1.0)), url('/public/images/bgs/xrl.jpg'); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner.racket-club {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05)), url('https://xrlpro.s3.amazonaws.com/frontend/public/images/bgs/racket-club-1.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner.nock {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05)), url('https://xrlpro.s3.amazonaws.com/frontend/public/images/bgs/nock.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner.echo-vr {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5)), url('/public/images/bgs/echo-vr.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner.breachers {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('/public/images/bgs/breachers.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner.ultimechs {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('/public/images/bgs/ultimechs.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner.beat-saber {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('/public/images/bgs/beat-saber.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner.gravity-league {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('https://xrlpro.s3.amazonaws.com/frontend/public/images/bgs/gravity-league.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner.a2 {
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('/public/images/bgs/a2.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner.eleven {
  /* background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2)), url('/public/images/bgs/eleven.jpg'); */
  /* background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(72, 11, 19, 0.2)), url('/public/images/bgs/eleven.jpg'); */
  /* background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(150, 44, 58, 0.7)), url('/public/images/bgs/eleven.png'); */
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2)), url('/public/images/bgs/eleven.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.app-inner {
  width: 100%;
  /* &.racket-club {
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.05)), url('/public/images/bgs/racket-club.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.echo-vr {
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5)), url('/public/images/bgs/echo-vr.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.breachers {
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('/public/images/bgs/breachers.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.ultimechs {
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('/public/images/bgs/ultimechs.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.a2 {
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('/public/images/bgs/a2.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.beat-saber {
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('/public/images/bgs/beat-saber.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.gravity-league {
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75)), url('/public/images/bgs/gravity-league.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  } */
}

.bg-echo {
  position: absolute;
  width: 100%; /* Set the desired width */
  /* height: 500px; /* Set the desired height */
  height: 70%;
  /* background-image: url('/public/bgs/echo.jpeg'); */
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05)), url('/public/bgs/echo.jpeg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-echo::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 70%; /* Adjust the height of the mirrored part */
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url('/public/bgs/echo.jpeg');
  background-repeat: repeat-x;
  background-size: cover;
  transform: scaleY(-1); /* Invert the image */
  opacity: 1.0; /* Adjust the opacity as needed */
  z-index: 1;
}

.scroll {
  overflow: scroll;
}

html, body, #app, .app-inner {
  min-height: 100vh;
}
</style>
