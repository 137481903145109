<template>
  <div class="transition-page-inner-wrapper w-full h-full">
    <div class="h-max-content h-full flow-root backdrop-blur-sm px-4 flex-grow my-leagues w-full">
      <div class="section-header-1 mt-6 min-w-0 flex-1 text-shadow-white-inset font-aldrich">
        <h1 class="truncate text-2xl">Manage Leagues</h1>
      </div>

      <TransitionRoot as="template" :show="joinLeagueOpen">
        <Dialog as="div" class="relative z-10" @close="joinLeagueOpen = false">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="dialog-1">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="close-button" @click="joinLeagueOpen = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <div class="game-logo-wrapper"><img class="h-10 w-10 rounded-full" :src="$root.logoFor(league.slug)" alt="" /></div>
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 mb-4 pb-4 pt-2">{{league.name}}</DialogTitle>
                      <div class="mt-2">
                        <div v-html="league_descriptions[league.id]" class="markdown-body"></div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button v-if="!isInLeague(league)" type="button" class="inline-flex w-full justify-center rounded-md bg-glass-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="joinLeague()">Accept Rules and Join League</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="joinLeagueOpen = false">Cancel</button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>

      <div v-if="is_loaded" class="grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 items-center">
        <div v-for="league in leagues" :key="league.id" class="relative rounded-lg border border-gray-300 bg-glass-white-600 px-6 py-5 shadow-sm hover:border-glass-primary-900">
          <div class="flex items-start justify-between">
            <div class="flex items-center space-x-3">
              <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" :src="$root.logoFor(league.slug)" alt="" />
              </div>

              <div class="min-w-0 flex-1">
                <p class="text-sm font-medium text-gray-900">{{ league.name }}</p>
              </div>

              <!-- <div class="flex-1">
                <p class="text-sm text-gray-700">{{league.overview}}</p>
              </div> -->
            </div>

            <button :disabled="!league.is_active" @click="showJoinLeaguePopup(league)" v-if="!isInLeague(league)" class="inline-flex items-center button-primary-md-1">
              Join
            </button>

            <div v-else class="flex flex-shrink-0 self-center">
              <Menu as="div" class="relative inline-block text-left z-20">
                <div>
                  <MenuButton class="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                    <span class="sr-only">Open options</span>
                    <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                  </MenuButton>
                </div>

                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <a @click="showRetireNotice()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex px-4 py-2 text-sm']">
                          <span>Retire from League</span>
                        </a>
                      </MenuItem>

                      <MenuItem v-slot="{ active }">
                        <a @click="showJoinLeaguePopup(league)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex px-4 py-2 text-sm']">
                          <!-- <CodeBracketIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" /> -->
                          <span>League Rules</span>
                        </a>
                      </MenuItem>

                      <MenuItem v-slot="{ active }">
                        <router-link :to="rootLeaguePathFor(league.subdomain, 'player', playerFor(league).id, 'details')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex px-4 py-2 text-sm']">

                          <span>View Player Profile</span>
                        </router-link>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>

          <div class="mt-2">
            <p class="truncate text-sm text-gray-500">
              {{league.overview}}
            </p>
          </div>
        </div>
      </div>

      <div v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot  } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
import Mixins from '@/components/mixins';

export default {
  name: 'MyLeagues',
  components: {
     Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
     XMarkIcon, EllipsisVerticalIcon
  },
  mixins: _.values(Mixins),
  props: ['parent'],
  data() {
    return {
      clips: [],
      is_loaded: false
    }
  },
  methods: {
    isInLeague(league) {
      return !_.isUndefined(_.find(this.players, {league_id: league.id}));
    },
    playerFor(league) {
      return _.find(this.players, {league_id: league.id});
    },
    async joinLeague() {
      let new_player = await Models.Player.create({
        user_id: this.$root.current_user.id,
        league_id: this.league.id
      });

      await this.$$initializeComponent();
      this.showSuccessMessage(`You have joined the ${this.league.display_name} league`, "Congratulations!");
      this.$root.current_user.league_ids.push(this.league.id);
      this.league = {};
      this.joinLeagueOpen = false;
    },
    showJoinLeaguePopup(league) {
      this.league = league;
      console.log("LEAGUE IS", league);
      this.joinLeagueOpen = true;
    },
    maybeShowJoinLeaguePopup() {
      const join_league_id = this.$route.query.join_league_id;

      if (join_league_id) {
        const league_to_join = this.leagues.find(league => league.id === join_league_id);
        if (league_to_join) {
          this.showJoinLeaguePopup(league_to_join);
        }
      }
    },
    showRetireNotice() {
      this.$$broadcast("notification.info", {
        ttl: -1,
        heading: "How to Retire from League",
        body: "For now, to retire from a league, visit the my teams page. Click Manage Team for your team in that league, and set your team status to inactive. If you are not on a team, or if you haven't set your team status to active during the current season, you are effectively already retired."
      })
    },
    async $$initializeComponent() {
      this.is_loaded = false;
      this.league_descriptions = {}
      this.leagues = this.$root.leagues;
      _.each(this.leagues, (league) => {
        this.league_descriptions[league.id] = this.compileMarkdown(league.description || "");
      })

      this.players = await Models.Player.search({
        by_user_id: this.$root.current_user.id
      })

      this.is_loaded = true;
    }
  },
  async created() {
    this.ensureCurrentUserLoggedIn();
    await this.$$initializeComponent();
    this.maybeShowJoinLeaguePopup();
    window.my_leagues = this;
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      league_descriptions: ref({}),
      league_description: ref(""),
      joinLeagueOpen: ref(false),
      league: ref({}),
      is_loaded: ref(false)
    }
  }
}
</script>

<style>

</style>
