import {BaseModel, Model} from './base_model';

@Model({
  type: 'tradeshow_ranking',
  url: 'api/front/tradeshow_rankings',
  attributes: {
    rating: { default_value: 0 },
    rank: {},
    win_count: { default_value: 0 },
    loss_count: { default_value: 0 },
    draw_count: { default_value: 0 },
    season_id: {},
    division: {}
  }
})

export class TradeshowRanking extends BaseModel {

}
