/* eslint-disable */
import MarkdownIt from 'markdown-it';
import markdownItGithubToc from 'markdown-it-github-toc';
import Schema from '@/constants/schema';
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const full_months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const CountryData = _.values(Schema.User.country.choices);
import { Helpers } from 'traxjs';

function formatDate(timestamp) {
  const date = new Date(timestamp);
  const month = months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return {month, day, year};
  // return `${month} ${day}, ${year}`;
}

function formatLocalDateTime(timestamp) {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
    timeZoneName: 'short',
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}

function formatLocalTime(timestamp) {
  const date = new Date(timestamp);

  const options = {
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
    timeZoneName: 'short',
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}

function formatTimeForTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short'
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

function formatDateForTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = {
    weekday: 'long',
    month: 'short',
    day: '2-digit'
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

function formatDateFullMonth(timestamp) {
  const date = new Date(timestamp);
  const month = full_months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return {month, day, year};
  // return `${month} ${day}, ${year}`;
}

function displayDate(timestamp) {
  const date = new Date(timestamp);
  const month = months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
}

function displayDateInline(timestamp) {
  const date = new Date(timestamp);
  const month = months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

function leaguePath() {
  return `/leagues/${this.$route.params.slug}`;
}

function leaguePathFor(...args) {
  return `/leagues/${this.$route.params.slug}/${args.join('/')}`;
}

function rootLeaguePathFor(...args) {
  return `/leagues/${args.join('/')}`;
}

function teamPathFor(team_id, tab='details') {
  return this.leaguePathFor('team', team_id, tab);
}

function playerPathFor(id, tab='details') {
  return this.leaguePathFor('player', id, tab);
}

function userPathFor(id, tab='details') {
  return `/user/${id}/${tab}`;
}

const MARKDOWN_COMPILER = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true
}).use(markdownItGithubToc, {});

function compileMarkdown(md) {
  return MARKDOWN_COMPILER.render(md);
}

function gameImagesForLeagueIds(ids) {
  let keys = _.map(ids, (id) => {
    let league = _.find(this.$root.leagues, {id: id});
    return league.game.image_key;
  })

  return _.map(keys, (k) => { return this.$root.logoFor(k) });
}

function gameImageForLeagueId(id) {
  let league = _.find(this.$root.leagues, {id: id});
  if(!league) { return }
  return this.$root.logoFor(league.game.image_key);
}

function timeAgo(time) {
  const date = new Date(time);
  const now = new Date();
  const seconds = Math.round((now - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);

  if (seconds < 60) return `${seconds} seconds ago`;
  else if (minutes < 60) return `${minutes} minutes ago`;
  else if (hours < 24) return `${hours} hours ago`;
  else return `${days} days ago`;
}

function $$reload() {
  this.is_loaded = false;
  this.$nextTick(() => {
    this.is_loaded = true;
  });
}

async function copyToClipboard(value, success_message='Copied to clipboard') {
  try {
    await navigator.clipboard.writeText(value);
    this.$$broadcast('notification.show', {
      heading: 'Copy Success',
      body: success_message
    })
  } catch(err) {
    console.log(err);
  }
}

function scrollToElement(element_id) {
  const element = document.getElementById(element_id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

function scrollToElementWhenAvailable(element_id) {
  const interval = setInterval(() => {
    const element = document.getElementById(element_id);
    console.log("LOOKING FOR ELEMENT")
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      clearInterval(interval);

      console.log("FOUND ELEMENT");
    }
  }, 1000);
}

function humanize(val) {
  const words = _.split(val, '_');
  return _.join(_.map(words, _.capitalize), ' ');
}

function ensureUserIsCaster() {
  if(!_.includes(this.$root.current_user.roles, 'caster')) {
    this.$router.push({ name: 'Home' });
  }
}

function hexToRgba(hex, opacity) {
  let r = 0, g = 0, b = 0;

  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }

  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

function uppercase(val) {
  return val.toUpperCase();
}

function imageUrl(path) {
  return `https://xrlpro.s3.amazonaws.com/frontend/public/images/${path}`;
}

const CountriesForFlags = {}

function countryForFlag(flag) {
  return Helpers.fetch(CountriesForFlags, flag, () => {
    return _.find(CountryData, (d) => { return d.attributes.flag === flag }).attributes.display_name
  });
}

export default {
  methods: {
    formatLocalDateTime,
    formatDate,
    formatDateFullMonth,
    formatTimeForTimestamp,
    formatDateForTimestamp,
    displayDateInline,
    displayDate,
    leaguePath,
    leaguePathFor,
    rootLeaguePathFor,
    teamPathFor,
    playerPathFor,
    userPathFor,
    compileMarkdown,
    gameImagesForLeagueIds,
    gameImageForLeagueId,
    timeAgo,
    $$reload,
    copyToClipboard,
    scrollToElement,
    scrollToElementWhenAvailable,
    humanize,
    ensureUserIsCaster,
    hexToRgba,
    uppercase,
    imageUrl,
    countryForFlag,
    formatLocalTime
  }
}
