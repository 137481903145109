<template>
  <div class="relative px-10 py-10">
    <div class="xrllogo mb-4"></div>
    <div class="absolute inset-0 overflow-hidden"></div>
    <div class="relative mx-auto flex max-w-3xl flex-col items-center text-center text-white">
      <div class="my-4 mb-2 text-2xl uppercase font-agency text-secondary">Mission:</div>
      <p class="text-lg text-gray-300">
        XR League offers a unique experience for any Virtual, Augmented,
        or Mixed reality consumer, by providing a premier option to meet with players and play your favorite games.
        We desire to maintain a place for organized play and tournaments,
        as well as giving you or your team the recognition you deserve.
        The XRL leadership team strives to be open and inclusive.  This is your league.
      </p>
      <div class="my-4 mb-2 text-2xl uppercase font-agency text-secondary">Vision:</div>
      <p class="text-gray-300 text-lg">
        To be the extended reality (XR) place to go for your virtual,
        augmented and mixed reality gaming and social needs.
        We want grow with future technologies in the XR arena.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AboutHero',
    async created() {
    }
  }
</script>

<style>
.xrllogo {
  background: url('/public/images/logos/xrllogo.png');
  height: 100px;
  position: inherit;
  z-index: 5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.about .hero {

}
</style>
