<template>
  <svg :class="svgClass" fill="currentColor" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitch</title><path d="M2.149 0L.423 4.189v17.223h5.919v2.588h2.588l2.588-2.588h3.882l5.176-5.176V0H2.149zm19.034 14.017l-3.176 3.176h-3.882l-2.588 2.588v-2.588H5.342V1.294h15.841v12.723zM7.726 3.882v7.078h2.588V3.882H7.726zm6.47 0v7.078h2.588V3.882h-2.588z"/></svg>
</template>

<script>
/* eslint-disable */
export default {
  name: 'TwitchIcon',
  props: {
    svgClass: {
      type: String,
      default: '',
    },
  },
};
</script>
