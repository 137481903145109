import {BaseModel, Model} from './base_model';

@Model({
  type: 'player',
  url: 'api/front/players',
  attributes: {
    name: {},
    team_id: {},
    image_url: {},
    team_ids: {},
    region: {},
    user_id: { read_only: true }
  },
})
export class Player extends BaseModel {

}
