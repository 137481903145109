<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" v-if="is_loaded" class="relative z-10" @close="close()">

      <Spinner v-if="is_saving" />

      <div class="fixed inset-0 overflow-hidden" v-if="!is_saving">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md popover-glass-white-form font-aldrich">
                <div class="flex h-full flex-col overflow-y-scroll bg-glass-white-500 backdrop-blur-md shadow-xl">
                  <div class="px-4 py-6 pb-2 sm:px-6">
                    <div class="flex items-start justify-between">
                      <h2 id="slide-over-heading" class="text-lg uppercase font-semibold leading-6 text-gray-900 text-shadow-white-inset-sm">Submit Match Result</h2>

                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md text-gray-400 hover:text-gray-500" @click="close()">
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="main-panel">
                    <div class="max-w-md mx-auto mt-4 p-6">
                      <form class="" @submit.prevent="submitForm">

                        <div class="mb-4">
                          <label class="block text-gray-700 text-sm font-bold mb-2 uppercase">
                            Select Winner
                          </label>
                          <div class="flex justify-between items-center p-1">
                            <div @click="selectWinner(match.home_team)"
                                 :class="isWinner(match.home_team.id) ? 'is-winner bg-green-400 bg-opacity-70 text-glass-black-800' : ''"
                                 class="select-winning-team flex flex-col items-center justify-center p-1 rounded-md hover:bg-glass-primary-600 hover-transition-1 cursor-pointer w-2/5">
                              <img class="h-10 w-10 rounded-full" :src="match.home_team.image_url" />
                              <div class="font-agency">{{ match.home_team.name }}</div>
                          </div>

                          <div class="w-1/10 flex justify-center items-center mx-5%">
                            <span>VS</span>
                          </div>

                          <div @click="selectWinner(match.away_team)"
                          :class="isWinner(match.away_team.id) ? 'is-winner bg-glass-black-400 text-glass-white-700' : ''"
                          class="select-winning-team flex flex-col items-center justify-center p-1 rounded-md hover:bg-glass-primary-600 hover-transition-1 cursor-pointer w-2/5">
                            <img class="h-10 w-10 rounded-full" :src="match.away_team.image_url" />
                            <div class="font-agency">{{ match.away_team.name }}</div>
                          </div>
                      </div>
                    </div>

                    <div class="glass-white-divider mb-4"></div>

                    <div v-if="rounds.length > 0 && this.winner" class="mt-4 mb-4">
                      <div v-for="(round, index) in rounds" :key="index" class="mb-4">
                        <div class="text-sm font-bold uppercase mb-1 text-shadow-white-inset-sm justify-between flex">
                          <span class="flex">{{league.rules.round_translation}} {{ index + 1 }}</span>
                          <span v-if="!is_forfeit" class="flex justify-end"><XMarkIcon class="h-4 w-4 cursor-pointer" @click="removeRound(index)" /></span>
                        </div>

                        <div class="flex justify-between items-center">
                          <input type="number"
                          v-model="round.home_team_score"
                          @input="markDirty()"
                          v-max-value="league.rules.max_points_per_round"
                          class="w-2/5 p-2 rounded-md border border-gray-300"
                          placeholder="Home Score" />

                          <input type="number"
                          v-model="round.away_team_score"
                          @input="is_confirm_disabled = true"
                          :max="league.rules.max_points_per_round"
                          class="w-2/5 p-2 rounded-md border border-gray-300"
                          placeholder="Away Score" />
                        </div>
                      </div>

                      <div class="flex justify-between items-center mt-4">
                        <div class="w-2/6 text-center text-3xl font-bold">
                          {{ homeTeamTotal() }}
                        </div>

                        <!-- Spacer for VS -->
                        <div class="w-1/10"></div>

                        <div class="w-2/6 text-center text-3xl font-bold">
                          {{ awayTeamTotal() }}
                        </div>
                      </div>
                    </div>

                    <div class="mb-4" v-if="winner">
                      <label class="flex items-center">
                        <input v-model="is_forfeit" @change="toggleForfeit" type="checkbox" class="form-checkbox h-5 w-5 text-blue-600" />
                        <span class="ml-2 text-gray-700">Is Forfeit?</span>
                      </label>
                    </div>

                  <div class="mt-2 mb-4 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6" >
                    <div class="mt-4 flex text-sm leading-6 text-gray-600 justify-center mb-2">
                      {{winnerPresent() ? resultText() : 'Select the winner above'}}
                    </div>
                  </div>

                  <div class="flex items-center justify-between mt-2 mb-2">
                    <button @click="addRoundScore()" :disabled="isAddRoundScoreDisabled()"
                      class="button-secondary-md-1">
                      Add {{league.rules.round_translation}} Score
                    </button>

                    <button @click="save()" class="button-primary-md-1" :disabled="isSubmitDisabled()">
                      <span v-if="this.record.is_new">
                        Submit Results
                      </span>
                      <span v-if="!this.record.is_new">
                        Resubmit Results
                      </span>
                    </button>
                  </div>

                  <div v-if="this.record.submitter_id" class="flex pt-4">
                    <button v-if="playerIsNotSubmitter()" @click="confirmMatchResult(this.record, this.league)" class="button-green-md-1 flex w-full justify-center items-center" :disabled="isConfirmDisabled()">
                      <CheckIcon class="flex items-center green h-4 w-4" />
                      Confirm Match Results
                    </button>
                  </div>
                </form>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import TemplateHelpersMixin from '@/components/mixins/template_helpers';

import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { XMarkIcon, EllipsisVerticalIcon, ClipboardIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'MyMatchesFormPopout',
  mixins: [TemplateHelpersMixin],
  components: {
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
    CheckIcon, ChevronUpDownIcon, XMarkIcon,
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    EllipsisVerticalIcon,
    ClipboardIcon
  },
  props: ['parent', 'match', 'league', 'close', 'confirm'],
  methods: {
    markDirty() {
      this.is_dirty = true;
    },
    toggleForfeit(val) {
      this.rounds.length = 0;

      if(val) {
        _.times(this.league.forfeit_rules.rounds, (i) => {
          if(this.winner.id === this.match.home_team.id) {
            this.addRoundScore(this.league.forfeit_rules.winner_points, this.league.forfeit_rules.loser_points);
          } else {
            this.addRoundScore(this.league.forfeit_rules.loser_points, this.league.forfeit_rules.winner_points);
          }
        })
      }
    },
    // updateRoundWinner(round) {
    //   round
    // },
    playerIsNotSubmitter() {
      //FOR LOCAL TESTING ONLY, MAKE SURE NEXT LINE IS COMMENTED OUT BEFORE COMMITING
      // return true
      return this.record.submitter_id !== this.player.id;
    },
    winnerPresent() {
      return !_.isObjectEmpty(this.winner);
    },
    isWinner(team_id) {
      return this.winner.id === team_id;
    },
    isConfirmDisabled() {
      return this.rounds.length < this.league.rules.min_rounds_per_match || this.is_dirty || this.disable_confirm;
    },
    isSubmitDisabled() {
      return this.rounds.length < this.league.rules.min_rounds_per_match || !this.winner;
    },
    removeRound(index) {
      this.rounds.splice(index, 1);
      this.is_dirty = true;
    },
    selectWinner(team) {
      if(this.is_forfeit) { return }
      this.is_dirty = true;
      this.winner = team;
      
      this.loser = _.find([this.match.home_team, this.match.away_team], (t) => {
        return t.id !== team.id;
      });

      _.times(this.league.rules.min_rounds_per_match, () => {
        this.addRoundScore();
      })
    },
    homeTeamTotal() {
      return _.sum(_.map(this.rounds, 'home_team_score'));
    },
    awayTeamTotal() {
      return _.sum(_.map(this.rounds, 'away_team_score'));
    },
    isAddRoundScoreDisabled() {
      return this.rounds.length >= this.league.rules.max_rounds_per_match
             || _.isObjectEmpty(this.winner)
             || this.is_forfeit;
    },
    addRoundScore(home_team_score=0, away_team_score=0) {
      this.is_dirty = true;

      this.rounds.push({
        home_team_score: home_team_score,
        away_team_score: away_team_score
      })
    },
    roundResults() {
      return _.map(this.rounds, (r) => {
        if(r.home_team_score > r.away_team_score) {
          r.winner_id = this.match.home_team.id;
          r.loser_id = this.match.away_team.id;
        } else {
          r.winner_id = this.match.away_team.id;
          r.loser_id = this.match.home_team.id;
        }

        return r;
      });
    },
    winnerOfRoundsId() {
      let round_results = this.roundResults();
      return _.findMostFrequentValue(round_results, 'winner_id');
    },

    winnerOfScoresId() {
      let round_results = this.roundResults();
      let home_team_score = this.homeTeamTotal();
      let away_team_score = this.awayTeamTotal();

      return home_team_score > away_team_score ? this.match.home_team.id : this.match.away_team.id;
    },
    resultText() {
      let round_results = this.roundResults();
      let totals = [this.homeTeamTotal(), this.awayTeamTotal()];
      if (this.winnerOfScoresId() === this.winner.id) {
        return `${this.winner.name} wins ${_.max(totals)} to ${_.min(totals)}`;
      } else {
        return `${this.winner.name} wins ${_.min(totals)} to ${_.max(totals)}`;
      }
    },
    scoreTotals() {
      return [this.homeTeamTotal(), this.awayTeamTotal()];
    },
    differential() {
      let totals = this.scoreTotals();

      if(this.winnerOfScoresId() === this.winner.id) {
        return _.max(totals) - _.min(totals);
      } else {
        return _.min(totals) - _.max(totals);
      }
    },
    async save() {
      let totals = this.scoreTotals();
      let differential = this.differential();
      let round_results = this.roundResults();

      this.record.round_results = _.withoutEmptyObjects(round_results);
      this.record.differential = differential;
      this.record.outcome = this.is_forfeit ? "forfeit" : "completed";
      this.record.round_results = round_results;

      if(this.record.submitter_id.length === 0) {
        this.record.submitter_id = this.player.id;
      }

      this.record.winner_id = this.winner.id;
      this.record.loser_id = this.loser.id;
      this.record.match_id = this.match.id;

      try {
        await this.record.save();
        this.$parent.match.result = this.record;
        this.$parent.$$reload();
        // this.$router.go();
        this.close();
      } catch(e) {

      }
    }
  },
  async created() {
    window.match_results_popout = this;
    this.record = this.$parent.record;

    this.confirmMatchResult = (record, league) => {
      this.disable_confirm = true;
      this._confirmMatchResult(this.record, this.league)
    }
    this._confirmMatchResult = _.throttle(this.confirm, 1000);

    //probably should figure out a way to skip pagination here
    this.player = await Models.Player.$findBy({
      'by_user_id': this.$root.current_user.id,
      'by_league_id': this.league.id
    });

    this.is_forfeit = this.record.outcome === 'forfeit';

    if(this.record.round_results) {
      this.winner = this.record.winner;
      this.loser = this.record.loser;
      this.rounds = this.record.round_results;
    }

    this.is_loaded = true;
    this.open = true;
  },

  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false),
      is_saving: ref(false),
      is_forfeit: ref(false),
      disable_confirm: ref(false),
      open: ref(false),
      is_dirty: ref(false),
      player: ref({}),
      record: ref({}),
      rounds: ref([]),
      winner: ref(false),
      teams: ref([]),
      errors: ref([])
    }
  }
}
</script>

<style>
</style>
