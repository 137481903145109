<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div>
        <div class="fixed inset-0 bg-glass-black-500 backdrop-blur-sm transition-opacity" />
        <slot></slot>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script>
 /* eslint-disable */
import { ref, computed } from 'vue';
import _ from 'lodash';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

export default {
  name: 'Overlay',
  components: { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot },
  props: [],
  methods: {
  },
  async created() {
    window.overlay = this;
  },
  setup() {
    return {
    }
  }
}
</script>

<style>
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
