<template>
<div class="py-2 sm:px-6 lg:px-4 flex-grow">
  <div class="page-title font-agency text-neutral-200 text-3xl my-2 mb-0">
    <h1 class="flex block items-end justify-between pb-4 pt-2">
        <div class=" flex block text-4xl">XRL.PRO</div>
        <div class="flex"></div>
        <div class="flex block items-center">AWE 2024 Leaderboard</div>
    </h1>
  </div>
  <!-- <StandingsIndexNavigation  :pagination="pagination" :onPageChanged="fetch" v-if="is_loaded"></StandingsIndexNavigation> -->
  <Transition name="fade-mid" mode="out">
    <LogoSpinner v-if="!is_loaded" />
  </Transition>
  <Transition name="page-transition-2" mode="in-out">
  <div class="flow-root text-shadow-lg flex-grow" v-if="is_loaded">
    <div class="relative -mx-6 -my-2 sm:-mx-6 lg:-mx-6">
      <div class="inline-block min-w-full py-2 align-middle sm:px-1 lg:px-1 overflow-x-visible">
        <table class="ui-table-1">
          <thead class="">
             <tr>
              <th scope="col" class="px-1 py-3.5 text-center font-semibold uppercase tracking-wide pl-0">Rank</th>
              <th scope="col" class="px-1 py-3.5 pr-0 text-left font-semibold uppercase " style="width: auto; min-width: fit-content;">Division</th>
              <th scope="col" class="px-1 py-3.5 pr-0 text-center font-semibold uppercase"> </th>
              <th scope="col" class="py-3.5 px-0 sm:px-1 text-left font-semibold uppercase tracking-wide auto">Player</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold uppercase tracking-wide">R1</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold uppercase tracking-wide">R2</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold uppercase tracking-wide">R3</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold uppercase tracking-wide">Total</th>
            </tr>
          </thead>
          <tbody class="">
            <StandingsIndexItem :max-players="1" :rating="rating" v-for="rating in ratings" :key="rating.id" />
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </Transition>
</div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import { TradeshowRanking } from '@/models/tradeshow_ranking';
import _ from 'lodash';
import Pagination from '@/components/ui/Pagination';
import StandingsIndexNavigation from './index/navigation';
import StandingsIndexItem from './index/item';
import { Subscriptions } from '@/lib/subscriptions';
import UIComponents from '@/components/ui';
import BaseMixin from '@/components/mixins/base_mixin';

export default {
  name: 'TradeshowsIndex',
  mixins: [BaseMixin],
  components: {
    Pagination, StandingsIndexNavigation, StandingsIndexItem, ...UIComponents
  },
  props: ['league'],
  data() {
    return {
      pagination: {}
    }
  },
  methods: {
    async fetch(query={}) {
      this.is_loaded = false;

      this.currentQuery = {
        ...this.currentQuery,
        ...this.$route.query,
        ...query,
        by_tradeshow: this.tradeshow,
        per_page: 1000
      };

      let ratings = await TradeshowRanking.search(this.currentQuery);
      this.ratings = _.sortBy(ratings, ['rank']);
      // this.pagination = ratings.meta.pagination;
      this.is_loaded = true;
    },
    setDefaultParams() {
      if(!this.$route.params.slug) { return }
      this.season = this.$route.params.season || _.last(this.league.seasons).number;
      this.region = this.$route.params.region || 'na';
    },
    teamShowPath(id) {
      return ''
      // return `/leagues/${this.$route.params.slug}/team/${id}/details`
    }
  },
  async created() {
    this.$$initialize();
    // this.setLeague();
    // this.setDefaultParams();
    this.fetch = _.debounce(this.fetch, 250, {leading: true, trailing: false});
    this.$root.inner_content_loading = true;
    window.standings_index = this;

    if(this.$router.currentRoute.value.query) {
      this.currentQuery = {
        page: 1,
        ...this.$router.currentRoute.value.query
      }
    }

    await this.fetch({
      ...this.currentQuery,
      by_league_id: this.league.id,
      by_region: this.region,
      by_season: this.season,
      per_page: 100
    });

    this.is_loaded = true;
    this.$root.inner_content_loading = false;
  },
  mounted() {
    console.log(this.$route.params.slug);
  },
  watch: {
    '$route.params.slug': {
      deep: false,
      handler(new_params, old_params) {
        this.is_loaded = false;
        this.setLeague();
        this.$route.query.page = 1;
        this.setDefaultParams();
        this.fetch();
      }
    },
    '$router.currentRoute.value.query'(data) { },
    '$router.currentRoute.value.query.per_page'(per_page) { },
    '$router.currentRoute.value.query.page'(page) { }
  },
  setup() {
    return {
      ratings: ref([]),
      teams: ref([]),
      league: ref({}),
      season: ref(1),
      region: ref("na"),
      currentQuery: ref({
        by_region: 'na',
        by_season: 1
      }),
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
.division-bg {
  background-size: 48px 48px !important;
  background-origin: center;
  background-position: left 0px bottom 16px !important;
}
.team-item {
  &:hover {
    cursor: default;
  }
}
</style>
