<template>
  <!-- <button @click="signInWithDiscord" class="flex items-center px-4 py-2 bg-transparent ring-2 ring-blue-800 hover:bg-blue-700 text-blue-700 hover:text-white hover-transition-2 text-sm font-medium rounded-md">
  <DiscordIcon svgClass="h-5 w-5 mr-2" /> Sign In
</button> -->
<button @click="signInWithDiscord" class="flex items-center px-4 py-2 bg-blue-600 bg-opacity-10 ring-blue-800 hover:bg-blue-700 text-white text-sm font-medium rounded-md">
  <DiscordIcon svgClass="h-5 w-5 mr-2" /> Sign In
</button>

</template>

<script>
 /* eslint-disable */
import { ref } from 'vue';
import _ from 'lodash';
import {
  Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems
} from '@headlessui/vue';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import DiscordIcon from '@/components/icons/discord_icon.vue';
import axios from 'axios';
const VUE_APP_DISCORD_URL = process.env.VUE_APP_DISCORD_URL;

export default {
  name: 'SignedOut',
  components: {
    DiscordIcon
  },
  methods: {
    signInWithDiscord() {
      console.log("DISCORD_URL_IS", VUE_APP_DISCORD_URL);
      // const discord_url = `https://discord.com/oauth2/authorize?client_id=1176598883757981789&scope=identify%20email&response_type=code&redirect_uri=http://localhost:3010/users/auth/discord/callback&permissions=274877908992`
      window.location.href = VUE_APP_DISCORD_URL;
    }
  },
  async created() {
    console.log(VUE_APP_DISCORD_URL);
    this.DISCORD_URL = VUE_APP_DISCORD_URL;
    window.signed_out = this;
  },
  setup() {
    return {
    }
  }
}
</script>

<style>
</style>
