import {BaseModel, Model} from './base_model';

@Model({
  type: 'game',
  url: 'api/front/games',
  attributes: {
    name: {},
    slug: {}
  },
  has_many: {
    leagues: { type: 'league' }
  }
})

export class Game extends BaseModel {
  get image_key() {
    return this.slug;
  }
}
