<template>
  <div class="heading-with-banner" v-if="user.show_hero_banner">
    <div class="image-heading-bg mt-6 rounded-md overflow-hidden rounded-md border border-glass-black-100">
      <img class="h-32 w-full object-cover lg:h-32 relative" :src="user.hero_image_url" alt="" />
    </div>
    <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 py-8">
      <div class="-mt-24 sm:-mt-24 sm:flex sm:items-end sm:space-x-5">
        <div class="flex">
          <img class="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 z-10" :src="user.image_url" alt="" />
        </div>
        <div class="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1 relative">
          <div class="mt-6 min-w-0 flex-1 text-shadow-black-sm font-agency">
            <h1 class="truncate font-bold text-white text-shadow-sm-black-inset tracking-wider uppercase text-3xl">
              <span class="text-4xl" v-if="user.country_flag">{{user.country_flag}}</span>
              <div class="inline-block ml-2 first-letter-orange first-letter-4xl">{{ user.username }}</div>
            </h1>
          </div>

          <UserShowSocialButtons :classes="'mt-6'" :user="user" />

          <div class="absolute mw-12 -top-100" style="top: -50px;" v-if="shouldShowEditButton()">
            <button @click="$parent.openForm()" type="button" class="inline-flex justify-center rounded-md text-button-glass-muted-1">
              <PencilIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              <span class="pl-1 pr-2">Edit Profile</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="page-title mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 py-4 backdrop-saturate--50 rounded-md m-4 mt-10 relative">
    <div class="flex flex-col sm:flex-row sm:items-center justify-center sm:justify-start">
      <div class="flex justify-center sm:justify-start sm:mr-4">
        <img class="h-20 w-20 rounded-full ring-4 ring-white" :src="user.image_url" alt="" />
      </div>
      <div class="mt-3 sm:mt-0 flex flex-col sm:flex-row sm:min-w-0 sm:flex-1 sm:items-center sm:justify-start">
        <div class="min-w-0 flex-1 font-agency">
          <h1 class="truncate font-bold text-white text-shadow-sm-black-inset tracking-wider uppercase text-3xl">
            <span class="text-4xl" v-if="user.country_flag">{{user.country_flag}}</span>
            <div class="inline-block ml-2 first-letter-orange first-letter-4xl">{{ user.username }}</div>
          </h1>
        </div>

        <UserShowSocialButtons :user="user" />

        <div class="absolute mw-12" style="top: 120px; right: 10px;" v-if="shouldShowEditButton()">
          <button @click="$parent.openForm()" type="button" class="inline-flex justify-center rounded-md text-button-glass-muted-1">
            <PencilIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            <span class="pl-1 pr-2">Edit Profile</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { PencilIcon } from '@heroicons/vue/24/solid';
import UIComponents from '@/components/ui';
import UserShowSocialButtons from './_social_buttons';

export default {
  name: 'UserShowHeading',
  components: {
    UserShowSocialButtons, PencilIcon,
    ...UIComponents
  },
  props: ['user', 'classes', 'isCurrentUser', 'showEditButton'],
  data() {
    return {
      is_loaded: false
    }
  },
  methods: {
    shouldShowEditButton() {
      return this.showEditButton && this.$parent.isCurrentUser();
    }
  },
  async created() {
    this.is_loaded = true;
  },
  mounted() {
  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
</style>
