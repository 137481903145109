<template>
<div class="spinner-wrapper flex items-center justify-center w-full h-full">
  <div class="spinner"></div>
</div>
</template>

<script>
 /* eslint-disable */
import { ref, computed } from 'vue';
import _ from 'lodash';

export default {
  name: 'Spinner',
  components: {

  },
  props: [],
  methods: {
  },
  async created() {
    window.spinner = this;
  },
  setup() {
    return {
    }
  }
}
</script>

<style>
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
