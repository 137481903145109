import {BaseModel, Model} from './base_model';

@Model({
  type: 'stat',
  url: 'api/front/stats',
  attributes: {
    players: {},
    matches: {},
    points:  {},
    rounds:  {}
  }
})

export class Stat extends BaseModel {
}
