<template>
  <div v-if="is_loaded" class="clip-wrapper py-2">
    <div :id="clip.id" v-if="clip.title.length > 0" style="padding-left: 0px;" class="clip-title text-lg font-agency text-slate-50 mb-2 flex justify-between">
      <div class="flex-grow">{{clip.title}}</div>
      <div class="">
        <button type="button" @click="copyUrlToClipboard()" class="relative rounded-md bg-blur-sm bg-glass-white-200 border border-glass-white-100 backdrop-saturate-10 p-2 text-glass-white-600 hover:text-glass-white-900 hover:backdrop-saturate-200 focus:outline-none hover:ring-2 hover:ring-glass-white-500 hover-transition-1">
          <ShareIcon class="h-5 w-5" />
        </button>
      </div>
    </div>
    <div class="clip-container">
      <div v-if="clip.platform === 'youtube'" class="responsive-iframe-container">
        <iframe :src="`https://www.youtube.com/embed/${clip.video_id}`" frameborder="0" allowfullscreen></iframe>
      </div>
      <div v-else-if="clip.platform === 'tiktok'" class="tiktok-embed responsive-iframe-container">
        <iframe
        :src="`https://www.tiktok.com/embed/${clip.video_id}`"
        class="tiktok-iframe"
        allowfullscreen
        scrolling="no"
        allow="encrypted-media;">
        </iframe>
      </div>
      <div v-else-if="isTwitchClip(clip)" class="responsive-iframe-container">
        <iframe :src="`https://clips.twitch.tv/embed?clip=${clip.video_id}&parent=${parentDomain}`" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div v-else>
    Loading...
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import * as Models from '@/models';
import _ from 'lodash';
import { ShareIcon } from '@heroicons/vue/24/solid';
import Mixins from '@/components/mixins';

export default {
  name: 'Clip',
  mixins: _.values(Mixins),
  components: {
    ShareIcon
  },
  props: ['clip'],
  data() {
    return {
      clips: [],
      is_loaded: false
    }
  },
  methods: {
    isTwitchClip(clip) {
      return clip.platform === "twitch" && _.includes(clip.url.split("."), "https://clips");
    },
    loadTikTokScript() {
      if (!document.querySelector('script[src="https://www.tiktok.com/embed.js"]')) {
        const script = document.createElement('script');
        script.src = "https://www.tiktok.com/embed.js";
        script.async = true;
        document.body.appendChild(script);
      }
    },
    getTikTokUser(url) {
      const match = url.match(/https:\/\/www\.tiktok\.com\/@([^\/]+)\/video\/(\d+)/);
      if (match && match.length > 1) {
        return match[1];
      }
      return null;
    },
    getTikTokVideoId(url) {
      const match = url.match(/https:\/\/www\.tiktok\.com\/@([^\/]+)\/video\/(\d+)/);
      if (match && match.length > 2) {
        return match[2];
      }
      return null;
    },
    copyUrlToClipboard() {
      let clip_url = `${this.parentDomain}${this.$route.path}?clip_id=${this.clip.id}`;
      this.copyToClipboard(clip_url, 'Copied clip url to clipboard');
    }
  },
  async created() {
    window.shared_clip = this;
  },
  mounted() {
    this.parentDomain = window.location.hostname;
    this.loadTikTokScript();
    this.is_loaded = true;

    if(this.$route.query.clip_id && this.$route.query.clip_id === this.clip.id) {
      this.scrollToElementWhenAvailable(this.$route.query.clip_id);
    }
  },
  watch: {
  },
  setup() {
    return {
      is_loaded: ref(false)
    }
  }
}
</script>

<style>
.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  @apply rounded-md;
}
.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tiktok-embed {
  height: 575px;
}

.tiktok-iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0;
}

.clip-container {
  @apply p-1 bg-glass-white-400 rounded-md backdrop-saturate-200 rounded-md border-glass-black-50;
}

.tiktok-embed {
  margin: 0px !important;
}

.clip-title {
  @apply text-3xl !important;
}
</style>
